import React, { useState } from "react";
import "../../styles/sponsors.css";

interface SponserCardProps {
  name: string;
  type?: string;
  image?: string;
  link?: string;
  instagram?: string;
  workshop?: string;
}

const SponserCardComponent: React.FC<SponserCardProps> = ({
  name,
  type,
  image,
  link,
  instagram,
  workshop,
}) => {

  return (
    <div
      className="sponser"
    >
      {image && (
        <img alt={`${name} logo`} className="sponser-img" src={image} />
      )}
      <p className="spons-name">
        <a href={link} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      </p>
      {type && <p className="spons-type">{type}</p>}
      {instagram && (
        <p className="spon-instagramlink">
          <a href={instagram} target="_blank" rel="noopener noreferrer">
            Instagram 🔗
          </a>
        </p>
      )}
      {workshop && (
        <p className="spon-workshoplink">
          <a href={workshop} target="_blank" rel="noopener noreferrer">
            Workshop 🔗
          </a>
        </p>
      )}
    </div>
  );
};

export default SponserCardComponent;
