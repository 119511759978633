import "../../styles/sponsors.css";
import {
  associateSponsers,
  mediaSponsers,
  workshopSponsers,
  worldfestSponsers,
} from "../../components/sponsors/sponsersDetails";
import SponserCardComponent from "../../components/sponsors/SponserCard";
import Navbar from "../../components/Navbar/Navbar";

function Sponsers() {
  return (
    <div className="sponser-layout">
      <Navbar />

      <div className="spon-intro-text">
        <h2>SPONSORS</h2>
        {/* <p>
          Intro text. Contrary to popular belief, Lorem Ipsum is not simply
          random text. It has roots in a piece of classical Latin literature
          from 45 BC, making it over 2000 years old. Richard McClintock, a Latin
          professor at Hampden-Sydney College in Virginia, looked up one of the
          more obscure Latin words, <i>consectetur</i>, from a Lorem Ipsum
          passage, and going through the cites of the word in classical
          literature, discovered the undoubtable source.
        </p> */}
      </div>

      <div className="sponsers-content">
        <div className="main-spon flex flex-col items-center">
          <div className="w-[30vw]">
            <SponserCardComponent
              // key={index}
              name="OnePlus"
              type="Title Sponsor"
              image="/spons/oneplus-logo.png"
              link="https://www.oneplus.com/us"
            />
          </div>
          <div className="by-sponsors flex flex-col md:flex-row gap-0 md:gap-12">
            <div className="w-[80vw] md:w-[25vw]">
              <SponserCardComponent
                // key={index}
                name="Coke"
                type="Official Beverage Partner"
                image="/spons/Coca-cola logo.png"
                link="https://www.hccb.in/"
              />
            </div>
            <div className="w-[80vw] md:w-[25vw] pt-0 md:pt-16">
              <SponserCardComponent
                // key={index}
                name="The Hindu"
                type="Presented By Sponsor"
                image="/spons/thehindu.png"
                link="https://www.thehindu.com/"
              />
            </div>
          </div>
        </div>
        <div className="spon-section">
          {associateSponsers.map((sponser, index) => (
            <SponserCardComponent
              key={index}
              name={sponser.name}
              type={sponser.type}
              image={String(sponser.image)}
              link={sponser.link}
            />
          ))}
        </div>

        <h2>Online Media Sponsors</h2>
        <div className="spon-section">
          {mediaSponsers.map((sponser, index) => (
            <SponserCardComponent
              key={index}
              name={sponser.name}
              type={sponser.type}
              image={String(sponser.image)}
              link={sponser.link}
            />
          ))}
        </div>

        <h2>Workshop Partners</h2>
        <div className="spon-section">
          {workshopSponsers.map((sponser, index) => (
            <SponserCardComponent
              key={index}
              name={sponser.name}
              image={String(sponser.image)}
              link={sponser.link}
              instagram={sponser.instagram}
              workshop={sponser.workshop}
            />
          ))}
        </div>

        <h2>Worldfest Partners</h2>
        <div className="spon-section Worldfest-section">
          {worldfestSponsers.map((sponser, index) => (
            <SponserCardComponent
              key={index}
              name={sponser.name}
              image={String(sponser.image)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sponsers;
