import React from 'react';
import './Hero.scss';
import { motion } from 'framer-motion';

const Hero = () => {
  return (
    <motion.div className='bg-black whole '>
      <motion.div className='flex justify-center items-center'>
        <motion.div className='flex justify-center items-center'>
          <ul className="flex md:flex-row flex-col relative ">
            <motion.div className='flex'>
              <motion.li     initial={{ y: -100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{ type: 'spring', stiffness: 100, damping: 10, duration: 3 }}
        
        className="panel md:w-[25vw] md:h-[105vh] w-[50vw] h-[50vh]  text-[#ffffff] hover:text-[#ffd162] flex flex-col justify-center items-center">
                <a href="#">
                  {/* <span className='relative z-10 w-full font-bold text-[4vw] '>
                    TRADITION
                  </span> */}
                </a>
                <motion.div   initial={{ y: -100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{ type: 'spring', stiffness: 100, damping: 10, duration: 3 }} className="image-wrapper">
                  <img src="tradition.JPG" alt="image of lions" className='md:w-[25vw] md:h-[105vh] w-[50vw] h-[50vh]' />
                  <span className="overlay"></span>
                </motion.div>
              </motion.li>

              <motion.li   initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{ type: 'spring', stiffness: 100, damping: 10, duration: 3 }} className="panel md:w-[25vw] md:h-[105vh] w-[50vw] h-[50vh] text-[#433d3d] hover:text-[#ffd162] flex flex-col justify-center items-center">
                <a href="#">
                  {/* <span className='font-bold text-[4vw]'>
                    EMOTION
                  </span> */}
                </a>
                <motion.div className="image-wrapper">
                  <img src="emotion.jpg" alt="image of hot air balloons" className='md:w-[25vw] md:h-[105vh] w-[50vw] h-[50vh]' />
                  <span className="overlay"></span>
                </motion.div>
              </motion.li>
            </motion.div>

            <motion.div className='flex pt-[6vh] md:pt-[0vh]'>
              <motion.li   initial={{ y: -100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{ type: 'spring', stiffness: 100, damping: 10, duration: 3 }} className="panel md:w-[25vw] md:h-[105vh] w-[50vw] h-[50vh] text-[#433d3d] hover:text-[#ffd162] flex flex-col justify-center items-center">
                <a href="#">
                  {/* <span className='font-bold text-[4vw]'>
                    DRAMA
                  </span> */}
                </a>
                <motion.div className="image-wrapper">
                  <img src="drama1.jpg" alt="image of hot air balloons" className='md:w-[25vw] md:h-[105vh] w-[50vw] h-[50vh]' />
                  <span className="overlay"></span>
                </motion.div>
              </motion.li>

              <motion.li   initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{ type: 'spring', stiffness: 100, damping: 10, duration:3 }} className="panel md:w-[25vw] md:h-[105vh] w-[50vw] h-[50vh] text-[#433d3d] hover:text-[#ffd162] flex flex-col justify-center items-center">
                <a href="#">
                  {/* <span className='font-bold text-[4vw]'>
                    LEGACY
                  </span> */}
                </a>
                <motion.div className="image-wrapper">
                  <img src="Worldfest.jpg" alt="Martin Luther King" className='md:w-[25vw] md:h-[105vh] w-[50vw] h-[50vh]' />
                  <span className="overlay"></span>
                </motion.div>
              </motion.li>
            </motion.div>

            <motion.h1   initial={{ x: -100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{ type: 'spring', stiffness: 100, damping: 10, duration: 3 }} 
        className='headline absolute z-10 w-[100vw] flex justify-center items-center'>
              <img src='FRAME2.png' className='md:w-[52vw] md:mt-[15vh] md:ml-[] ml-[12vw] mt-[35vh]' />
            </motion.h1>
          </ul>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default Hero;
