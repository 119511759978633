import React, { useState } from "react";
import "./schedule.css";
import Navbar from "../Navbar/Navbar";
const Schedule = () => {
  const [activeDay, setActiveDay] = useState<null | number>(null);

  const handleButtonClick = (day: number) => {
    setActiveDay(day); // Set the active day to the clicked button
  };

  return (
    <>
    <Navbar/>
    <div className="w-[100vw] min-h-[100vh] bg-[#c1204a] flex flex-col py-[10vh] items-center">
      <h1 className="font-unlock text-[#fcd34d] md:text-7xl text-4xl">EVENT SCHEDULE</h1>
      <div className="flex flex-row gap-10 mt-[5vh]">
        <div className="container flex justify-center items-center">
          <button
            onClick={() => handleButtonClick(1)}
            className="btn md:w-[120px] md:h-[60px] text-[#000000] font-unlock"
          >
            Day1
          </button>
        </div>
        <div className="container flex justify-center items-center">
          <button
            onClick={() => handleButtonClick(2)}
            className="btn md:w-[120px] md:h-[60px] text-[#000000] font-unlock"
          >
            Day2
          </button>
        </div>
        <div className="container flex justify-center items-center">
          <button
            onClick={() => handleButtonClick(3)}
            className="btn md:w-[120px] md:h-[60px] text-[#000000] font-unlock"
          >
            Day3
          </button>
        </div>

      
      </div>
<div className="font-unlock text-[#fcd34d] md:text-7xl text-4xl mt-[5vh]">
<h1 >      {activeDay === 1 && <p>DAY 1</p>}
        {activeDay === 2 && <p>DAY 2</p>}
        {activeDay === 3 && <p>DAY 3</p>}
        </h1>
</div>
      {activeDay === 1 && <img src="day1.png" alt="Day 1" className="mt-[5vh]" />}
      {activeDay === 2 && <img src="day2.png" alt="Day 2" className="mt-[5vh]" />}
      {activeDay === 3 && <img src="day3.png" alt="Day 3" className="mt-[5vh]" />}
    </div>
    </>
  );
};

export default Schedule;
