import { EventsLayout } from "./EventsLayout";
import {
  useGetEventByClubQuery,
  useGetClubsQuery,
} from "../../generated/graphql";
import { EventsList } from "./EventsList";
import { motion } from "framer-motion";
import Navbar from "../Navbar/Navbar";
import { ClubPassRegistrationModal } from "./ClubPassRegistrationModal";
import { useState } from "react";

export default function Workshops() {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const { data } = useGetClubsQuery();
  const workshopClub = data?.getClubs?.find(
    (club: any) => club.category.toLowerCase() === "workshops"
  );

  const { data: eventData } = useGetEventByClubQuery({
    variables: { clubId: workshopClub?.id || "" },
    skip: !workshopClub?.id,
  });

  const fadeInVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      className="h-full  overflow-hidden pt-[10vh]"
      style={{ backgroundImage: `url('Framebg.jpg')` }}
    >
      <Navbar />
      <div className="flex flex-col md:flex-row justify-center py-[0px]">
        <div className="w-[50%] px-[5vw] py-[7vh]">
          <h1 className="md:text-8xl text-4xl font-bold text-[#FFFB00] mb-[2vh] font-unlock tracking-wide">
            WORKSHOPS
          </h1>
          <h1 className="md:text-7xl text-4xl font-bold text-[white] mb-[2vh] font-unlock tracking-wider">
            SAARANG'25
          </h1>
          <p className="md:text-2xl text-1xl text-[#FDB951] md:w-[120%] w-[90vw] mb-[2vh]">
            Immerse yourself in hands-on learning experiences at Saarang's
            dynamic workshops. Led by industry experts and talented
            professionals, these sessions offer unique opportunities to develop
            new skills and explore your interests in an engaging environment.
          </p>
        </div>
        <div className="md:w-[50%] mr-[5%]">
          <img src="./workshop.png" alt="workshops" />
        </div>
      </div>

      {/* <motion.div
        variants={fadeInVariant}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="relative w-[110vw] transform -translate-x-10 md:h-[20vh] h-[12vh] mt-[5%] rotate-15 overflow-hidden flex items-center justify-center bg-[#fffb00]"
      >
        <motion.div className="w-[full] whitespace-nowrap">
          <span className="inline-block animate-scroll md:text-8xl text-4xl font-bold font-unlock text-black">
            REGISTER NOW &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; REGISTER NOW &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; REGISTER NOW
          </span>
        </motion.div>
      </motion.div> */}

      <div className="mt-[5vh] px-[5vw]">
        {workshopClub && (
          <div className="mb-8">
            <button
              onClick={() => setShowRegistrationModal(true)}
              className="w-full bg-[#FDB951] text-black p-6 hover:bg-yellow-600 transition-colors rounded-lg shadow-md"
            >
              <h3 className="text-2xl font-semibold font-unlock">
                Get Workshop Pass
              </h3>
              <p className="text-gray-800 mt-2 text-center font-bold">
                Get access to all workshops at a discounted price! Register for any workshop without additional payment.
              </p>
            </button>
          </div>
        )}

        <h1 className="md:text-6xl text-3xl font-bold text-[#FFFB00] mb-[3vh] font-unlock tracking-wide text-center">
          UPCOMING WORKSHOPS
        </h1>
        {workshopClub ? (
          <EventsList
            selectedCategory="workshop"
            selectedClubId={workshopClub.id}
            onClubSelect={() => {}}
          />
        ) : (
          <div className="text-center text-2xl text-[#FDB951]">
            No workshops available at the moment
          </div>
        )}
      </div>

      {/* Add Registration Modal */}
      {workshopClub && (
        <ClubPassRegistrationModal
          isOpen={showRegistrationModal}
          onClose={() => setShowRegistrationModal(false)}
          club={{
            id: workshopClub.id,
            clubName: "Workshops Pass",
            clubPassPrice: workshopClub.clubPassPrice,
            upcomingEvents: eventData?.getEventByClub || []
          }}
        />
      )}
    </motion.div>
  );
}
