import React, { useState, useMemo } from "react";
import {
  useGetEventsQuery,
  useGetEventByClubQuery,
  useGetEventBylocationQuery,
  useUpdateEventsMutation,
  useDeleteEventsMutation,
  useCreateEventsMutation,
  useGetAllEventRegistrationsQuery,
  useGeEventsRegistrationsByEventIdQuery,
  useGetClubsQuery,
  useCreateClubMutation,
  useUpdateClubMutation,
  useDeleteClubMutation,
  useGetClubPassRegistrationsQuery,
  useGetComboPassQuery,
  useGetAllComboPassRegistrationsQuery,
  useCreateComboPassMutation,
  useUpdateComboPassMutation,
  useDeleteComboPassMutation,
  useGetAllComboPassesQuery,
} from "../../generated/graphql";
import { Pencil, Trash2, Plus, Filter } from "lucide-react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
interface Event {
  id: string;
  title: string;
  description: string[];
  image_url: string | null;
  clubId: string | null;
  event_date: string;
  price: number;
  event_location: string;
  registrations_closed: boolean;
  Club?: {
    clubName: string;
    clubPassPrice: number;
    id: string;
    logo: string | null | undefined;
    category: string;
  };
}

interface FormData {
  title: string;
  description: string[];
  eventDate: string;
  price: number;
  eventLocation: string;
  imageUrl: string;
  registrationsClosed: boolean;
  clubId: string;
}

// Add Club interface
interface Club {
  id: string;
  clubName: string;
  logo: string | null | undefined;
  clubPassPrice: number;
  category: string;
  ContactNumber?: string | null;
  ContactName?: string | null;
}

// Add ClubFormData interface
interface ClubFormData {
  clubName: string;
  logo: string;
  clubPassPrice: number;
  category: string;
  contactNumber?: string | null;
  contactName?: string | null;
}
interface ClubPassRegistration {
  registration: {
    id: string;
    reg_date: string;
    razorpay_order_id?: string;
    payment_id?: string | null;
    user_id: string;
    paid: boolean;
  };
  user: {
    username?: string;
    user_id: string;
    email_primary?: string;
    mobile_number_primary?: string | null | undefined;
  };
  club: {
    clubName: string;
    id: string;
    category?: string;
    clubPassPrice: number;
  };
}
const initialClubFormData: ClubFormData = {
  clubName: "",
  logo: "",
  clubPassPrice: 0,
  category: "",
  contactNumber: "",
  contactName: "",
};

const initialFormData: FormData = {
  title: "",
  description: [],
  eventDate: "",
  price: 0,
  eventLocation: "",
  imageUrl: "",
  registrationsClosed: false,
  clubId: "",
};

const downloadCSV = (data: any[], filename: string) => {
  const headers = [
    "Username",
    "Email",
    "Mobile Number",
    "Event Title",
    "Registration Date",
    "Order ID",
    "Status",
  ];

  const csvData = data.map((reg) => [
    reg.user?.username || "Unknown User",
    reg.user?.email_primary || "No Email",
    reg.user?.mobile_number_primary||"",
    reg.event?.title || "Unknown Event",
    new Date(reg.registration.reg_date).toLocaleDateString(),
    reg.registration.razorpay_order_id || "",
    reg.registration.verified ? "Verified" : "Pending",
  ]);

  const csvContent = [
    headers.join(","),
    ...csvData.map((row) => row.join(",")),
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

// Add this interface near the top of the file with other interfaces
interface ComboPassRegistrationDetails {
  registration: {
    reg_date: string;
    razorpay_order_id?: string;
    payment_id?: string | null;
    user_id: string;
  };
  user: {
    username?: string;
    email_primary?: string;
    mobile_number_primary?: string | null | undefined;
  };
  comboPass?: {
    id: string;
    title: string;
    description: string[];
    current_registrations?: number | null | undefined;
  };
  clubs: {
    clubName: string;
    id: string;
    category?: string;
  }[];
}

const EventsAdminPortal: React.FC = () => {
  const navigate = useNavigate();
  // State for filters
  const [selectedClub, setSelectedClub] = useState<string>("");
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [showRegistrations, setShowRegistrations] = useState(false);
  const [selectedEventForReg, setSelectedEventForReg] = useState<string | null>(
    null
  );

  const [showClubModal, setShowClubModal] = useState(false);
  const [clubFormData, setClubFormData] =
    useState<ClubFormData>(initialClubFormData);
  const [selectedClubId, setSelectedClubId] = useState<string | null>(null);
  const [createClub] = useCreateClubMutation();
  const [updateClub] = useUpdateClubMutation();
  const [deleteClub] = useDeleteClubMutation();
  const { data: clubsData } = useGetClubsQuery();
  const [showOnlyIITStudents, setShowOnlyIITStudents] = useState(false);

  // State for form
  const [formData, setFormData] = useState<FormData>(initialFormData);

  // State for dialog
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

  const [selectedComboPassForReg, setSelectedComboPassForReg] = useState<
    string | null
  >(null);

  // Queries
  const { data: allEvents, loading: eventsLoading } = useGetEventsQuery();
  const { data: clubEvents } = useGetEventByClubQuery({
    variables: { clubId: selectedClub },
    skip: !selectedClub,
  });
  const { data: locationEvents } = useGetEventBylocationQuery({
    variables: { location: selectedLocation },
    skip: !selectedLocation,
  });

  const { data: allRegistrations } = useGetAllEventRegistrationsQuery();

  const { data: eventRegistrations } = useGeEventsRegistrationsByEventIdQuery({
    variables: {
      eventId: selectedEventForReg || "",
    },
    skip: !selectedEventForReg,
  });
  const filterEventRegistrations = (registrations: any[]) => {
    if (!registrations) return [];
    if (!showOnlyIITStudents) return registrations;
    
    return registrations.filter((reg: any) => 
      !reg.user?.email_primary?.endsWith("smail.iitm.ac.in")
    );
  };
  

  // Update the club select opt ions to use actual club data
  const clubOptions = useMemo(() => {
    return (
      clubsData?.getClubs?.map((club) => ({
        id: club.id,
        name: club.clubName,
      })) || []
    );
  }, [clubsData]);

  // Update location options based on unique locations from events
  const locationOptions = useMemo(() => {
    const locations = new Set<string>();
    allEvents?.getEvents?.forEach((event) => {
      if (event.event_location) {
        locations.add(event.event_location);
      }
    });
    return Array.from(locations);
  }, [allEvents]);

  // Update the displayEvents logic
  const displayEvents = useMemo(() => {
    if (selectedClub && clubEvents?.getEventByClub) {
      return clubEvents.getEventByClub;
    }
    if (selectedLocation && locationEvents?.getEventBylocation) {
      return locationEvents.getEventBylocation;
    }
    return allEvents?.getEvents || [];
  }, [selectedClub, clubEvents, selectedLocation, locationEvents, allEvents]);

  // Mutations
  const [createEvent] = useCreateEventsMutation();
  const [updateEvent] = useUpdateEventsMutation();
  const [deleteEvent] = useDeleteEventsMutation();

  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const variables = {
      ...formData,
      price: parseFloat(formData.price.toString()),
      description: formData.description.filter((point) => point.trim() !== ""),
      eventDate: new Date(formData.eventDate).toISOString(),
      clubId: formData.clubId,
    };

    try {
      if (selectedEventId) {
        await updateEvent({
          variables: {
            ...variables,
            updateEventsId: selectedEventId,
          },
        });
        toast.success("Event updated successfully!");
      } else {
        await createEvent({
          variables,
        });
        toast.success("Event created successfully!");
      }
      setIsDialogOpen(false);
      resetForm();
    } catch (error: any) {
      console.error("Error submitting event:", error);
      toast.error(error.message || "Failed to save event. Please try again.");
    }
  };

  const handleEdit = (event: Event) => {
    setSelectedEventId(event.id);
    setFormData({
      title: event.title,
      description: event.description,
      eventDate: new Date(event.event_date).toISOString().split("T")[0],
      price: event.price,
      eventLocation: event.event_location,
      imageUrl: event.image_url || "",
      registrationsClosed: event.registrations_closed,
      clubId: event.Club?.id || "",
    });
    setIsDialogOpen(true);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      try {
        await deleteEvent({
          variables: { deleteEventsId: id },
        });
        toast.success("Event deleted successfully!");
      } catch (error: any) {
        console.error("Error deleting event:", error);
        toast.error(
          error.message || "Failed to delete event. Please try again."
        );
      }
    }
  };

  const handleClubSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (selectedClubId) {
        await updateClub({
          variables: {
            updateClubId: selectedClubId,
            clubName: clubFormData.clubName,
            logo: clubFormData.logo,
            clubPassPrice: parseFloat(clubFormData.clubPassPrice.toString()),
            category: clubFormData.category,
            contactName: clubFormData.contactName || null,
            contactNumber: clubFormData.contactNumber || null,
          },
        });
        toast.success("Club updated successfully!");
      } else {
        await createClub({
          variables: {
            ...clubFormData,
            clubPassPrice: parseFloat(clubFormData.clubPassPrice.toString()),
            category: clubFormData.category,
            contactName: clubFormData.contactName || null,
            contactNumber: clubFormData.contactNumber || null,
          },
        });
        toast.success("Club created successfully!");
      }
      setShowClubModal(false);
      resetClubForm();
    } catch (error: any) {
      console.error("Error submitting club:", error);
      toast.error(error.message || "Failed to save club. Please try again.");
    }
  };

  const handleEditClub = (club: Club) => {
    setSelectedClubId(club.id);
    setClubFormData({
      clubName: club.clubName,
      logo: club.logo || "",
      clubPassPrice: club.clubPassPrice,
      category: club.category,
      contactName: club.ContactName || null,
      contactNumber: club.ContactNumber || null,
    });
    setShowClubModal(true);
  };

  const handleDeleteClub = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this club?")) {
      try {
        await deleteClub({
          variables: { deleteClubId: id },
        });
        toast.success("Club deleted successfully!");
      } catch (error: any) {
        console.error("Error deleting club:", error);
        toast.error(
          error.message || "Failed to delete club. Please try again."
        );
      }
    }
  };

  const resetClubForm = () => {
    setClubFormData(initialClubFormData);
    setSelectedClubId(null);
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setSelectedEventId(null);
  };

  const [showClubPassRegistrations, setShowClubPassRegistrations] =
    useState(false);
  const [showComboPassRegistrations, setShowComboPassRegistrations] =
    useState(false);
  const [selectedClubForPassReg, setSelectedClubForPassReg] = useState<
    string | null
  >(null);

  // Add new queries
  const { data: clubPassRegistrations } = useGetClubPassRegistrationsQuery({
    variables: { clubId: selectedClubForPassReg || "" },
    skip: !selectedClubForPassReg,
  });

  // Add new download function for club pass registrations
  const downloadClubPassCSV = (
    data: ClubPassRegistration[],
    filename: string
  ) => {
    const headers = [
      "Username",
      "Club name",
      "Email",
      "Mobile",
      "Order ID",
      "Registration Date",
    ];

    const csvData = data.map((reg) => [
      reg.user.username,
      reg.club.clubName,
      reg.user.email_primary || "No Email",
      reg.user.mobile_number_primary || "No Mobile",
      reg.user.username,
      reg.registration.razorpay_order_id || "",
      new Date(reg.registration.reg_date).toLocaleDateString(),
    ]);

    const csvContent = [
      headers.join(","),
      ...csvData.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Add new interface for Combo Pass
  interface ComboPass {
    id: string;
    title: string;
    description: string[];
    club_ids: string[];
    price: number;
    image_url?: string | null;
    is_active?: boolean | null;
    valid_till?: string | null;
    max_registrations?: number | null;
    current_registrations?: number | null;
  }

  // Update the ComboPassFormData interface
  interface ComboPassFormData {
    title: string;
    description: string[];
    clubIds: string[];
    price: number;
    imageUrl: string;
  }

  // Add initial state for combo pass form
  const initialComboPassFormData: ComboPassFormData = {
    title: "",
    description: [""],
    clubIds: [],
    price: 0,
    imageUrl: "",
  };

  // Add state for combo pass
  const [selectedComboPassId, setSelectedComboPassId] = useState<string | null>(
    null
  );

  // Add combo pass query
  const { data: comboPassData } = useGetComboPassQuery({
    variables: { getComboPassId: selectedComboPassId || "" },
    skip: !selectedComboPassId,
  });
  const downloadFilteredComboPassCSV = (
    data: ComboPassRegistrationDetails[],
    comboPassTitle: string
  ) => {
    const filename = selectedComboPassForReg
      ? `combo_pass_${comboPassTitle}_registrations.csv`
      : "all_combo_pass_registrations.csv";

    const filteredData = selectedComboPassForReg
      ? data.filter((reg) => reg.comboPass?.id === selectedComboPassForReg)
      : data;

    downloadComboPassCSV(filteredData, filename);
  };
  // Add download function for combo pass registrations
  const downloadComboPassCSV = (
    data: ComboPassRegistrationDetails[],
    filename: string
  ) => {
    const headers = [
      "Username",
      "Email",
      "Mobile",
      "Combo Pass Title",
      "Registration Date",
      "Order ID",
      "Payment ID",
      "Status",
    ];

    const csvData = data.map((reg) => [
      reg.user?.username || "Unknown User",
      reg.user?.email_primary || "No Email",
      reg.user?.mobile_number_primary || "No Mobile",
      reg.comboPass?.title || "Unknown Pass",
      reg.registration.reg_date || "No Date",
      reg.registration.razorpay_order_id || "",
      reg.registration.payment_id || "",
      reg.registration.razorpay_order_id ? "Verified" : "Pending",
    ]);

    const csvContent = [
      headers.join(","),
      ...csvData.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Add queries and mutations
  const { data: allComboPassRegistrations } =
    useGetAllComboPassRegistrationsQuery();
  const [createComboPass] = useCreateComboPassMutation();
  const [updateComboPass] = useUpdateComboPassMutation();

  // For Combo Pass Form
  const handleComboPassSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (selectedComboPassId) {
        await updateComboPass({
          variables: {
            // updateComboPassId: // value for 'updateComboPassId'
            // *      description: // value for 'description'
            // *      title: // value for 'title'
            // *      clubIds: // value for 'clubIds'
            // *      price: // value for 'price'
            // *      imageUrl: // value for 'imageUrl'
            // *      validTill: // value for 'validTill'
            // *      isActive: // value for 'isActive'
            // *      maxRegistrations: // value for 'maxRegistrations'
            updateComboPassId: selectedComboPassId,
            description: comboPassFormData.description.filter(
              (point) => point.trim() !== ""
            ),
            title: comboPassFormData.title,
            imageUrl: comboPassFormData.imageUrl || null,
            price: parseFloat(comboPassFormData.price.toString()),
          },
        });
        toast.success("Combo pass updated successfully!");
      } else {
        await createComboPass({
          variables: {
            clubIds: comboPassFormData.clubIds,
            description: comboPassFormData.description.filter(
              (point) => point.trim() !== ""
            ),
            title: comboPassFormData.title,
            imageUrl: comboPassFormData.imageUrl || null,
            price: parseFloat(comboPassFormData.price.toString()),
          },
        });
        toast.success("Combo pass created successfully!");
      }
      setShowComboPassForm(false);
      resetComboPassForm();
    } catch (error: any) {
      console.error("Error submitting combo pass:", error);
      toast.error(
        error.message || "Failed to save combo pass. Please try again."
      );
    }
  };

  // Add these mutations to your component
  const [deleteComboPass] = useDeleteComboPassMutation();
  // const [updateComboPass] = useUpdateComboPassMutation();
  const { data: allComboPasses } = useGetAllComboPassesQuery();

  // Add these handlers
  const handleEditComboPass = (comboPass: ComboPass) => {
    setComboPassFormData({
      title: comboPass.title,
      description: comboPass.description,
      clubIds: comboPass.club_ids,
      price: comboPass.price,
      imageUrl: comboPass.image_url || "",
    });
    setSelectedComboPassId(comboPass.id);
    setShowComboPassForm(true);
  };

  const handleDeleteComboPass = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this combo pass?")) {
      try {
        await deleteComboPass({
          variables: { deleteComboPassId: id },
        });
        toast.success("Combo pass deleted successfully!");
      } catch (error: any) {
        console.error("Error deleting combo pass:", error);
        toast.error(error.message || "Failed to delete combo pass");
      }
    }
  };

  const [showComboPassForm, setShowComboPassForm] = useState(false);
  const [comboPassFormData, setComboPassFormData] = useState<ComboPassFormData>(
    initialComboPassFormData
  );

  const resetComboPassForm = () => {
    setComboPassFormData(initialComboPassFormData);
    setSelectedComboPassId(null);
  };

  // Filter for club pass registrations
  const filterClubPassRegistrations = (registrations: ClubPassRegistration[]) => {
    if (!registrations) return [];
    if (!showOnlyIITStudents) return registrations;
    
    return registrations.filter((reg) => 
      !reg.user.email_primary?.endsWith("smail.iitm.ac.in")
    );
  };

  // Filter for combo pass registrations
  const filterComboPassRegistrations = (registrations: ComboPassRegistrationDetails[]) => {
    if (!registrations) return [];
    if (!showOnlyIITStudents) return registrations;
    
    return registrations.filter((reg) => 
      !reg.user.email_primary?.endsWith("smail.iitm.ac.in")
    );
  };

  const filteredRegistrations = filterEventRegistrations(
    (selectedEventForReg
      ? eventRegistrations?.geEventsRegistrationsByEventId
      : allRegistrations?.getAllEventRegistrations) || []
  );

  // For club pass registrations:
  const filteredClubPassRegistrations = filterClubPassRegistrations(
    clubPassRegistrations?.getClubPassRegistrations || []
  );

  // For combo pass registrations:
  const filteredComboPassRegistrations = filterComboPassRegistrations(
    allComboPassRegistrations?.getAllComboPassRegistrations || []
  );

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      {/* Header Section */}
      <div className="p-8 border-b border-gray-800">
        <div className="max-w-[1920px] mx-auto flex justify-between items-center">
          <h1 className="text-4xl font-bold bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">
            Events Management
          </h1>
          <div className="flex gap-4">
            <button
              onClick={() => navigate("/admin/escapeRoom")}
              className="inline-flex items-center px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-200 shadow-lg hover:shadow-indigo-500/25"
            >
              Escape Room Admin
            </button>
            <button
              onClick={() => setIsDialogOpen(true)}
              className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-200 shadow-lg hover:shadow-blue-500/25"
            >
              <Plus className="mr-2 h-5 w-5" />
              Add Event
            </button>
            <button
              onClick={() => setShowClubModal(true)}
              className="inline-flex items-center px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-all duration-200"
            >
              <Plus className="mr-2 h-5 w-5" />
              Manage Clubs
            </button>
            <button
              onClick={() => {
                const filteredRegistrations = filterEventRegistrations(
                  (selectedEventForReg
                    ? eventRegistrations?.geEventsRegistrationsByEventId
                    : allRegistrations?.getAllEventRegistrations) || []
                );
                const filename = selectedEventForReg
                  ? `registrations_${selectedEventForReg}.csv`
                  : "all_registrations.csv";
                downloadCSV(filteredRegistrations, filename);
              }}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors text-sm"
            >
              Download CSV
            </button>

            <button
              onClick={() => setShowRegistrations(true)}
              className="inline-flex items-center px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-all duration-200 shadow-lg hover:shadow-purple-500/25"
            >
              <Filter className="mr-2 h-5 w-5" />
              View Registrations
            </button>
            <button
              onClick={() => setShowClubPassRegistrations(true)}
              className="inline-flex items-center px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-all duration-200"
            >
              <Filter className="mr-2 h-5 w-5" />
              Club Pass Registrations
            </button>
            <button
              onClick={() => setShowComboPassRegistrations(true)}
              className="inline-flex items-center px-6 py-3 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 transition-all duration-200"
            >
              <Filter className="mr-2 h-5 w-5" />
              Combo Pass Registrations
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="p-8 max-w-[1920px] mx-auto">
        {/* Filters Section */}
        <div className="bg-gray-800 rounded-xl shadow-lg p-6 mb-8 border border-gray-700">
          <div className="flex items-center mb-4">
            <Filter className="h-5 w-5 text-blue-400 mr-2" />
            <h2 className="text-lg font-semibold text-gray-100">Filters</h2>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Club
              </label>
              <select
                value={selectedClub}
                onChange={(e) => setSelectedClub(e.target.value)}
                className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">All Clubs</option>
                {clubOptions.map((club) => (
                  <option key={club.id} value={club.id}>
                    {club.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Location
              </label>
              <select
                value={selectedLocation}
                onChange={(e) => setSelectedLocation(e.target.value)}
                className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">All Locations</option>
                {locationOptions.map((location) => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Events Grid */}
        {eventsLoading ? (
          <div className="text-center py-8">Loading events...</div>
        ) : displayEvents.length === 0 ? (
          <div className="text-center py-8">No events found</div>
        ) : (
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {displayEvents.map((event) => (
              <div
                key={event.id}
                className="bg-gray-800 rounded-xl shadow-lg hover:shadow-xl transition-all duration-200 border border-gray-700 hover:border-blue-500/50"
              >
                {event.image_url && (
                  <div className="aspect-video w-full overflow-hidden rounded-t-xl">
                    <img
                      src={event.image_url}
                      alt={event.title}
                      className="w-full h-full object-cover hover:scale-105 transition-transform duration-200"
                    />
                  </div>
                )}
                <div className="p-6">
                  <div className="flex justify-between items-start mb-4">
                    <h3 className="text-lg font-semibold text-gray-100 truncate">
                      {event.title}
                    </h3>
                    <div className="flex gap-2">
                      <button
                        //@ts-ignore
                        onClick={() => handleEdit(event)}
                        className="p-2 text-gray-400 hover:text-blue-400 rounded-full hover:bg-blue-500/10 transition-colors"
                      >
                        <Pencil className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleDelete(event.id)}
                        className="p-2 text-gray-400 hover:text-red-400 rounded-full hover:bg-red-500/10 transition-colors"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                  <p className="text-sm text-gray-400 mb-4">
                    {event.description[0]}
                  </p>
                  <div className="space-y-2 text-sm text-gray-400">
                    <p className="flex items-center">
                      <span className="w-5">📍</span>
                      <span className="text-gray-300">
                        {event.event_location}
                      </span>
                    </p>
                    <p className="flex items-center">
                      <span className="w-5">🎪</span>
                      <span className="text-gray-300">
                        {event.Club?.clubName || "N/A"}
                      </span>
                    </p>
                    <p className="flex items-center">
                      <span className="w-5">📅</span>
                      <span className="text-gray-300">
                        {new Date(event.event_date).toLocaleDateString()}
                      </span>
                    </p>
                    <p className="flex items-center">
                      <span className="w-5">💰</span>
                      <span className="text-gray-300">${event.price}</span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Modal Dialog */}
        {isDialogOpen && (
          <div className="fixed inset-0 bg-black/75 backdrop-blur-sm flex items-center justify-center p-4 z-50">
            <div className="bg-gray-800 rounded-xl max-w-md w-full max-h-[90vh] overflow-y-auto border border-gray-700 shadow-2xl">
              <div className="p-6 border-b border-gray-700">
                <h2 className="text-xl font-semibold text-gray-100">
                  {selectedEventId ? "Edit Event" : "Create Event"}
                </h2>
              </div>
              <form onSubmit={handleSubmit} className="p-6">
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Title
                    </label>
                    <input
                      type="text"
                      value={formData.title}
                      onChange={(e) =>
                        setFormData({ ...formData, title: e.target.value })
                      }
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Description (Bullet Points)
                    </label>
                    <div className="space-y-2">
                      {formData.description.map((point, index) => (
                        <div key={index} className="flex gap-2">
                          <input
                            type="text"
                            value={point}
                            onChange={(e) => {
                              const newDesc = [...formData.description];
                              newDesc[index] = e.target.value;
                              setFormData({
                                ...formData,
                                description: newDesc,
                              });
                            }}
                            className="flex-1 bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            placeholder={`Bullet point ${index + 1}`}
                          />
                          <button
                            type="button"
                            onClick={() => {
                              const newDesc = formData.description.filter(
                                (_, i) => i !== index
                              );
                              setFormData({
                                ...formData,
                                description: newDesc,
                              });
                            }}
                            className="px-2 py-1 bg-red-600/20 text-red-400 rounded-lg hover:bg-red-600/30"
                          >
                            ✕
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            description: [...formData.description, ""],
                          });
                        }}
                        className="w-full px-4 py-2 bg-gray-700 text-gray-300 rounded-lg hover:bg-gray-600 transition-colors flex items-center justify-center gap-2"
                      >
                        <Plus className="h-4 w-4" />
                        Add Bullet Point
                      </button>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Event Date
                    </label>
                    <input
                      type="date"
                      value={formData.eventDate}
                      onChange={(e) =>
                        setFormData({ ...formData, eventDate: e.target.value })
                      }
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Price
                    </label>
                    <input
                      type="number"
                      value={formData.price}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          price: parseFloat(e.target.value),
                        })
                      }
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Location
                    </label>
                    <input
                      type="text"
                      value={formData.eventLocation}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          eventLocation: e.target.value,
                        })
                      }
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Image URL
                    </label>
                    <input
                      type="url"
                      value={formData.imageUrl}
                      onChange={(e) =>
                        setFormData({ ...formData, imageUrl: e.target.value })
                      }
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Club
                    </label>
                    <select
                      value={formData.clubId}
                      onChange={(e) =>
                        setFormData({ ...formData, clubId: e.target.value })
                      }
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="">Select a Club</option>
                      {clubsData?.getClubs?.map((club) => (
                        <option key={club.id} value={club.id}>
                          {club.clubName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.registrationsClosed}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          registrationsClosed: e.target.checked,
                        })
                      }
                      className="h-4 w-4 bg-gray-700 border-gray-600 rounded text-blue-500 focus:ring-blue-500 focus:ring-offset-gray-800"
                    />
                    <label className="ml-2 block text-sm text-gray-300">
                      Registrations Closed
                    </label>
                  </div>
                </div>
                <div className="mt-6 flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => setIsDialogOpen(false)}
                    className="px-4 py-2 text-gray-300 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors shadow-lg hover:shadow-blue-500/25"
                  >
                    {selectedEventId ? "Update" : "Create"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      {showRegistrations && (
        <div className="fixed inset-0 bg-black/75 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-gray-800 rounded-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto border border-gray-700 shadow-2xl">
            <div className="p-6 border-b border-gray-700">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold text-gray-100">
                  Event Registrations
                </h2>
                <button
                  onClick={() => {
                    setShowRegistrations(false);
                    setSelectedEventForReg(null);
                  }}
                  className="text-gray-400 hover:text-gray-200"
                >
                  ✕
                </button>
              </div>
            </div>

            <div className="p-6">
              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Filter by Event
                </label>
                <select
                  value={selectedEventForReg || ""}
                  onChange={(e) =>
                    setSelectedEventForReg(e.target.value || null)
                  }
                  className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-gray-200"
                >
                  <option value="">Select event</option>
                  {allEvents?.getEvents?.map((event) => (
                    <option key={event.id} value={event.id}>
                      {event.title}
                    </option>
                  ))}
                </select>
              </div>
              {/* Add a download button for current filtered event */}
              {selectedEventForReg && (
                <div className="mb-4">
                  <button
                    onClick={() => {
                      console.log(
                        "event bro:",
                        eventRegistrations?.geEventsRegistrationsByEventId || []
                      );
                      console.log(
                        "selected event bro 2 :",
                        selectedEventForReg
                      );
                      const eventRegistrationData =
                        eventRegistrations?.geEventsRegistrationsByEventId ||
                        [];
                      const eventTitle =
                        allEvents?.getEvents?.find(
                          (e) => e.id === selectedEventForReg
                        )?.title || "unknown";
                      downloadCSV(
                        eventRegistrationData.map((reg) => ({
                          user: reg.user,
                          event: reg.event,
                          registration: reg.registration || reg,
                        })),
                        `event_registrations_${eventTitle}.csv`
                      );
                    }}
                    className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                  >
                    Download{" "}
                    {
                      allEvents?.getEvents?.find(
                        (e) => e.id === selectedEventForReg
                      )?.title
                    }{" "}
                    Registrations
                  </button>
                </div>
              )}

              <div className="overflow-x-auto">
                <table className="w-full text-left text-gray-200">
                  <thead className="text-sm text-gray-400 uppercase bg-gray-700">
                    <tr>
                      <th className="px-4 py-3">User Details</th>
                      <th className="px-4 py-3">Event Details</th>
                      <th className="px-4 py-3">Registration Details</th>
                      <th className="px-4 py-3">Payment Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredRegistrations?.map((reg: any) => (
                      <tr
                        key={reg.registration?.id}
                        className="border-b border-gray-700"
                      >
                        <td className="px-4 py-3">
                          <div className="space-y-1">
                            <div className="font-medium">
                              {reg.user?.username || "Unknown User"}
                            </div>
                            <div className="text-sm text-gray-400">
                              {reg.user?.email_primary}
                            </div>

                            {reg.user?.mobile_number_primary && (
                              <div className="text-sm text-gray-400">
                                Mobile: {reg.user.mobile_number_primary}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="px-4 py-3">
                          <div className="space-y-1">
                            <div className="font-medium">
                              {reg.event?.title || "Unknown Event"}
                            </div>
                            {/* {reg.event?.description && (
                              <div className="text-sm text-gray-400">
                                {Array.isArray(reg.event.description)
                                  ? reg.event.description[0]
                                  : reg.event.description}
                              </div>
                            )} */}
                            {reg.event?.price && (
                              <div className="text-sm text-gray-400">
                                Price: ₹{reg.event.price}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="px-4 py-3">
                          <div className="space-y-1">
                            <div>
                              {reg.registration?.reg_date || reg.reg_date}
                            </div>
                            {reg.registration?.price && (
                              <div className="text-sm text-gray-400">
                                Paid Amount: ₹{reg.registration.price}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="px-4 py-3">
                          <div className="space-y-2">
                            <span
                              className={`px-2 py-1 rounded-full text-xs ${
                                reg.registration?.verified || reg.verified
                                  ? "bg-green-500/20 text-green-400"
                                  : "bg-yellow-500/20 text-yellow-400"
                              }`}
                            >
                              {reg.registration?.verified || reg.verified
                                ? "Verified"
                                : "Pending"}
                            </span>
                            {reg.registration?.razorpay_order_id && (
                              <div className="text-xs text-gray-400 break-all">
                                Order ID: {reg.registration.razorpay_order_id}
                              </div>
                            )}
                            {/* {reg.registration?.paid !== undefined && (
                              <div className="text-xs text-gray-400">
                                Payment:{" "}
                                {reg.registration.paid
                                  ? "Completed"
                                  : "Pending"}
                              </div>
                            )} */}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {showClubModal && (
        <div className="fixed inset-0 bg-black/75 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-gray-800 rounded-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto border border-gray-700">
            <div className="p-6 border-b border-gray-700">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold text-gray-100">
                  Manage Clubs
                </h2>
                <button
                  onClick={() => setShowClubModal(false)}
                  className="text-gray-400 hover:text-gray-200"
                >
                  ✕
                </button>
              </div>
            </div>

            {/* Club List */}
            <div className="p-6">
              <div className="mb-6">
                <button
                  onClick={() => {
                    resetClubForm();
                    setSelectedClubId(null);
                    setClubFormData(initialClubFormData);
                    setShowClubModal(true);
                  }}
                  className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-200"
                >
                  <Plus className="mr-2 h-4 w-4" />
                  Add New Club
                </button>
              </div>

              {/* Club Form (shown when adding/editing) */}
              {(selectedClubId !== null || showClubModal) && (
                <div className="mb-8 bg-gray-700 p-6 rounded-lg">
                  <form onSubmit={handleClubSubmit} className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">
                        Club Name
                      </label>
                      <input
                        type="text"
                        value={clubFormData.clubName}
                        onChange={(e) =>
                          setClubFormData({
                            ...clubFormData,
                            clubName: e.target.value,
                          })
                        }
                        className="w-full bg-gray-600 border border-gray-500 rounded-lg px-3 py-2 text-gray-200"
                        required
                      />
                    </div>
                    {/* <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">
                        Logo URL
                      </label>
                      <input
                        type="url"
                        value={clubFormData.logo}
                        onChange={(e) =>
                          setClubFormData({
                            ...clubFormData,
                            logo: e.target.value,
                          })
                        }
                        className="w-full bg-gray-600 border border-gray-500 rounded-lg px-3 py-2 text-gray-200"
                      />
                    </div> */}
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">
                        Club Pass Price
                      </label>
                      <input
                        type="number"
                        value={clubFormData.clubPassPrice}
                        onChange={(e) =>
                          setClubFormData({
                            ...clubFormData,
                            clubPassPrice: parseFloat(e.target.value),
                          })
                        }
                        className="w-full bg-gray-600 border border-gray-500 rounded-lg px-3 py-2 text-gray-200"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">
                        Category
                      </label>
                      <select
                        value={clubFormData.category}
                        onChange={(e) =>
                          setClubFormData({
                            ...clubFormData,
                            category: e.target.value,
                          })
                        }
                        className="w-full bg-gray-600 border border-gray-500 rounded-lg px-3 py-2 text-gray-200"
                      >
                        <option value="">Select a category</option>
                        <option value="Performing Arts">Performing Arts</option>
                        <option value="Literary Arts">Literary Arts</option>
                        <option value="Creative and Digital Arts">
                          Creative and Digital Arts
                        </option>
                        <option value="Non Competitive Events">
                          Non Competitive Events
                        </option>
                        <option value="workshops">Workshops</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">
                        Contact Name
                      </label>
                      <input
                        type="text"
                        value={clubFormData.contactName || ""}
                        onChange={(e) =>
                          setClubFormData({
                            ...clubFormData,
                            contactName: e.target.value,
                          })
                        }
                        className="w-full bg-gray-600 border border-gray-500 rounded-lg px-3 py-2 text-gray-200"
                        placeholder="Contact Person Name"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">
                        Contact Number
                      </label>
                      <input
                        type="number"
                        value={clubFormData.contactNumber || ""}
                        onChange={(e) =>
                          setClubFormData({
                            ...clubFormData,
                            contactNumber: e.target.value,
                          })
                        }
                        className="w-full bg-gray-600 border border-gray-500 rounded-lg px-3 py-2 text-gray-200"
                        placeholder="Contact Number"
                      />
                    </div>
                    <div className="flex justify-end gap-3">
                      <button
                        type="button"
                        onClick={resetClubForm}
                        className="px-4 py-2 text-gray-300 bg-gray-600 rounded-lg hover:bg-gray-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                      >
                        {selectedClubId ? "Update" : "Create"}
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {/* Clubs Grid */}
              <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {clubsData?.getClubs?.map((club) => (
                  <div
                    key={club.id}
                    className="bg-gray-700 rounded-lg p-4 border border-gray-600"
                  >
                    <div className="flex items-center justify-between mb-4">
                      <div className="flex items-center">
                        <img
                          src={club.logo || ""}
                          alt={club.clubName}
                          className="w-10 h-10 rounded-full object-cover mr-3"
                        />
                        <div>
                          <h3 className="font-semibold text-gray-200">
                            {club.clubName}
                          </h3>
                          <p className="text-sm text-gray-400">
                            Pass Price: ${club.clubPassPrice}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <button
                          // @ts-ignore
                          onClick={() => handleEditClub(club)}
                          className="p-2 text-gray-400 hover:text-blue-400 rounded-full hover:bg-blue-500/10"
                        >
                          <Pencil className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleDeleteClub(club.id)}
                          className="p-2 text-gray-400 hover:text-red-400 rounded-full hover:bg-red-500/10"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {showClubPassRegistrations && (
        <div className="fixed inset-0 bg-black/75 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-gray-800 rounded-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto border border-gray-700 shadow-2xl">
            <div className="p-6 border-b border-gray-700">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold text-gray-100">
                  Club Pass Registrations
                </h2>
                <button
                  onClick={() => {
                    setShowClubPassRegistrations(false);
                    setSelectedClubForPassReg(null);
                  }}
                  className="text-gray-400 hover:text-gray-200"
                >
                  ✕
                </button>
              </div>
            </div>

            <div className="p-6">
              <div className="mb-6 flex justify-between items-center">
                <select
                  value={selectedClubForPassReg || ""}
                  onChange={(e) =>
                    setSelectedClubForPassReg(e.target.value || null)
                  }
                  className="bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-gray-200"
                >
                  <option value="">Select Club</option>
                  {clubsData?.getClubs?.map((club) => (
                    <option key={club.id} value={club.id}>
                      {club.clubName}
                    </option>
                  ))}
                </select>
                <button
                  onClick={() => {
                    const filteredRegistrations = filterClubPassRegistrations(
                      (clubPassRegistrations?.getClubPassRegistrations) || []
                    );
                    const selectedClubName = selectedClubForPassReg
                      ? clubsData?.getClubs?.find(
                          (club) => club.id === selectedClubForPassReg
                        )?.clubName
                      : "all";
                    downloadClubPassCSV(
                      filteredRegistrations,
                      `club_pass_registrations_${selectedClubName}.csv`
                    );
                  }}
                  className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                >
                  Download CSV
                </button>
              </div>

              <div className="overflow-x-auto">
                <table className="w-full text-left text-gray-200">
                  <thead className="text-sm text-gray-400 uppercase bg-gray-700">
                    <tr>
                      <th className="px-4 py-3">User</th>
                      <th className="px-4 py-3">Registration Date</th>
                      <th className="px-4 py-3">Order ID</th>
                      <th className="px-4 py-3">Status</th>
                      {/* <th className="px-4 py-3">Registered Events</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredClubPassRegistrations?.map(
                      (reg) => (
                        <tr
                          key={reg.registration.id}
                          className="border-b border-gray-700"
                        >
                          <td className="px-4 py-3">{reg.user.username}</td>
                          <td className="px-4 py-3">
                            {new Date(
                              reg.registration.reg_date
                            ).toLocaleDateString()}
                          </td>
                          <td className="px-4 py-3">
                            {reg.registration.razorpay_order_id}
                          </td>
                          <td className="px-4 py-3">
                            {/* <span
                              className={`px-2 py-1 rounded-full text-xs ${
                                reg.registration.paid
                                  ? "bg-green-500/20 text-green-400"
                                  : "bg-yellow-500/20 text-yellow-400"
                              }`}
                            >
                              {reg.registration.paid
                                ? "Verified"
                                : "Pending"}
                            </span> */}
                          </td>
                          <td className="px-4 py-3">
                            {/* {reg.registered_event_ids.length} */}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {showComboPassRegistrations && (
        <div className="fixed inset-0 bg-black/75 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-gray-800 rounded-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto border border-gray-700 shadow-2xl">
            <div className="p-6 border-b border-gray-700">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold text-gray-100">
                  Combo Pass Management
                </h2>

                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Filter Registrations by Combo Pass
                  </label>
                  <select
                    value={selectedComboPassForReg || ""}
                    onChange={(e) =>
                      setSelectedComboPassForReg(e.target.value || null)
                    }
                    className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-gray-200 mb-4"
                  >
                    <option value="">All Combo Passes</option>
                    {allComboPasses?.getAllComboPasses?.map(({ comboPass }) => (
                      <option key={comboPass.id} value={comboPass.id}>
                        {comboPass.title}
                      </option>
                    ))}
                  </select>
                </div>

                <button
                  onClick={() => {
                    setShowComboPassRegistrations(false);
                    setSelectedComboPassId(null);
                  }}
                  className="text-gray-400 hover:text-gray-200"
                >
                  ✕
                </button>
              </div>
            </div>

            <div className="p-6">
              <div className="mb-6 flex justify-between items-center">
                <button
                  onClick={() => {
                    setComboPassFormData(initialComboPassFormData);
                    setSelectedComboPassId(null);
                    setShowComboPassForm(true);
                  }}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Add Combo Pass
                </button>
                <button
                  onClick={() => {
                    const filteredRegistrations = filterComboPassRegistrations(
                      (allComboPassRegistrations?.getAllComboPassRegistrations) || []
                    );
                    const comboPassTitle = selectedComboPassForReg
                      ? allComboPasses?.getAllComboPasses?.find(
                          ({ comboPass }) => comboPass.id === selectedComboPassForReg
                        )?.comboPass.title || "unknown"
                      : "all";
                    downloadFilteredComboPassCSV(filteredRegistrations, comboPassTitle);
                  }}
                  className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
                >
                  Download CSV
                </button>
              </div>

              {showComboPassForm && (
                <div className="mb-8 bg-gray-700 p-6 rounded-lg">
                  <form onSubmit={handleComboPassSubmit} className="space-y-4">
                    <input
                      type="text"
                      placeholder="Title"
                      value={comboPassFormData.title}
                      onChange={(e) =>
                        setComboPassFormData({
                          ...comboPassFormData,
                          title: e.target.value,
                        })
                      }
                      className="w-full bg-gray-600 border border-gray-500 rounded-lg px-3 py-2 text-gray-200"
                      required
                    />
                    {comboPassFormData.description.map((desc, index) => (
                      <div key={index} className="flex gap-2">
                        <input
                          type="text"
                          placeholder={`Description point ${index + 1}`}
                          value={desc}
                          onChange={(e) => {
                            const newDesc = [...comboPassFormData.description];
                            newDesc[index] = e.target.value;
                            setComboPassFormData({
                              ...comboPassFormData,
                              description: newDesc,
                            });
                          }}
                          className="flex-1 bg-gray-600 border border-gray-500 rounded-lg px-3 py-2 text-gray-200"
                        />
                        <button
                          type="button"
                          onClick={() => {
                            const newDesc =
                              comboPassFormData.description.filter(
                                (_, i) => i !== index
                              );
                            setComboPassFormData({
                              ...comboPassFormData,
                              description: newDesc,
                            });
                          }}
                          className="px-2 py-1 bg-red-600/20 text-red-400 rounded-lg hover:bg-red-600/30"
                        >
                          ✕
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => {
                        setComboPassFormData({
                          ...comboPassFormData,
                          description: [...comboPassFormData.description, ""],
                        });
                      }}
                      className="w-full px-4 py-2 bg-gray-600 text-gray-300 rounded-lg hover:bg-gray-500"
                    >
                      Add Description Point
                    </button>
                    <select
                      multiple
                      value={comboPassFormData.clubIds}
                      onChange={(e) =>
                        setComboPassFormData({
                          ...comboPassFormData,
                          clubIds: Array.from(
                            e.target.selectedOptions,
                            (option) => option.value
                          ),
                        })
                      }
                      className="w-full bg-gray-600 border border-gray-500 rounded-lg px-3 py-2 text-gray-200"
                      required
                    >
                      {clubsData?.getClubs?.map((club) => (
                        <option key={club.id} value={club.id}>
                          {club.clubName}
                        </option>
                      ))}
                    </select>
                    <input
                      type="number"
                      placeholder="Price"
                      value={comboPassFormData.price}
                      onChange={(e) =>
                        setComboPassFormData({
                          ...comboPassFormData,
                          price: parseFloat(e.target.value),
                        })
                      }
                      className="w-full bg-gray-600 border border-gray-500 rounded-lg px-3 py-2 text-gray-200"
                      required
                    />
                    <input
                      type="url"
                      placeholder="Image URL"
                      value={comboPassFormData.imageUrl}
                      onChange={(e) =>
                        setComboPassFormData({
                          ...comboPassFormData,
                          imageUrl: e.target.value,
                        })
                      }
                      className="w-full bg-gray-600 border border-gray-500 rounded-lg px-3 py-2 text-gray-200"
                    />
                    <div className="flex justify-end gap-3">
                      <button
                        type="button"
                        onClick={() => {
                          setShowComboPassForm(false);
                          setSelectedComboPassId(null);
                        }}
                        className="px-4 py-2 bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                      >
                        {selectedComboPassId ? "Update" : "Create"}
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {/* Combo Passes Grid */}
              <div className="grid gap-6 grid-cols-1 md:grid-cols-2">
                {allComboPasses?.getAllComboPasses?.map(
                  ({ comboPass, clubs }) => (
                    <div
                      key={comboPass.id}
                      className="bg-gray-700 rounded-lg p-4 border border-gray-600"
                    >
                      <div className="flex justify-between items-start mb-4">
                        <div>
                          <h3 className="font-semibold text-gray-200">
                            {comboPass.title}
                          </h3>
                          <p className="text-sm text-gray-400">
                            ₹{comboPass.price}
                          </p>
                          {/* <p className="text-sm text-gray-400">
                            Valid till:{" "}
                            {new Date(
                              comboPass.valid_till
                            ).toLocaleDateString()}
                          </p>
                          <p className="text-sm text-gray-400">
                            Registrations: {comboPass.current_registrations}/
                            {comboPass.max_registrations}
                          </p> */}
                        </div>
                        <div className="flex gap-2">
                          <button
                            onClick={() => handleEditComboPass(comboPass)}
                            className="p-2 text-gray-400 hover:text-blue-400 rounded-full hover:bg-blue-500/10"
                          >
                            <Pencil className="h-4 w-4" />
                          </button>
                          <button
                            onClick={() => handleDeleteComboPass(comboPass.id)}
                            className="p-2 text-gray-400 hover:text-red-400 rounded-full hover:bg-red-500/10"
                          >
                            <Trash2 className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                      <div className="text-sm text-gray-400">
                        <p className="font-medium text-gray-300 mb-1">
                          Included Clubs:
                        </p>
                        <ul className="list-disc list-inside">
                          {clubs.map((club) => (
                            <li key={club.id}>{club.clubName}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )
                )}
              </div>

              {/* Registrations Table in Combo Pass Management */}
              <div className="mt-8 hidden">
                <h3 className="text-lg font-semibold text-gray-200 mb-4">
                  Registrations
                </h3>
                <table className="w-full hidden">
                  {/* <thead className="text-sm text-gray-400 uppercase bg-gray-700">
                    <tr>
                      <th className="px-4 py-3 text-left">User Details</th>
                      <th className="px-4 py-3 text-left">Pass Details</th>
                      <th className="px-4 py-3 text-left">
                        Registration Details
                      </th>
                      <th className="px-4 py-3 text-left">Payment Status</th>
                    </tr>
                  </thead> */}
                  <tbody>
                    {filteredComboPassRegistrations.map(
                      (reg: ComboPassRegistrationDetails) => (
                        <tr
                          key={
                            reg.registration.payment_id ||
                            reg.registration.razorpay_order_id
                          }
                          className="border-b border-gray-700"
                        >
                          <td className="px-4 py-3">
                            <div className="space-y-1">
                              <div className="font-medium">
                                {reg.user?.username || "Unknown User"}
                              </div>
                              {reg.user?.email_primary && (
                                <div className="text-sm text-gray-400">
                                  {reg.user.email_primary}
                                </div>
                              )}
                              {reg.user?.mobile_number_primary && (
                                <div className="text-sm text-gray-400">
                                  Mobile: {reg.user.mobile_number_primary}
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="space-y-1">
                              <div className="font-medium">
                                {/* {reg.comboPass?.title || "Unknown Pass"} */}
                              </div>
                              <div className="text-sm text-gray-400">
                                Clubs:{" "}
                                {reg.clubs
                                  .map((club: any) => club.clubName)
                                  .join(", ")}
                              </div>
                              {reg.comboPass?.description && (
                                <div className="text-sm text-gray-400">
                                  {Array.isArray(reg.comboPass.description)
                                    ? reg.comboPass.description[0]
                                    : reg.comboPass.description}
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="space-y-1">
                              <div>{reg.registration.reg_date}</div>
                              {reg.comboPass?.current_registrations && (
                                <div className="text-sm text-gray-400">
                                  Registration #
                                  {reg.comboPass.current_registrations}
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="space-y-2">
                              <span
                                className={`px-2 py-1 rounded-full text-xs ${
                                  reg.registration.razorpay_order_id
                                    ? "bg-green-500/20 text-green-400"
                                    : "bg-yellow-500/20 text-yellow-400"
                                }`}
                              >
                                {reg.registration.razorpay_order_id
                                  ? "Verified"
                                  : "Pending"}
                              </span>
                              {reg.registration.razorpay_order_id && (
                                <div className="text-xs text-gray-400 break-all">
                                  Order ID: {reg.registration.razorpay_order_id}
                                </div>
                              )}
                              {reg.registration.payment_id && (
                                <div className="text-xs text-gray-400 break-all">
                                  Payment ID: {reg.registration.payment_id}
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="fixed bottom-4 right-4 z-50">
        <button
          onClick={() => setShowOnlyIITStudents(!showOnlyIITStudents)}
          className={`px-6 py-3 rounded-full shadow-lg flex items-center gap-2 transition-all duration-200 ${
            showOnlyIITStudents 
            ? 'bg-blue-600 hover:bg-blue-700' 
            : 'bg-gray-700 hover:bg-gray-600'
          }`}
        >
          <span className={`text-sm ${showOnlyIITStudents ? 'text-white' : 'text-gray-300'}`}>
            {showOnlyIITStudents ? 'Showing External Participants' : 'Showing All Registrations'}
          </span>
          <div className={`w-10 h-6 rounded-full p-1 ${showOnlyIITStudents ? 'bg-blue-800' : 'bg-gray-600'}`}>
            <div className={`w-4 h-4 rounded-full bg-white transform transition-transform duration-200 ${
              showOnlyIITStudents ? 'translate-x-4' : 'translate-x-0'
            }`} />
          </div>
        </button>
      </div>
    </div>
  );
};

export default EventsAdminPortal;
