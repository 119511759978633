import React, { useState } from 'react';
import './aa.css';
import { ParallaxProvider, useParallax } from 'react-scroll-parallax';
import { motion } from 'framer-motion';
import Hnav from '../HomeNavbar/Hnav';
import { useNavigate } from 'react-router-dom';

interface ComponentProps {
  title: string;
  videoSrc: string;
  line?: string;

  poster1?: string;
  poster2?: string;
  poster3?: string;
  poster4?: string;
  poster5?: string;
  poster6?: string;
  poster7?: string;
  poster8?: string;
  poster9?: string;
  poster10?: string;
  poster11?: string;
  poster12?: string;
  poster13?: string;
  button?: string;
  link?: string;
  navigation?:boolean;

  active ?:string;
}

function Component({
  title,
  videoSrc,
  poster1,
  poster2,
  line,
  poster3,
  poster4,
  poster5,
  poster6,
  poster7,
  poster8,
  poster9,
  poster10,
  poster11,
  poster12,
  button,
  link,
  active,
  navigation
}: ComponentProps) {
  const parallax = useParallax<HTMLDivElement>({
    translateX: [10, 10],
    translateY: [-200, 200],
    scale: [1, 1.2],
  });

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.8, y: 100 },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 200,
        damping: 15,
        duration: 0.5,
      },
    },
  };

  // All posters
  const posters = [poster1, poster2, poster3, poster4, poster5, poster6, poster7, poster8,poster9,poster10,poster11,poster12,line];
  const filledPosters = posters.filter(Boolean); // Remove any empty poster slots
  const totalPosters = filledPosters.length;

  // Helper function to determine rows based on poster count
// Helper function to determine rows based on poster count
const renderRows = () => {
  if (totalPosters <= 4) {
    return (
      <div className="flex flex-col md:mt-[60px] md:mb-[30px] sm:mt-0 sm:mb-0 sm:flex-row sm:flex-wrap sm:gap-4 justify-center">
        {filledPosters.map((poster, index) => (
          <motion.div
            key={index}
            className="flex justify-center rounded-2xl w-full md:mb-[0vh] mb-[5vh]  sm:w-[calc(25%-1rem)]"
            variants={cardVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
          >
            <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 1}`} />
          </motion.div>
        ))}
      </div>
    );
  } else if (totalPosters === 5) {
    return (
      <>
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:gap-4 justify-center">
          {filledPosters.slice(0, 3).map((poster, index) => (
            <motion.div
              key={index}
              className="flex justify-center rounded-2xl w-full md:mb-[0vh] mb-[5vh]  sm:w-[calc(33.33%-1rem)]"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 1}`} />
            </motion.div>
          ))}
        </div>
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:gap-4 mt-4 justify-center">
          {filledPosters.slice(3).map((poster, index) => (
            <motion.div
              key={index + 3}
              className="flex justify-center rounded-2xl w-full md:mb-[0vh] mb-[5vh]  sm:w-[calc(33.33%-1rem)]"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 4}`} />
            </motion.div>
          ))}
        </div>
      </>
    );
  } else if (totalPosters === 6) {
    return (
      <>
        <div className="flex flex-col sm:flex-row sm:flex-wrap md:gap-12 sm:gap-4 justify-center">
          {filledPosters.slice(0, 3).map((poster, index) => (
            <motion.div
              key={index}
              className="flex justify-center rounded-2xl w-full md:mb-[0vh] mb-[5vh]  sm:w-[calc(25%-1rem)]"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 1}`} />
            </motion.div>
          ))}
        </div>
        <div className="flex flex-col sm:flex-row sm:flex-wrap md:gap-12 sm:gap-4 mt-4 justify-center">
          {filledPosters.slice(3, 6).map((poster, index) => (
            <motion.div
              key={index + 3}
              className="flex justify-center rounded-2xl w-full md:mb-[0vh] mb-[5vh]  sm:w-[calc(25%-1rem)]"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 4}`} />
            </motion.div>
          ))}
        </div>
      </>
    );
  } else if (totalPosters === 9) {
    return (
      <>
        <div className="flex flex-col sm:flex-row sm:flex-wrap md:gap-12 sm:gap-4 justify-center">
          {filledPosters.slice(0, 3).map((poster, index) => (
            <motion.div
              key={index}
              className="flex justify-center rounded-2xl w-full md:mb-[0vh] mb-[5vh]  sm:w-[calc(25%-1rem)]"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 1}`} />
            </motion.div>
          ))}
        </div>
        <div className="flex flex-col sm:flex-row sm:flex-wrap md:gap-12 sm:gap-4 mt-4 justify-center">
          {filledPosters.slice(3, 6).map((poster, index) => (
            <motion.div
              key={index + 3}
              className="flex justify-center rounded-2xl w-full md:mb-[0vh] mb-[5vh]  sm:w-[calc(25%-1rem)]"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 4}`} />
            </motion.div>
          ))}
        </div>
        <div className="flex flex-col sm:flex-row sm:flex-wrap md:gap-12 sm:gap-4 mt-4 justify-center">
          {filledPosters.slice(6, 9).map((poster, index) => (
            <motion.div
              key={index + 3}
              className="flex justify-center rounded-2xl w-full md:mb-[0vh] mb-[5vh]  sm:w-[calc(25%-1rem)]"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 4}`} />
            </motion.div>
          ))}
        </div>
      </>
    );
  } else if (totalPosters === 4) {
    return (
      <>
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:gap-4 justify-center">
          {filledPosters.slice(0, 2).map((poster, index) => (
            <motion.div
              key={index}
              className="flex justify-center rounded-2xl w-full  md:mb-[0vh] mb-[5vh]  sm:w-[calc(50%-1rem)]"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 1}`} />
            </motion.div>
          ))}
        </div>
        <div className="flex flex-col sm:flex-row sm:flex-wrap md:mb-[0vh] mb-[5vh]  sm:gap-4 mt-4 justify-center">
          {filledPosters.slice(2, 4).map((poster, index) => (
            <motion.div
              key={index + 2}
              className="flex justify-center rounded-2xl w-full sm:w-[calc(50%-1rem)]"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 3}`} />
            </motion.div>
          ))}
        </div>
      </>
    );
  } else if (totalPosters <= 8) {
    return (
      <>
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:gap-5 justify-center">
          {filledPosters.slice(0, 4).map((poster, index) => (
            <motion.div
              key={index}
              className="flex justify-center rounded-2xl w-full  md:mb-[0vh] mb-[5vh] sm:w-[calc(25%-1rem)] "
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 1}`} />
            </motion.div>
          ))}
        </div>
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:gap-4 mt-4 justify-center">
          {filledPosters.slice(4, 8).map((poster, index) => (
            <motion.div
              key={index + 4}
              className="flex justify-center rounded-2xl w-full md:mb-[0vh] mb-[5vh]  sm:w-[calc(25%-1rem)]"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 5}`} />
            </motion.div>
          ))
        
          }
        </div>
      </>
      
    );
  }
else if (totalPosters>10){
    return (
      <>
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:gap-4 justify-center">
          {filledPosters.slice(0, 4).map((poster, index) => (
            <motion.div
              key={index}
              className="flex justify-center rounded-2xl w-full  md:mb-[0vh] mb-[5vh] sm:w-[calc(25%-1rem)] "
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 1}`} />
            </motion.div>
          ))}
        </div>
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:gap-4 mt-4 justify-center">
          {filledPosters.slice(4, 8).map((poster, index) => (
            <motion.div
              key={index + 4}
              className="flex justify-center rounded-2xl w-full md:mb-[0vh] mb-[5vh]  sm:w-[calc(25%-1rem)]"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 5}`} />
            </motion.div>
          ))}
        </div>
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:gap-4 mt-4 justify-center">
          {filledPosters.slice(8, 12).map((poster, index) => (
            <motion.div
              key={index + 4}
              className="flex justify-center rounded-2xl w-full md:mb-[0vh] mb-[5vh]  sm:w-[calc(25%-1rem)]"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 5}`} />
            </motion.div>
          ))}
        </div>
      </>
      
    );     
  }
  
else if (totalPosters==12){
    return (
      <>
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:gap-4 justify-center">
          {filledPosters.slice(0, 4).map((poster, index) => (
            <motion.div
              key={index}
              className="flex justify-center rounded-2xl w-full  md:mb-[0vh] mb-[5vh] sm:w-[calc(25%-1rem)] "
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 1}`} />
            </motion.div>
          ))}
        </div>
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:gap-4 mt-4 justify-center">
          {filledPosters.slice(4, 8).map((poster, index) => (
            <motion.div
              key={index + 4}
              className="flex justify-center rounded-2xl w-full md:mb-[0vh] mb-[5vh]  sm:w-[calc(25%-1rem)]"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 5}`} />
            </motion.div>
          ))}
        </div>
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:gap-4 mt-4 justify-center">
          {filledPosters.slice(8, 12).map((poster, index) => (
            <motion.div
              key={index + 4}
              className="flex justify-center rounded-2xl w-full md:mb-[0vh] mb-[5vh]  sm:w-[calc(25%-1rem)]"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
            >
              <img src={poster} className="w-full rounded-2xl" alt={`Poster ${index + 5}`} />
            </motion.div>
          ))}
        </div>
      </>
      
    );     
  }
  
};
const navigate = useNavigate()
const handleNavigation=(navigation:any,link:any)=>{
if(navigation){
navigate(link)
}
else{
  window.open(link,"_blank")
}
}

  return (
    <motion.div className="Proshw font-unlock">
             

      <motion.div className="relative h-screen w-full overflow-hidden">
        <video
          className="absolute top-1/2 left-1/2 w-full h-full object-cover transform -translate-x-1/2 -translate-y-1/2"
          autoPlay
          muted
          loop
        >
          <source src={videoSrc} type="video/mp4" />
        </video>

        <motion.div
          ref={parallax.ref}
          className="relative z-10 flex flex-col items-center justify-center text-[#ea9901] parallax-box md:ml-[2vw] ml-[2vw]"
        >
          {title}
        </motion.div>

        <motion.div className="absolute inset-0 h-full bg-[black] bg-opacity-50 z-0"></motion.div>
      </motion.div>

      <motion.div className="w-[100vw] h-full bg-[black] flex flex-col justify-center items-center py-[5vh]">
       



{active === 'first' && (
         <motion.div className="flex flex-col items-center h-full justify-center gap-4 max-w-[80vw]">
         {renderRows()}
       </motion.div>
      )}

      {active === 'second' && (
       <section className="wrapper" >
       <div className="top">cominng soon</div>
       <div className="bottom" aria-hidden="true">cominng soon</div>
     </section>
      )}

{button && (
          <div className="container flex justify-center items-center mt-[4vh]">
           <button onClick={()=>handleNavigation(navigation,link)}className="btn text-[#000000] font-unlock">{button}</button>
          </div>
        )}
      </motion.div>
    </motion.div>
  );
}

// const ComingSoon = () => {
  

//   return (
//     <section className="wrapper">
//   <div className="top">cominng soon</div>
//   <div className="bottom" aria-hidden="true">cominng soon</div>
// </section>
//   );
// };


const Proshows = () => {
  return (
    <ParallaxProvider>
      
      <Component
        title="PROSHOWS"
        videoSrc="/rock.mp4"
        poster1="/proshow1.jpg"
        poster2="/proshow2.jpg"
        poster3="/proshow3.png"
        poster4="/proshow4.png"
        poster5="/proshow8.png"
        poster6="/proshow6.jpg"
        poster7="/proshow7.png"
        poster8="/proshow9.png"
        active="first"
        link="https://sales.saarang.org/#proshows"
        navigation={false}
        button="GET TICKETS"
      />
      <Component
        title="WORLDFEST"
        videoSrc="/world.mp4"
        poster1="/worldfest1.png"
        poster2="/worldfest2.png"
        poster3="/worldfest3.png"
        poster4="/worldfest4.png"
        poster5="/worldfest5.png"
        poster6="/worldfest6.png"
        poster7="/worldfest7.png"
        poster8="/worldfest8.png"
        poster9="/worldfest9.png"
        button=""
        active="first"

      />
      <Component
        title="SPOTLIGHTS"
        videoSrc="/spotlight.mp4"
        poster1="spot1.png"
        poster2="spot2.png"
        poster3="spot3.png"
        poster4="spot4.png"
        poster5="spot5.png"
        poster6="spot6.png"
        poster7="spot7.png"
        poster8="spot8.png"
        poster9="spot9.png"
        poster10="spot10.png"
        poster11="spot11.png"



        button=""
        active="first"

      />
      <Component
        title="COMPETITIONS"
        videoSrc="/car.mp4"
        poster1="literary_arts.png"
        poster2="non_comp.png"
        poster3="performing_arts.png"
        poster4="creative_arts.png"
        poster5=""
        poster6=""
        poster7=""
        poster8=""
        active="first"
        link="/events"
        navigation={true}

        button="Register Now"
      />
      <Component
        title="WORKSHOPS"
        videoSrc="/solodj.mp4"
        poster1="workshop6.png"
        poster2="workshop2.png"
        poster3="workshop3.png"
        poster4="workshop4.png"
        poster5="workshop5.png"
        poster6="BEATBOXING.png"
        poster7="karagattam.png"
        poster8="oyilattam.png"
        poster9="parai attam.png"
        poster11="workshop1.png"
        poster10="shiva vathiyam.png"
        poster12="silambam.png"
        link="/workshops"
        navigation={true}
        active="first"

        button="Register Now"
      />
      <Component
        title="HIPHOP FEST"
        videoSrc="/comp.mp4"
        poster1="shivaay.png"
        poster2="damm.png"
        poster3="divya.png"
        poster4="soora.png"
        poster5="rhythm and ride.png"
        poster6="skateboard.png"
        poster7="hiphopdance.png"
        // poster8="beatbox.png"
        active="first"

        button=""
      />
      {/* <Component
        title="U 25"
        videoSrc=""
        poster1="Artboard 2.png"
        poster2="Artboard 4.png"
        poster3="Artboard 3.png"
        poster4="Artboard 5.png"
        poster5=""
        poster6=""
        poster7=""
        poster8=""
        active="first"
        link="https://shared.under25universe.com/ymQF"
        button="RSVP (open in mobile)"
      /> */}


    </ParallaxProvider>
  );
};

export default Proshows;