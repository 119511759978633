
export const faqDetails=[
  {
    sectionId: "section1",
    sectionName: "General",
    faqs: [
      {
        "id": 1,
        "Question": "When is Saarang 2025 scheduled?",
        "Answer": "Saarang 2025 is to be held from 9th January 2025 to 13th January 2025, with the ProShows starting at 17:30 hours each day."
      },
      {
        "id": 2,
        "Question": "How can I make payments at Saarang?",
        "Answer": "No cash payments are acceptable. Payments are to be made through POS or UPI."
      },
      {
        "id": 3,
        "Question": "Are third-party payment platforms allowed?",
        "Answer": "The organizers will not be responsible for payments made through unauthorized third-party platforms."
      },
      {
        "id": 4,
        "Question": "Are taxes included in ticket prices?",
        "Answer": "All prices are inclusive of applicable taxes unless otherwise mentioned."
      },
      {
        "id": 5,
        "Question": "When should I arrive at the venue?",
        "Answer": "It is recommended to arrive at least 30 minutes prior to the venue for seamless entry."
      },
      {
        "id": 6,
        "Question": "What rules should I follow at the back of the ticket?",
        "Answer": "Kindly go through all the instructions given at the back of the ticket."
      },
      {
        "id": 7,
        "Question": "What happens if I enter a prohibited area?",
        "Answer": "Any attempt to enter a prohibited area in the venue will lead to eviction."
      },
      {
        "id": 8,
        "Question": "Are children and senior citizens encouraged to attend?",
        "Answer": "Little children, senior citizens, and cardiac patients are not encouraged to attend the shows due to high volume levels."
      },
      {
        "id": 9,
        "Question": "What items are prohibited inside the venue?",
        "Answer": "The following items are strictly not allowed into the venue: Photographic equipment (except mobile phones), bags, helmets, cosmetics, inflammable objects, arms, cigarettes, power banks, selfie-sticks, headphones, pens, chains, rings, combs, etc., any kind of food, including water bottles."
      },
      {
        "id": 10,
        "Question": "Is smoking allowed on campus?",
        "Answer": "Smoking inside the campus is prohibited."
      },
      {
        "id": 11,
        "Question": "Are alcoholic materials and narcotics allowed?",
        "Answer": "No, alcoholic material, narcotics, and medicines are not allowed inside the OAT."
      },
      {
        "id": 12,
        "Question": "Will water and food be available at the venue?",
        "Answer": "Drinking water will be provided free of charge, and food & beverage stalls will be available inside the venue."
      },
      {
        "id": 13,
        "Question": "What happens in case of misbehavior at the venue?",
        "Answer": "Any kind of misbehavior or disturbance will result in strict disciplinary action, including fines and expulsion from the venue premises."
      },
      {
        "id": 14,
        "Question": "What precautions are in place for security?",
        "Answer": "Frisking is done for security reasons. Kindly cooperate."
      },
      {
        "id": 15,
        "Question": "Who is responsible for personal property loss or theft?",
        "Answer": "The organization holds no responsibility for the loss or theft of any personal property."
      },
      {
        "id": 16,
        "Question": "Should I bring valuables to the venue?",
        "Answer": "It is advised to refrain from bringing valuables such as jewelry or laptops to the venue."
      },
      {
        "id": 17,
        "Question": "Can the terms and conditions change?",
        "Answer": "These terms and conditions are subject to change from time to time at the discretion of the organization."
      }
    ],
  },
  {
    sectionId: "section2",
    sectionName: "Accommodation",
    faqs: [
      {
        id: 1,
        Question: "What type of accommodations does IIT Madras offer?",
        Answer:
          "Options include shared and single rooms, depending on the availability within our campus hostels.",
      },
      {
        id: 2,
        Question: "How can I submit an accommodation request?",
        Answer:
          "You can apply via our Saarang publicity portal or our sales portal, which offers a combination of accommodation and proshow tickets.",
      },
      {
        id: 3,
        Question: "Is there a cost associated with accommodation?",
        Answer:
          "Yes, there is a fee associated with the accommodations. The portal will provide the pricing details.",
      },
      {
        id: 4,
        Question: "A security deposit: what is it?",
        Answer:
          " A 400/-security deposit is required before you can enter your assigned room; it will be refunded if everything is kept in good condition and will be subtracted if you cause any damage while you are staying at the hostel.",
      },
      {
        id: 5,
        Question: "What does the accommodation package include?",
        Answer:
          " A mattress, a kit of necessities (soap, toothbrush, etc.), and a free breakfast are all included in the package.",
      },
      {
        id: 6,
        Question: "What is the procedure for checking in?",
        Answer:
          " A week before your arrival on campus, check-in information will be sent to you.",
      },
      {
        id: 7,
        Question: "What should I do if I face issues in my accommodation?",
        Answer:
          " Please contact the designated hostel management or security if you are having any problems with your accommodations. If the problem is still not resolved, you may also contact Student Relations Saarang.",
      },
      {
        id: 8,
        Question: "How are roommates assigned?",
        Answer:
          " We will arrange for you to stay alongside your friends if you bought the accommodation in a group; if not, we will assign on a first-come, first-served basis.",
      },
      {
        id: 9,
        Question: "Are meals provided?",
        Answer:
          " Yes, complimentary breakfast is included with your accommodation.",
      },
      {
        id: 10,
        Question: "Can I cancel my accommodation request?",
        Answer:
          " Only 70% of the money paid will be returned if you cancel your accommodation request before January 5th; after that date, we won't be taking any more requests.",
      },
      {
        id: 11,
        Question: "In the case of a medical emergency, what should I do?",
        Answer:
          " You can use our campus medical facilities in case of an emergency, and we've included the contacts in our accommodation handbook.",
      },
      {
        "id": 12,
        "Question": "How to Reach IIT Madras",
        "Answer": "By Plane: The nearest airport is Chennai International Airport, located approximately 12 km from IIT Madras. You can take a cab or use ride-hailing services to reach the campus in about 30 minutes.",
        "Answer1": "By Train: The nearest railway station is Chennai Central Railway Station, located about 13 km from IIT Madras. Taxis and auto-rickshaws are readily available, or you can use the metro to reach the campus in around 30-40 minutes.",
        "Answer2": "By Metro: The nearest metro station is Little Mount Metro Station, located 4 km from IIT Madras. From there, you can take an auto or cab to reach the campus in about 10-15 minutes.",
        "Answer3": "By Bus: Several city buses operate to IIT Madras from various parts of Chennai. The campus is well-connected and easily accessible from all major bus stops in the city."
      },
    ],
  },
  {
    "sectionId": "section3",
    "sectionName": "ProShows Tickets",
    "faqs": [
      {
        "id": 1,
        Question: "How can I purchase tickets for Saarang ProShows?",
        Answer: "Visit the official Saarang sales portal, navigate to the ProShows section, select your desired show, and follow the instructions to complete the booking process."
      },
      {
        "id": 2,
        Question: "What payment methods are accepted on the sales portal?",
        Answer: "The sales portal accepts UPI, debit/credit cards, net banking, and popular payment wallets."
      },
      {
        "id": 3,
        Question: "What should I do if I face issues during payment?",
        Answer: "If you encounter payment issues, refresh the page and try again. If the issue persists, contact Saarang's support team, through this email: devops@saarang.org"
      },
      {
        "id": 4,
        Question: "Are Saarang ProShows tickets refundable or transferable?",
        Answer: "Tickets are non-refundable. Transfers are only allowed if explicitly mentioned during the purchase process. Please check the ticketing terms for details."
      },
      {
        "id": 5,
        Question: "How will I receive my tickets after purchase?",
        Answer: "Your e-tickets will be sent to your registered email ID. You can also view them directly from the sales portal under the 'Orders' section."
      },
      {
        "id": 6,
        Question: "Can I purchase tickets for multiple ProShows at once?",
        Answer: "Yes, you can add tickets for multiple ProShows to your cart and complete the payment in one transaction."
      },
      {
        "id": 7,
        Question: "Do I need a physical copy of the ticket to enter the venue?",
        Answer: "A physical copy is not mandatory. You can show the e-ticket on your mobile device, along with a valid ID, at the venue."
      },
      {
        "id": 8,
        Question: "Is there any early bird discount for ProShows tickets?",
        Answer: "Yes, early bird discounts are often available. Check the sales portal or Saarang’s social media pages for the latest updates and offers."
      },
      {
        "id": 9,
        Question: "What should I do if I don’t receive my e-ticket after payment?",
        Answer: "Check your spam/junk email folder. If you still don’t find it, contact the support team immediately with your transaction ID."
      },
      {
        "id": 10,
        Question: "Is there a limit to how many tickets I can purchase?",
        Answer: "Yes, there is usually a limit to prevent bulk booking. The limit will be mentioned during the purchase process for each event."
      },
      {
        "id": 11,
        "Question": "How will I receive my entry pass for the ProShows?",
        "Answer": "A unique QR code for entry will be shared via email before the show for each night."
      },
      {
        "id": 12,
        "Question": "Is the QR code mandatory for entry?",
        "Answer": "Yes, the QR code will be necessary for event entry."
      },
      {
        "id": 13,
        "Question": "How should I carry my QR code to the venue?",
        "Answer": "Ensure you bring the QR code in either printed or digital format to prevent delays."
      },
      {
        "id": 14,
        "Question": "Are ProShow tickets refundable or transferable?",
        "Answer": "No, the tickets are non-transferable and nonrefundable."
      },
      {
        "id": 15,
        "Question": "Can I request a refund for my ticket?",
        "Answer": "Requests for refunds will not be entertained at any time or in any circumstances."
      },
      {
        "id": 16,
        "Question": "What happens if I resell my ticket?",
        "Answer": "Unlawful resale or attempted resale of a ticket will lead to seizure or cancellation without refund or other compensation."
      },
      {
        "id": 17,
        "Question": "Can I re-enter the venue once I leave?",
        "Answer": "Re-entry into the venue of ticketed shows is strictly prohibited, except in cases of medical emergencies."
      },
      {
        "id": 18,
        "Question": "What should I carry with my ticket?",
        "Answer": "Patrons must always carry a valid photo identity proof and provide it if asked; failing this, entry may be denied."
      },
      {
        "id": 19,
        "Question": "How will Bowl and Fan Pass patrons be identified?",
        "Answer": "They will be provided with colored wristbands."
      },
      {
        "id": 20,
        "Question": "What happens if I lose or tamper with my wristband?",
        "Answer": "No replacement will be provided in case of loss or tampering of the wristband."
      },
      {
        "id": 21,
        "Question": "Can I enter the venue without a wristband?",
        "Answer": "Organizers reserve the right to evict patrons found without appropriate wristbands."
      },
      {
        "id": 22,
        "Question": "What makes a ticket inadmissible?",
        "Answer": "Tickets with tampered holograms or those void of a hologram, official stamp, or signature will be considered inadmissible."
      }
    ]
  },  
  {
    "sectionId": "section4",
    "sectionName": "Merchandise Orders",
    "faqs": [
      {
        "id": 1,
        Question: "Are there any additional charges for deliveries outside IITM?",
        Answer: "Yes, there is a delivery charge of ₹50 for deliveries outside IITM. Delivery within IITM is free of charge."
      },
      {
        "id": 2,
        Question: "How long will it take for my order to be delivered?",
        Answer: "Orders within IITM are typically delivered within 3-5 business days. Deliveries outside IITM may take longer depending on the location and shipping method."
      },
      {
        "id": 3,
        Question: "Can I change my delivery address after placing the order?",
        Answer: "Unfortunately, once the order is placed, we cannot change the delivery address. Please ensure the address is correct before completing the checkout process."
      },
      {
        "id": 4,
        Question: "What happens if I’m not available to receive the delivery?",
        Answer: "If you're unavailable to receive the delivery, the courier may attempt re-delivery or leave a notice with instructions for rescheduling."
      }
    ]
  },  
  {
    "sectionId": "section5",
    "sectionName": "Event Registrations",
    "faqs": [
      {
        "id": 1,
        "Question": "I have bought a club/combo pass, am I automatically registered for all events in that club?",
        "Answer": "No, you have to click register on the particular event you want to take part in. You'll be able to register for free based on the club/combo pass you have bought."
      },
      {
        "id": 2,
        "Question": "Can one person register on behalf of the entire team for group events?",
        "Answer": "No, every member of the team participating in a group event must register individually and purchase their own passes for the event or club to take part."
      },
      {
        "id": 3,
        "Question": "Is there a limit to the number of teams or individuals that can participate?",
        "Answer": "There is no limit to the number of teams or individuals that can participate unless otherwise specified."
      },
      {
        "id": 4,
        "Question": "What details are required during the payment process?",
        "Answer": "Ensure that all details entered during the payment process are accurate."
      },
      {
        "id": 5,
        "Question": "What happens if I enter incorrect payment details?",
        "Answer": "Incorrect entries may result in failed or delayed transactions, and the organizers will not be responsible for any losses due to such errors."
      }
    ]
  },
  {
    "sectionId": "section6",
    "sectionName": "Ambassadors",
    "faqs": [
      {
        "id": 1,
        "Question": "What is the SA program / who is a Student Ambassador?",
        "Answer": "The Student Ambassador represents Saarang, IIT Madras' cultural fest, on their campus and helps publicize events and workshops. This role is akin to a digital marketing internship."
      },
      {
        "id": 2,
        "Question": "How / where should I apply?",
        "Answer": "You can apply for the program using the sign-up button on this page. During your sign-up, answer all the questions asked. Remember the login credentials you provide during sign-up, as you cannot change the email ID."
      },
      {
        "id": 3,
        "Question": "How are the top SAs decided?",
        "Answer": "The top SAs will be determined based on a points system designed to fairly evaluate the performance of SAs."
      },
      {
        "id": 4,
        "Question": "What is the eligibility/criteria to become a SA?",
        "Answer": "If you are a student pursuing either B.Tech or B.E. and have a valid college ID, you can apply for this program."
      },
      {
        "id": 5,
        "Question": "How much time or effort on my part would be required?",
        "Answer": "A maximum of 2 hours per week would be required from a Student Ambassador."
      },
      {
        "id": 6,
        "Question": "What is Milans?",
        "Answer": "Milans is a mini Saarang event conducted for publicity. SAs are responsible for coordinating and conducting Milans in their respective colleges."
      },
      {
        "id": 7,
        "Question": "What benefits do I get as a Student Ambassador?",
        "Answer": "As a Student Ambassador, you will earn coupons and vouchers upon completing tasks, receive premium Saarang merchandise and goodies, have a chance to secure internship certificates, and participate in Saarang. You will also develop skills in team management, event organization, public speaking, social media, content writing and promotion, and other technical skills."
      },
      {
        "id": 8,
        "Question": "Can I participate in the program if I am from a non-IIT Madras institution?",
        "Answer": "Yes, the Student Ambassador program is open to students from colleges across India."
      },
      {
        "id": 9,
        "Question": "What should I do if I face challenges in completing a task?",
        "Answer": "If you encounter any challenges while completing a task, report them immediately to the Saarang team through the designated communication channels. Describe the issue in detail so that we can provide the necessary support."
      }
    ]
  }, 
    
];
