import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Check_RegDocument } from "../../generated/graphql";
import { useAuth } from "../../contexts/AuthContext";
import toast from "react-hot-toast";
import { useGetMe } from "../../hooks/useGetMe";

const VerifyEmail: React.FC = () => {
  const { id1, id2 } = useParams<{ id1: string; id2: string }>();
  const [checkReg, { loading, error }] = useMutation(Check_RegDocument);
  const { updateUserData, user } = useAuth();
  const navigate = useNavigate();
  const { refetch: refetchUser } = useGetMe();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const result = await checkReg({
          variables: {
            emailBase64: id1,
            loginHash: id2,
          },
        });

        if (result.data?.check_reg) {
          const updatedUserData = {
            ...user,
            ...result.data.check_reg,
            verified: true
          };
          updateUserData(updatedUserData);
          
          await refetchUser();
          
          toast.success("Email verified successfully!");
          navigate("/");
        }
      } catch (error: any) {
        console.log(error);
        toast.error(error.message || "Verification failed. Please try again.");
      }
    };

    verifyEmail();
  }, [id1, id2, checkReg, updateUserData, navigate, refetchUser, user]);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center bg-[#c1204b]">
        <div className="bg-[#febd02] p-8 rounded-3xl shadow-lg">
          <p className="text-white text-xl">Verifying your email...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-screen flex items-center justify-center bg-[#c1204b]">
        <div className="bg-[#febd02] p-8 rounded-3xl shadow-lg">
          <p className="text-red-600">Verification failed. Please try again.</p>
        </div>
      </div>
    );
  }

  return null;
};

export default VerifyEmail;
