import React, { useState } from 'react';
import { FaHome, FaInfoCircle, FaTicketAlt, FaUser } from 'react-icons/fa';
import Proshows from '../../components/Home/Proshow/proshows';
import Event from '../../components/Home/event';
import Spotlight from '../../components/Home/spotlight';
import About from '../../components/Home/about';
import { FloatingDock } from '../../components/ui/floating-dock';
import Games from '../../components/Home/games';
import Theme from '../../components/Home/theme';
// import Hero from '../../components/Home/Hero/Hero';
import Load from '../../components/Home/Loading/loading';
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/Home/Hero/Hero';
import Footer from '../../components/footer/footer';
import Hnav from '../../components/Home/HomeNavbar/Hnav';

const Home: React.FC = () => {
  const [loadingComplete, setLoadingComplete] = useState(true);

  return (
    <div className='overflow-hidden'>
      {/* {!loadingComplete && <Load onFinish={() => setLoadingComplete(true)} />}
      {loadingComplete && ( */}
        <main className='overflow-hidden'>
        {/* <Hnav/> */}
          <Navbar/>
       
          <Hero />
          <Theme />
          <About />
          <Proshows />
          <Footer/>
        </main>
      
    </div>
  );
};

export default Home;
