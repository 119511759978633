import React from "react";
import { MaskContainer } from "../ui/maskcontainer";
import { AnimatePresence, motion } from "framer-motion";
import { Timeline } from "../ui/timeline";

const images: string[] = ["image_1.jpg", "image_2.jpg", "image_3.jpg"];
const data = [
  {
    title: "Theme",
    content: (
      <div>
        <p className="text-white text-lg md:text-xl font-medium font-montserrat mb-8">
        Frames & Fables celebrates the timeless legacy of Chennai's cinematic heritage, offering a tribute to its vibrant film culture while resonating with the city's deep-rooted connection to cinema
        </p>
      </div>
    ),
  },
  {
    title: "Get Tickets",
    content: (
      <div>
        <div className="flex  justify-start items-center space-x-2">
              {images.map((item, index) => (
                <img
                  key={index}
                  src={item}
                  alt={`Image ${index + 1}`}
                  className="w-24 h-36 object-cover "
                />
              ))}
            </div>
      </div>
    ),
  },
  {
    title: "9TH → 13TH JANUARY",
    content: (
      <div>
        <div className="relative bg-[#ece4d1]  rounded-l-[26px]">
          <div className="p-6 flex flex-col">
          <div className="relative w-32 h-32 rounded-3xl overflow-hidden">
      <img
        src="madras.jpg"
        alt="madras"
        className="absolute w-full h-full object-cover rounded-3xl z-0"
      />
    </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="300"
              height="30"
              viewBox="0 0 500 50"
            >
              <rect width="300" height="50" fill="#efe3d6" />
              <rect
                x="10"
                y="15"
                width="160"
                height="20"
                rx="10"
                ry="10"
                fill="none"
                stroke="#cc0000"
                stroke-width="2"
              />
              <rect
                x="30"
                y="20"
                width="120"
                height="10"
                rx="5"
                ry="5"
                fill="#ff9900"
              />
              <circle
                cx="20"
                cy="25"
                r="5"
                fill="#ff9900"
                stroke="#cc0000"
                stroke-width="2"
              />
              <circle
                cx="160"
                cy="25"
                r="5"
                fill="#ff9900"
                stroke="#cc0000"
                stroke-width="2"
              />
              <circle
                cx="205"
                cy="25"
                r="15"
                fill="#ff9900"
                stroke="#cc0000"
                stroke-width="4"
              />
            </svg>
          </div>
      </div>
      <div className="absolute bottom-0 right-0">
            <img src="astronaut.png" alt="" className="w-48 h-auto" />
          </div>
      </div>
    ),
  },
]

const Theme: React.FC = () => {
  return (
    <div className="h-[100%] w-full relative">
      <div className="xl:flex hidden flex-col items-center justify-center h-[100vh]">
        <div className="flex w-full justify-between items-start bg-[#ea9901] ">
          <div className="flex font-anton text-9xl p-3 text-[#e7d5c6] ">
            FRAMES & <br /> FABLES.
          </div>

          <div className="flex flex-col">
            <div className="flex space-x-0">
              {images.map((item, index) => (
                <img
                  key={index}
                  src={item}
                  alt={`Image ${index + 1}`}
                  className="w-44 h-54 object-cover "
                />
              ))}
            </div>
            <div className="flex space-x-4 mt-2">
              {images.map((_, index) => (
                <div
                  key={index}
                  className=" flex items-center justify-center text-white text-xl"
                >
                  <img
                    key={index}
                    src="icon.png"
                    alt={`Image ${index + 1}`}
                    className="w-8 h-8 object-cover "
                  />
                  <span className="text-black font-montserrat font-bold">
                    GET TICKETS
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-black w-full flex justify-start h-[22rem] ">
          <MaskContainer
            revealText={
              <p className="w-1/2 text-white text-3xl font-bold p-4 leading-10 pt-8 font-montserrat">
                Frames & Fables celebrates the timeless legacy of Chennai's
                cinematic heritage, offering a tribute to its vibrant film
                culture while resonating with the city's deep-rooted connection
                to cinema.
              </p>
            }
            className="border rounded-md w-full h-[30rem] font-montserrat"
          >
            <span className="text-[#d78c00] "> Frames & Fables </span>celebrates
            the timeless legacy of{" "}
            <span className="text-[#d78c00] "> Chennai's</span> cinematic
            heritage, offering a tribute to its vibrant film culture while
            resonating with the city's deep-rooted connection to{" "}
            <span className="text-[#d78c00] "> cinema</span>
          </MaskContainer>
        </div>
        <div className="flex bg-[#ea9901] w-full h-full space-x-16  p-4  text-6xl text-[#e7d5c6]">
          <span className="flex items-center justify-center">
            <motion.span className="font-montserrat font-semibold"
            initial={{x:-100}}
            whileInView={{x:0}}
            transition={{duration:2 ,ease: "easeInOut",}}
            >9TH</motion.span>
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 176 18"
              strokeWidth="4"
              stroke="#e7d5c6"
              className="w-44 h-8"
              initial={{ x: 0 }}
              whileInView={{ x: 80 }} 
              transition={{
                duration: 1,
                repeat: 1,
                repeatType: "reverse",
                ease: "easeInOut",
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 12h140m0 0l-6-6m6 6l-6 6"
              />
            </motion.svg>
            <motion.span className="font-montserrat font-semibold "
            initial={{x:-100}}
            whileInView={{x:0}}
            transition={{duration:2,
              ease: "easeInOut"
            }}
            >13TH</motion.span>
          </span>
          <motion.span className="font-montserrat font-semibold flex justify-center items-center"
          initial={{x:-100}}
          whileInView={{x:0}}
          transition={{duration:2,
          ease: "easeInOut"
          }}>
            JANUARY
          </motion.span>
        </div>
      </div>
      <div className="absolute max-xl:hidden bg-[#ece4d1] bottom-16 rounded-l-[32px] right-32 ">
        <div className="relative ">
          <div className="p-6 flex flex-col">
          <motion.div className="relative w-56 h-56 rounded-3xl overflow-hidden">
      <AnimatePresence>
        <motion.div
          className="absolute inset-0 bg-white"
          initial={{ scaleX: 0 }}
          whileInView={{ scaleX: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 1.5,
            ease: "easeInOut",
            delay: 0.5,
          }}
          style={{
            transformOrigin: "center",
          }}
          key="white-overlay"
        />
      </AnimatePresence>
      <motion.img
        src="madras.jpg"
        alt="madras"
        className="absolute w-full h-full object-cover rounded-3xl z-10"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{
          duration: 1.5,
          delay: 1.8, 
        }}
      />

      <motion.img
        src="madras.jpg"
        alt="madras"
        className="absolute w-full h-full object-cover rounded-3xl z-0"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 1.5,
          delay: 2, 
        }}
      />
    </motion.div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="300"
              height="50"
              viewBox="0 0 300 50"
            >
              <rect width="300" height="50" fill="#efe3d6" />
              <rect
                x="10"
                y="15"
                width="160"
                height="20"
                rx="10"
                ry="10"
                fill="none"
                stroke="#cc0000"
                stroke-width="2"
              />
              <rect
                x="30"
                y="20"
                width="120"
                height="10"
                rx="5"
                ry="5"
                fill="#ff9900"
              />
              <circle
                cx="20"
                cy="25"
                r="5"
                fill="#ff9900"
                stroke="#cc0000"
                stroke-width="2"
              />
              <circle
                cx="160"
                cy="25"
                r="5"
                fill="#ff9900"
                stroke="#cc0000"
                stroke-width="2"
              />
              <circle
                cx="205"
                cy="25"
                r="15"
                fill="#ff9900"
                stroke="#cc0000"
                stroke-width="4"
              />
            </svg>
          </div>
          <div className="absolute bottom-0 -right-32">
            <img src="astronaut.png" alt="" className="w-72 h-auto" />
          </div>
        </div>
      </div>
      <div className="w-full xl:hidden">
      <Timeline data={data} />

      </div>
    </div>
  );
};

export default Theme;
