import React from "react";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
  return (
    <div className="bg-[#c1204b] flex flex-col items-center justify-center min-h-screen p-8">
      <h1 className="text-4xl font-bold mb-4">404: Page Not Found</h1>
      <p className="text-lg mb-6">Oops! The page you’re looking for doesn’t exist.</p>
      <Link 
        to="/" 
        className="text-yellow-600 underline hover:text-yellow-500 transition duration-300"
      >
        Go back to Main Page
      </Link>
    </div>
  );
};

export default NotFound;
