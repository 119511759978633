import { useQuery } from '@apollo/client';
import { GetMeDocument } from '../generated/graphql';
import { useAuth } from '../contexts/AuthContext';

export const useGetMe = () => {
    const { updateUserData } = useAuth();

    const { data, loading, error, refetch } = useQuery(GetMeDocument, {
        onCompleted: (data) => {
            if (data?.getMe) {
                console.log("ksbdhc")
                updateUserData(data.getMe);
            }
        

        },
    });

    return { data, loading, error, refetch };
}; 