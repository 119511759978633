import React, { useState } from "react";
import {
  useGetEscapeRoomsQuery,
  useCreateEscapeRoomMutation,
  useUpdateEscapeRoomMutation,
  useDeleteEscapeRoomMutation,
  useGetEscapeRoomRegistrationsQuery,
  useGetEscapeRoomByLocationQuery,
} from "../../generated/graphql";
import { Pencil, Trash2, Plus, Filter } from "lucide-react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface EscapeRoom {
  id: string;
  title: string;
  description: string[];
  image_url?: string | null | undefined;
  event_date: string;
  price: number;
  event_location: string;
  registrations_closed: boolean;
  slot: string;
}

interface FormData {
  title: string;
  description: string[];
  eventDate: string;
  price: number;
  eventLocation: string;
  slot: string;
  registrationsClosed: boolean;
}

const initialFormData: FormData = {
  title: "",
  description: [],
  eventDate: "",
  price: 0,
  eventLocation: "",
  slot: "",
  registrationsClosed: false,
};

const downloadCSV = (data: any[], filename: string) => {
  const headers = [
    "Username",
    "Email",
    "Mobile",
    "Event Title",
    "Event Date",
    "Slot",
    "Registration Date",
    "Order ID",
    "Status",
  ];

  const csvData = data.map((reg) => [
    reg.user?.username || "Unknown User",
    reg.user?.email_primary || "No Email",
    reg.user?.mobile_number_primary || "No Mobile",
    reg.escapeRoom?.title || "Unknown Event",
    new Date(reg.escapeRoom?.event_date).toLocaleDateString() || "No Date",
    reg.escapeRoom?.slot || "No Slot",
    new Date(reg.registration.reg_date).toLocaleDateString(),
    reg.registration.razorpay_order_id || "",
    reg.registration.verified ? "Verified" : "Pending",
  ]);

  const csvContent = [
    headers.join(","),
    ...csvData.map((row) => row.join(",")),
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const downloadSlotWiseCSV = (data: any[], slot: string, filename: string) => {
  const filteredData = data.filter((reg) => reg.escapeRoom.slot === slot);

  const headers = [
    "Username",
    "Email",
    "Mobile",
    "Event Title",
    "Slot",
    "Registration Date",
    "Order ID",
    "Status",
  ];

  const csvData = filteredData.map((reg) => [
    reg.user?.username || "Unknown User",
    reg.user?.email_primary || "No Email",
    reg.user?.mobile_number_primary || "No Mobile",
    reg.escapeRoom?.title || "Unknown Event",
    reg.escapeRoom?.slot || "No Slot",
    new Date(reg.registration.reg_date).toLocaleDateString(),
    reg.registration.razorpay_order_id || "",
    reg.registration.verified ? "Verified" : "Pending",
  ]);

  const csvContent = [
    headers.join(","),
    ...csvData.map((row) => row.join(",")),
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

enum EscapeRoomSlot {
  SLOT1 = "10:00-10:40",
  SLOT2 = "10:50-11:30",
  SLOT3 = "11:40-12:20",
  SLOT4 = "12:30-1:20",
  SLOT5 = "1:30-2:20",
  SLOT6 = "2:30-3:20",
  SLOT7 = "3:30-4:20",
}

const ESCAPE_ROOM_DATES = [
  new Date("2025-01-12"),
  new Date("2025-01-11"),
] as const;

const EscapeRoomAdmin: React.FC = () => {
  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [showRegistrations, setShowRegistrations] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedEscapeRoomId, setSelectedEscapeRoomId] = useState<
    string | null
  >(null);
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedDownloadSlot, setSelectedDownloadSlot] = useState<string>("");

  const { data: allEscapeRooms, loading: escapeRoomsLoading } =
    useGetEscapeRoomsQuery();
  const { data: locationEscapeRooms } = useGetEscapeRoomByLocationQuery({
    variables: { location: selectedLocation },
    skip: !selectedLocation,
  });
  const { data: registrations, refetch: refetchRegistrations } =
    useGetEscapeRoomRegistrationsQuery({
      fetchPolicy: "network-only",
    });

  const [createEscapeRoom] = useCreateEscapeRoomMutation();
  const [updateEscapeRoom] = useUpdateEscapeRoomMutation();
  const [deleteEscapeRoom] = useDeleteEscapeRoomMutation();

  const displayEscapeRooms = selectedLocation
    ? locationEscapeRooms?.getEscapeRoomByLocation || []
    : allEscapeRooms?.getEscapeRooms || [];

  const locationOptions = Array.from(
    new Set(
      allEscapeRooms?.getEscapeRooms?.map((room) => room.event_location) || []
    )
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const variables = {
      ...formData,
      price: parseFloat(formData.price.toString()),
      description: formData.description.filter((point) => point.trim() !== ""),
      eventDate: new Date(formData.eventDate).toISOString(),
    };

    try {
      if (selectedEscapeRoomId) {
        await updateEscapeRoom({
          variables: {
            ...variables,
            updateEscapeRoomId: selectedEscapeRoomId,
          },
        });
        toast.success("Escape Room updated successfully!");
      } else {
        await createEscapeRoom({
          variables,
        });
        toast.success("Escape Room created successfully!");
      }
      setIsDialogOpen(false);
      resetForm();
    } catch (error: any) {
      console.error("Error submitting escape room:", error);
      toast.error(
        error.message || "Failed to save escape room. Please try again."
      );
    }
  };

  const handleEdit = (escapeRoom: EscapeRoom) => {
    setSelectedEscapeRoomId(escapeRoom.id);
    setFormData({
      title: escapeRoom.title,
      description: escapeRoom.description,
      eventDate: new Date(escapeRoom.event_date).toISOString().split("T")[0],
      price: escapeRoom.price,
      eventLocation: escapeRoom.event_location,
      slot: escapeRoom.slot,
      registrationsClosed: escapeRoom.registrations_closed,
    });
    setIsDialogOpen(true);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this escape room?")) {
      try {
        await deleteEscapeRoom({
          variables: { deleteEscapeRoomId: id },
        });
        toast.success("Escape Room deleted successfully!");
      } catch (error: any) {
        console.error("Error deleting escape room:", error);
        toast.error(
          error.message || "Failed to delete escape room. Please try again."
        );
      }
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setSelectedEscapeRoomId(null);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      {/* Header Section */}
      <div className="p-8 border-b border-gray-800">
        <div className="max-w-[1920px] mx-auto flex justify-between items-center">
          <div className="flex items-center gap-4">
            <button
              onClick={() => navigate("/admin")}
              className="px-4 py-2 text-sm bg-gray-800 hover:bg-gray-700 rounded-lg transition-colors duration-200"
            >
              ← Back to Admin
            </button>
            <h1 className="text-4xl font-bold bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">
              Escape Room Management
            </h1>
          </div>
          <div className="flex gap-4">
            <button
              onClick={() => setIsDialogOpen(true)}
              className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-200"
            >
              <Plus className="mr-2 h-5 w-5" />
              Add Escape Room
            </button>
            <button
              onClick={() => setShowRegistrations(true)}
              className="inline-flex items-center px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-all duration-200"
            >
              <Filter className="mr-2 h-5 w-5" />
              View Registrations
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="p-8 max-w-[1920px] mx-auto">
        {/* Filters Section */}
        <div className="bg-gray-800 rounded-xl p-6 mb-8">
          <div className="flex items-center mb-4">
            <Filter className="h-5 w-5 text-blue-400 mr-2" />
            <h2 className="text-lg font-semibold">Filters</h2>
          </div>
          <select
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            className="w-full md:w-auto bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-gray-200"
          >
            <option value="">All Locations</option>
            {locationOptions.map((location) => (
              <option key={location} value={location}>
                {location}
              </option>
            ))}
          </select>
        </div>

        {/* Escape Rooms Grid */}
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {displayEscapeRooms.map((room) => (
            <div
              key={room.id}
              className="bg-gray-800 rounded-xl shadow-lg border border-gray-700"
            >
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <h3 className="text-lg font-semibold">{room.title}</h3>
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleEdit(room)}
                      className="p-2 text-gray-400 hover:text-blue-400 rounded-full hover:bg-blue-500/10"
                    >
                      <Pencil className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => handleDelete(room.id)}
                      className="p-2 text-gray-400 hover:text-red-400 rounded-full hover:bg-red-500/10"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
                <div className="space-y-2 text-sm text-gray-400">
                  <p>📍 {room.event_location}</p>
                  <p>⏰ {room.slot}</p>
                  <p>📅 {new Date(room.event_date).toLocaleDateString()}</p>
                  <p>💰 ₹{room.price}</p>
                  <p>
                    Status:{" "}
                    <span
                      className={
                        room.registrations_closed
                          ? "text-red-400"
                          : "text-green-400"
                      }
                    >
                      {room.registrations_closed ? "Closed" : "Open"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Create/Edit Dialog */}
        {isDialogOpen && (
          <div className="fixed inset-0 bg-black/75 backdrop-blur-sm flex items-center justify-center p-4 z-50">
            <div className="bg-gray-800 rounded-xl max-w-md w-full max-h-[90vh] overflow-y-auto">
              <form onSubmit={handleSubmit} className="p-6">
                <h2 className="text-xl font-semibold mb-6">
                  {selectedEscapeRoomId ? "Edit" : "Create"} Escape Room
                </h2>
                <div className="space-y-4">
                  {/* Form fields */}
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Title
                    </label>
                    <input
                      type="text"
                      value={formData.title}
                      onChange={(e) =>
                        setFormData({ ...formData, title: e.target.value })
                      }
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2"
                      required
                    />
                  </div>

                  {/* Description points */}
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Description Points
                    </label>
                    {formData.description.map((point, index) => (
                      <div key={index} className="flex gap-2 mb-2">
                        <input
                          type="text"
                          value={point}
                          onChange={(e) => {
                            const newDesc = [...formData.description];
                            newDesc[index] = e.target.value;
                            setFormData({ ...formData, description: newDesc });
                          }}
                          className="flex-1 bg-gray-700 border border-gray-600 rounded-lg px-3 py-2"
                          placeholder={`Point ${index + 1}`}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            const newDesc = formData.description.filter(
                              (_, i) => i !== index
                            );
                            setFormData({ ...formData, description: newDesc });
                          }}
                          className="px-2 py-1 bg-red-600/20 text-red-400 rounded-lg"
                        >
                          ✕
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() =>
                        setFormData({
                          ...formData,
                          description: [...formData.description, ""],
                        })
                      }
                      className="w-full px-4 py-2 bg-gray-700 text-gray-300 rounded-lg hover:bg-gray-600"
                    >
                      Add Point
                    </button>
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Date
                    </label>
                    <select
                      value={formData.eventDate}
                      onChange={(e) =>
                        setFormData({ ...formData, eventDate: e.target.value })
                      }
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2"
                      required
                    >
                      <option value="">Select a date</option>
                      {ESCAPE_ROOM_DATES.map((date) => (
                        <option
                          key={date.toISOString()}
                          value={date.toISOString().split("T")[0]}
                        >
                          {date.toLocaleDateString()}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Price
                    </label>
                    <input
                      type="number"
                      value={formData.price}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          price: parseFloat(e.target.value),
                        })
                      }
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Location
                    </label>
                    <input
                      type="text"
                      value={formData.eventLocation}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          eventLocation: e.target.value,
                        })
                      }
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Slot
                    </label>
                    <select
                      value={formData.slot}
                      onChange={(e) =>
                        setFormData({ ...formData, slot: e.target.value })
                      }
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2"
                      required
                    >
                      <option value="">Select a slot</option>
                      {Object.entries(EscapeRoomSlot).map(([key, value]) => (
                        <option key={key} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.registrationsClosed}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          registrationsClosed: e.target.checked,
                        })
                      }
                      className="h-4 w-4 bg-gray-700 border-gray-600 rounded"
                    />
                    <label className="ml-2 text-sm">Registrations Closed</label>
                  </div>
                </div>

                <div className="mt-6 flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => setIsDialogOpen(false)}
                    className="px-4 py-2 text-gray-300 bg-gray-700 rounded-lg hover:bg-gray-600"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                  >
                    {selectedEscapeRoomId ? "Update" : "Create"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Registrations Modal */}
        {showRegistrations && (
          <div className="fixed inset-0 bg-black/75 backdrop-blur-sm flex items-center justify-center p-4 z-50">
            <div className="bg-gray-800 rounded-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
              <div className="p-6 border-b border-gray-700">
                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-semibold">Registrations</h2>
                  <button
                    onClick={() => setShowRegistrations(false)}
                    className="text-gray-400 hover:text-gray-200"
                  >
                    ✕
                  </button>
                </div>
              </div>

              <div className="p-6">
                {/* Download Controls Section */}
                <div className="bg-gray-700/50 rounded-lg p-4 mb-6">
                  <h3 className="text-lg font-medium mb-4">
                    Download Registrations
                  </h3>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                    {/* Date Filter */}
                    <div>
                      <label className="block text-sm text-gray-400 mb-1">
                        Date
                      </label>
                      <select
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-sm"
                      >
                        <option value="">All Dates</option>
                        {ESCAPE_ROOM_DATES.map((date) => (
                          <option
                            key={date.toISOString()}
                            value={date.toISOString().split("T")[0]}
                          >
                            {date.toLocaleDateString()}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Slot Filter */}
                    <div>
                      <label className="block text-sm text-gray-400 mb-1">
                        Slot
                      </label>
                      <select
                        value={selectedDownloadSlot}
                        onChange={(e) =>
                          setSelectedDownloadSlot(e.target.value)
                        }
                        className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-sm"
                      >
                        <option value="">All Slots</option>
                        {Object.values(EscapeRoomSlot).map((slot) => (
                          <option key={slot} value={slot}>
                            {slot}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Download Button */}
                    <div className="flex items-end">
                      <button
                        onClick={() => {
                          const data =
                            registrations?.getEscapeRoomRegistrations || [];
                          let filteredData = data;

                          // Apply date filter
                          if (selectedDate) {
                            filteredData = filteredData.filter(
                              (reg) =>
                                reg.escapeRoom.event_date.split("T")[0] ===
                                selectedDate
                            );
                          }

                          // Apply slot filter
                          if (selectedDownloadSlot) {
                            filteredData = filteredData.filter(
                              (reg) =>
                                reg.escapeRoom.slot === selectedDownloadSlot
                            );
                          }

                          // Generate filename based on filters
                          let filename = "escape_room_registrations";
                          if (selectedDate) {
                            filename += `_${selectedDate}`;
                          }
                          if (selectedDownloadSlot) {
                            filename += `_${selectedDownloadSlot.replace(
                              /[:-]/g,
                              ""
                            )}`;
                          }
                          filename += ".csv";

                          downloadCSV(filteredData, filename);
                        }}
                        className="w-full px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 flex items-center justify-center gap-2"
                      >
                        <svg
                          className="w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                          />
                        </svg>
                        Download Registrations
                      </button>
                    </div>
                  </div>

                  {/* Quick Filters */}
                  <div className="flex flex-wrap gap-2">
                    <button
                      onClick={() => {
                        setSelectedDate("");
                        setSelectedDownloadSlot("");
                      }}
                      className="px-3 py-1 text-sm bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-500"
                    >
                      Clear Filters
                    </button>
                    {ESCAPE_ROOM_DATES.map((date) => (
                      <button
                        key={date.toISOString()}
                        onClick={() =>
                          setSelectedDate(date.toISOString().split("T")[0])
                        }
                        className={`px-3 py-1 text-sm rounded-lg ${
                          selectedDate === date.toISOString().split("T")[0]
                            ? "bg-blue-600 text-white"
                            : "bg-gray-600 text-gray-200 hover:bg-gray-500"
                        }`}
                      >
                        {date.toLocaleDateString()}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Registrations Table */}
                <div className="overflow-x-auto">
                  <table className="w-full text-left">
                    <thead className="text-sm text-gray-400 uppercase bg-gray-700">
                      <tr>
                        <th className="px-4 py-3">User</th>
                        <th className="px-4 py-3">Event</th>
                        <th className="px-4 py-3">Date</th>
                        <th className="px-4 py-3">Slot</th>
                        <th className="px-4 py-3">Registration Date</th>
                        <th className="px-4 py-3">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {registrations?.getEscapeRoomRegistrations
                        ?.filter((reg) => {
                          let matchesDate = true;
                          let matchesSlot = true;

                          if (selectedDate) {
                            matchesDate =
                              reg.escapeRoom.event_date.split("T")[0] ===
                              selectedDate;
                          }
                          if (selectedDownloadSlot) {
                            matchesSlot =
                              reg.escapeRoom.slot === selectedDownloadSlot;
                          }

                          return matchesDate && matchesSlot;
                        })
                        .map((reg) => (
                          <tr
                            key={reg.registration.id}
                            className="border-b border-gray-700"
                          >
                            <td className="px-4 py-3">
                              <div>
                                <div>{reg.user.username}</div>
                                <div className="text-sm text-gray-400">
                                  {reg.user.email_primary}
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3">
                              {reg.escapeRoom.title}
                            </td>
                            <td className="px-4 py-3">
                              {new Date(
                                reg.escapeRoom.event_date
                              ).toLocaleDateString()}
                            </td>
                            <td className="px-4 py-3">{reg.escapeRoom.slot}</td>
                            <td className="px-4 py-3">
                              {new Date(
                                reg.registration.reg_date
                              ).toLocaleDateString()}
                            </td>
                            <td className="px-4 py-3">
                              <span
                                className={`px-2 py-1 rounded-full text-xs ${
                                  reg.registration.verified
                                    ? "bg-green-500/20 text-green-400"
                                    : "bg-yellow-500/20 text-yellow-400"
                                }`}
                              >
                                {reg.registration.verified
                                  ? "Verified"
                                  : "Pending"}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EscapeRoomAdmin;
