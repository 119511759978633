import React from 'react'
import {motion} from "framer-motion"
const About = () => {
  return (
    <div className='w-full h-[100vh] bg-cover bg-center relative'
    >
      <div className='absolute hidden md:block w-full h-[100vh] bg-cover bg-center'
      style={{backgroundImage:"url('/camera.png')"}}
      >
      </div>
      <div className='absolute block md:hidden w-full h-[100vh] bg-center bg-cover bg-no-repeat'
      style={{backgroundImage:"url('/camera6.png')"}}
      >
      </div>
      <div className=''>
        <div className='absolute top-0 right-0 flex flex-col items center justify-center pb-[10vh] md:pb-[0vh] text-[#b60002] lg:w-[38%] w-2/3 px-2 h-[100vh]'>
          <h2 className='font-roboto lg:text-left lg:text-6xl text-4xl font-bold py-5 whitespace-nowrap'>About Saarang</h2>
          <p className='font-montserrat font-semibold lg:text-xl text-sm '>Saarang, acclaimed as India's largest student-run festival and distinguished by its ISO certification, draws an impressive footfall of over 80,000 people. The festival is celebrated for hosting over 100 diverse events, including five professional shows that have featured musical luminaries such as Farhan Akhtar, Opeth, and Shankar Mahadevan. It showcases a wide array of genres, including Rock, Classical, EDM, and Pop. This year marks the 51st edition of Saarang, scheduled to be held offline from January 9th to 13th. The lineup includes renowned artists like Ritviz, Amit Trivedi, and Antariksh.</p>

        </div>
      </div>
      <div className='absolute top-12 -left-24'>
        <motion.img src="cloud.png" alt="" className='lg:w-56 w-32 h-auto'
        initial={{x:0}}
        whileInView={{x:100}}
        transition={{duration:2}}/>
      </div>
      <div className='absolute top-32 -left-20'>
        <motion.img src="cloud.png" alt="" className='lg:w-72 w-36 h-auto'
        initial={{x:0}}
        whileInView={{x:100}}
        transition={{duration:1}}/>
      </div>
     
      
    </div>
  )
}

export default About
