import { motion } from 'framer-motion';
import React from 'react'
// import Footer from "../components/footer/footer";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
// import { useGetMe } from "../hooks/useGetMe";
import { AnimatedTooltip } from "./animated-tooltip";
import { FaXTwitter } from "react-icons/fa6";
import './footer.css'





const people = [
  {
    id: 1,
    name: "Amjad Khan",
    designation: "Core",
    image:
      "amjath.jpg",
  },
  {
    id: 2,
    name: "Sagar",
    designation: "Core",
    image:
      "Sagar.jpg",
  },
  {
    id: 3,
    name: "Prakash Kumar Jha",
    designation: "Backend Developer",
    image:
      "prakash.jpg",
  
  },
  {
    id: 4,
    name: "Muhammed Kenz",
    designation: "Backend Developer",
    image:
      "kenz1.jpg",
  },
  {
    id: 5,
    name: "Gurukiran",
    designation: "Frontend Developer",
    image:
      "gurukiran.jpeg",
  },


  {
    id: 6,
    name: "Vijay",
    designation: "Frontend Developer",
    image:
      "vijay.jpg",
      
  },
  {
    id: 7,
    name: "Muhammed Naveed",
    designation: "Frontend  Developer",
    image:
      "naveed.jpg",
  },
 

  {
    id: 8,
    name: "Sreelatha",
    designation: "Frontend  Developer",
    image:
      "sreelatha.jpg",
  },
 
];










const footer = () => {
  return (
    <div className='bg-black w-[100vw] overflow-hidden h-[30vh] flex justify-center items-center ' >
 <div className="marquee-container w-[100vw] p-0 m-0 flex relative justify-center text-center align-center">
  <div className="marquee ">
    <span className='font-unlock mt-[vh]'>SAARANG'25</span>
  </div>
</div>


<div className='absolute w-[100vw] bg-[#f80000] md:bg-[#0000007d] h-[25vh] flex flex-row justify-between'>
  <div className='sss bg-[#f80000] px-4 rounded-br-2xl rounded-tr-2xl py-2 text-[#000000] mt-[0vh] font-unlock text-left '>
    FRAMES <br/>AND <br/>FABLES
  </div>

{/* <div className='pt-[5vh]'> 
  <p className='text-[#ffffff] mb-[2vh] font-unlock text-center '> Designed and Developed by Saarang DevOps 2025</p>
  <div className="flex flex-row items-center justify-center mb-10 w-full">
      <AnimatedTooltip items={people} />
    </div>
</div> */}


<div className='bg-[red] pl-[10vw] md:pl-[3vw] md:pr-[3vw] h-[25vh] px-[2vw] flex flex-col justify-center items-center text-center align-center rounded-bl-2xl rounded-tl-2xl'>
<div className='py-[3vh]  md:w-[20vw] w-[100%]'> 
  <p className='text-[#000000] mb-[1vh] font-unlock text-center '> Developed by Saarang DevOps </p>
  <div className="flex flex-row items-center justify-center w-full">
      <AnimatedTooltip items={people} />
    </div>
</div>
  <div className=' mt-[0vh] pb-[2vh] flex flex-row  text-3xl gap-[10px] justify-center text-center align-center rounded-bl-2xl rounded-tl-2xl '>
  <a href='https://www.facebook.com/saarangiitm/'><FaFacebook/></a>
 <a href='https://www.instagram.com/saarang_iitmadras/'><FaInstagram/></a>
 <a href='https://www.linkedin.com/company/saarang/posts/?feedView=all'><FaLinkedin/></a>
<a href='https://x.com/saarang_iitm'><FaXTwitter/> </a>
  </div>
</div>

</div>

    </div>
  )
}

export default footer



// "use client";
