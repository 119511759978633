import React from "react";
import "../../styles/oatrules.css";
import Navbar from "../../components/Navbar/Navbar";
const popcornImg = require("../../assets/popcorn.png");
function OatRules() {
  return (
    

     
      <div className="rules-page">
      <Navbar/>

      {/* <div className="oat-img1 ">
        <img className="popcorn-img-oat" alt="" src={String(popcornImg)} />
      </div>
      <div className="oat-img2">
        <img className="popcorn-img-oat" alt="" src={String(popcornImg)} />
      </div> */}
        <h1 className="oat-heading">OAT Rules and Regulations</h1>
        <ol className="oat-content">
          <li>
            Gates of entry
            <ul className="oat-qn">
              <li>
                Entry of IIT Faculty/Staff members and their families is through
                GARDEN gate.
                <ul className="oat-ans">
                  <li>
                    A separate queue will be maintained for the Faculty/Staff
                    members and their families.
                  </li>
                  <li>Kindly carry the Institute ID cards of members.</li>
                </ul>
              </li>
              <li>
                Entry of IIT students (Institute ID card holders) is through
                STUDENT gate. A separate queue will be maintained for IIT
                students. Students must also carry their Student ID Cards.
              </li>
              <li>
                Visitors are allowed through Family and Main Gate inside OAT.
              </li>
            </ul>
          </li>
          <li>Tickets are non-transferable.</li>
          <li>
            Re-entry into the venue of ticketed shows is strictly prohibited
            with exceptions in case of a medical emergency.
          </li>
          <li>
            Any attempt to enter into a prohibited area in the venue will lead
            to eviction from the venue. Also attempts by gallery patrons to
            enter into the bowl area will also attract eviction from the venue.
          </li>
          <li>
            Kindly go through all the instructions given at the back of the
            ticket.
          </li>
          <li>
            Patrons with tickets to Bowl and Fan pass will be provided with
            colored wristbands and no replacement will be provided in case of
            loss or tampering of the wristband. Even gallery, gallery plus.
          </li>
          <li>
            Patrons must always carry a valid photo identity proof and must be
            able to provide it if asked for, failing to do so may result in
            denial of entry in the venue.
          </li>
          <li>
            Organizers reserve the right to send the patrons out of OAT if found
            without appropriate wristbands.
          </li>
          <li>
            Right to admission is reserved by the Saarang organizing team.
          </li>
          <li>
            Tickets with tampered hologram and the tickets void of hologram or
            the official stamp and signature will be considered inadmissible.
          </li>
          <li>
            Requests for refund will not be entertained at any time or in any
            circumstances.
          </li>
          <li>
            No cash payments are acceptable. Payments are to be made through POS
            or UPI.
          </li>
          <li>
            Little children, senior citizens, cardiac patients are not
            encouraged to attend the shows as the volume level used may be
            uncomfortable for them .
          </li>
          <li> Items restricted inside OAT.</li>
          <ul className="oat-qn">
            <li>
              Any electrical items like a camera, power bank, headphones, wired
              earphones, and Bluetooth earphones are not allowed except for your
              phone.
            </li>
            <li>
              Any food items, including water bottles and cool drinks, are NOT
              allowed inside. Drinking water will be provided free of cost, and
              Food & Beverage stalls will be available inside the venue.
            </li>
            <li>
              Bags, Helmets, Cosmetics, Inflammable Objects, Arms and
              Ammunition, selfie sticks, and any sharp object intended for
              defensive or offensive use, such as pens, chains, rings, combs,
              etc. Key chains are optionally allowed, provided there are no
              sharp obtrusions. The organizers of Saarang hold the right to
              declare this.
            </li>
          </ul>
          <li>
            Any item, if viewed as a threat to the safety of the audience and
            artists, will not be allowed into the venue. The discretion of the
            organizing team will be final.
          </li>
          <li>
            Smoking inside the campus is prohibited. Cigarettes, alcoholic
            materials, narcotics, and medicines are not allowed inside the OAT.
            Anyone found under the influence of alcohol is strictly NOT allowed
            inside the OAT. The discretion of the organizing team will be final
            in deciding if the person is under the influence of alcohol.
          </li>
          <li>
            You can deposit any restricted item in the lockers near the Family
            gate at a nominal cost.
          </li>
          <li>
            Littering anywhere inside the campus will result in strict
            disciplinary action. Garbage bins will be placed in appropriate
            places, so please use them.
          </li>
          <li>
            Once the gates are closed for entry, no requests for entry inside
            OAT are entertained.
          </li>
          <li>
            Any kind of misbehavior or disturbance will result in strict
            disciplinary action including fines and expulsion from the venue's
            premises.
          </li>
          <li>Frisking is done for security reasons. Kindly cooperate.</li>
          <li>
            Security personnel reserve the right to refuse your entry (Right of
            admission reserved) or refuse your presence inside the OAT, at any
            point of time if they deem it unsafe.
          </li>
          <li>
            Organization helds no responsibility for the loss or theft of any
            personal property. The audience is advised to refrain from bringing
            valuables such as jewellery, laptops, etc. to the venue.
          </li>
          <li>
            Kindly cooperate with the Safety and Security team of Saarang'25,
            and enjoy the shows.
          </li>
        </ol>
        <h1 className="oat-heading">Events Rules and Regulations</h1>
        <ol className="oat-content">
          <li>
            When you buy any events/club pass, you agree to these terms and
            conditions.
          </li>
          <li>Events/club passes are non - refundable and non transferable.</li>
          <li>
            The decisions made by the Event Organizing head/ Club convenor would
            be final.
          </li>
          <li>
            The Saarang Events Organizing team reserves the right to cancel any
            event.
          </li>
          <li>
            If there are less than 4 participants in an event, the event might
            be canceled. The decision lies with the organizing team.
          </li>
          <li>
            In case any event is canceled, there would be no prize money given.
          </li>
        </ol>
      </div>
    
  );
}

export default OatRules;
