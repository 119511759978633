// ContactCard.tsx
import React from "react";
import { RiWhatsappLine } from "react-icons/ri";
import { IoMdMailOpen } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

interface ContactCardProps {
  role: string;
  name: string;
  phone: string;
  email: string;
  image: string;
  linkedin: string;
  instagram: string;
}

const ContactCard: React.FC<ContactCardProps> = ({
  role,
  name,
  phone,
  email,
  image,
  linkedin,
  instagram,
}) => {
  return (
    <div className="contact-card">
      <p className="contact-role">{role}</p>
      <img
        src={String(image)}
        alt={`${name}'s avatar`}
        className="contact-image"
      />
      <p className="contact-name">{name}</p>
      <p className="contact-details">
        {linkedin && (
          <a href={`${linkedin}`} target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="linkedin-logo contact-logo" />
          </a>
        )}
        {email && (
          <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
            <IoMdMailOpen className="mail-logo contact-logo" />
          </a>
        )}
        {/* {phone && (
          <a href={`https://wa.me/${phone}/`} target="_blank" rel="noopener noreferrer">
            <RiWhatsappLine className="whatsapp-logo contact-logo" />
          </a>
        )} */}

        {/* {instagram && (
          <a href={`${instagram}`} target="_blank" rel="noopener noreferrer">
            <FaInstagram className="instagram-logo contact-logo" />
          </a>
        )} */}

      </p>
    </div>
  );
};

export default ContactCard;
