const associateSponsers = [
  {
    name: "Latentview",
    type: "Quiz Club Title ",
    image: "/spons/LatentView White Logo.png",
    link: "https://www.latentview.com/",
  },
  {
    name: "Greenvironment",
    type: "Sustainability partner",
    image: "/spons/greenvironment.png",
    link: "https://greenvironmentindia.com/",
  },
  {
    name: "Cetaphil",
    type: "Skincare Partner",
    image: "/spons/Cetaphil.png",
    link: "https://www.cetaphil.in/",
  },
  {
    name: "Boost",
    type: "Stamina Partner ",
    image: "/spons/BOOST milkshake unit with BG (2).png",
    link: "https://www.instagram.com/boost.india?igsh=MW1vbWd2YXlrYzd6bA==",
  },
  {
    name: "CoERS",
    type: "Road safety Partner",
    image: "/spons/COERS_Logo (1).png",
    link: "https://ed.iitm.ac.in/~vb/rbg/CoERS.html",
  },
  {
    name: "shankar IAS",
    type: "UPSC Education sponsor",
    image: "/spons/SIA Logo.png",
    link: "https://www.shankariasacademy.com/",
  },
  {
    name: "HDFC ",
    type: "Banking Partner",
    image: "/spons/hdfc light background_page-0001.jpg",
    link: "https://www.hdfcbank.com/",
  },
  {
    name: "ICICI",
    type: "Banking Partner",
    image: "/spons/images (1).png",
    link: "https://www.icicibank.com/",
  },
  
  {
    name: "ESAF",
    type: "Banking Partner ",
    image: "/spons/ESAF Bank Logo Color_page-0002.jpg",
    link: "https://www.esafbank.com/",
  },
  {
    name: "Qoneqt",
    type: "Community Partner",
    image: "/spons/Qoneqt_Logo_Variation_02_02.png",
    link: "https://qoneqt.com/",
  },
  {
    name: "Canara",
    type: "Banking Partner",
    image: "/spons/Canara Logo.png",
    link: "https://canarabank.com/",
  },
  {
    name: "Khadi and Village Industries Commission",
    type: "Sustainiblity clothing patner ",
    image: "/spons/KVIC_logo.png",
    link: "https://www.kvic.gov.in/kvicres/index.php",
  },
  {
    name: "Office of Alumni and Corporate Relations",
    type: "Alumni Partner",
    image: "/spons/ACR_offfice logo.png",
    link: "https://acr.iitm.ac.in/",
  },
  {
    name: "Krafton",
    type: "Official Mobile Gaming Partner",
    image: "/spons/KRAFTON_IN_ESPORTS_For_Dark_BG.png",
    link: "https://krafton.in/",
  },
  {
    name: "Westin",
    type: "Hospitality sponsor",
    image: "/spons/WESTIN CHENNAI LOGO-01.png",
    link: "https://www.marriott.com/en-us/hotels/maavw-the-westin-chennai-velachery/overview/",
  },
  {
    name: "Atulya Healthcare",
    type: "Healthcare Partner",
    image: "/spons/Athulya Senior Care Logo 3_page-0001.jpg",
    link: "https://www.athulyahomecare.com/",
  },
  {
    name: "Almytees",
    type: "Tees Sponsor",
    image: "/spons/almytees.png R-01.png",
    link: "https://www.instagram.com/almytees/",
  },
  {
    name: "Blossom Book House",
    type: "Writing Partner",
    image: "/spons/booksblossoms.png",
    link: "https://blossombookhouse.in",
  },
  {
    name: "Board Game Lounge",
    type: "Play Partner",
    image: "/spons/Board Game Lounge Logo.png",
    link: "https://www.instagram.com/the.boardgame.lounge/?hl=en",
  },
  {
    name: "Bounce Salon",
    type: "Salon Care Partner",
    image: "/spons/bounce logo (2) (1)_page-0001.jpg",
    link: "https://www.bouncehere.com/",
  },
  
  {
    name: "C.Krishniah Chetty & Co",
    type: "Premium Jewellery Partner",
    image: "/spons/Blue CKC&Co logo_page-0001.jpg",
    link: "https://krishniahchetty.co/",
  },
  {
    name: "Chaos Entertainment",
    type: "Entertainment Partner",
    image: "/spons/chaos.jpeg",
    link: "https://www.chaosgaming.co.in/",
  },
  
  {
    name: "Coffee Bite",
    type: "Sweet Moments Sponsor ",
    image: "/spons/coffeebite.png",
    link: "",
  },
  {
    name: "Lotte Choco Pie",
    type: "Sweet Moments Sponsor ",
    image: "/spons/New Chocopie logo unit wb.png",
    link: "https://www.lotteindia.com/",
  },
  {
    name: "Colors Queen",
    type: "Glam Partner",
    image: "/spons/ColorsQueen Fashionclub.jpg",
    link: "https://colorsqueen.com/?srsltid=AfmBOorItN3OWTZV7JeApfnQ9pIAD1ncpC_5YsoI83qP4PD0z3j8RFgk",
  },
  
  {
    name: "Dab To Fab",
    type: "Moisture Partner",
    image: "/spons/dabtofablogo (2)_page-0001.jpg",
    link: "https://dabtofab.co/",
  },
  {
    name: "Don miller",
    type: "Premium Leather Sponsor ",
    image: "/spons/DM DON MILLER logo black.png",
    link: "https://www.donmiller.in/",
  },
  {
    name: "Dreamzone",
    type: "Design Partner",
    image: "/spons/DZ_Logo (1)_page-0001.jpg",
    link: "https://www.dreamzone.co.in/",
  },
  {
    name: "Enamel Echoes",
    type: "Banking Partner",
    image: "/spons/enamel drama+classical.jpg",
    link: "https://www.instagram.com/enamelechoes_/",
  },
  {
    name: "Ensens Perfumery",
    type: "Official Perfume Partner ",
    image: "/spons/ensens-horizontal.png",
    link: "https://ensensperfumery.in/",
  },
  
  {
    name: "Freeing India",
    type: "Escape Room Partner",
    image: "/spons/FREEING INDIA LOGO.jpg",
    link: "http://freeingindia.in/",
  },
  {
    name: "Gameistry",
    type: "Board Game Sponsor",
    image: "/spons/Mascot + Logo.png",
    link: "https://www.gameistry.in/",
  },
  
  {
    name: "Iris Art Lab",
    type: "Crochet Arts Partner",
    image: "/spons/Iris Art Lab.png",
    link: "https://www.irisartlab.com/",
  },
  {
    name: "Iris Glam",
    type: "Fashion and Pageant Partner",
    image: "/spons/irisglam.png",
    link: "http://irisglam.com/",
  },
  {
    name: "Ishwaryam Goodness",
    type: "Goodies Partner",
    image: "/spons/ishwaryamgoodness.png",
    link: "https://ishwaryamgoodness.com/",
  },
  {
    name: "Jio Saavn ",
    type: "Official Music Streaming Partner ",
    image: "/spons/Jio Saavn Logo.png",
    link: "https://www.jiosaavn.com/",
  },
  {
    name: "Koblerr",
    type: "Sytlish Footwear Partner",
    image: "/spons/koblerr.png",
    link: "https://koblerrshoes.com",
  },
  {
    name: "Lens Tiger",
    type: "Camera and Lens Partner",
    image: "/spons/logo final lens tiger.png",
    link: "https://www.lenstiger.com/",
  },
  {
    name: "Mommy Scrunchies",
    type: "none",
    image: "/spons/MSLOGO.jpg",
    link: "",
  },
  {
    name: "Nutribs",
    type: "Wellness Partner",
    image: "/spons/Nutribs logo_page-0001.jpg",
    link: "https://nutribs.com/",
  },
  {
    name: "Nuvue Store",
    type: "Light Painting Partner",
    image: "/spons/nuvuestore.png",
    link: "https://nuvuestore.com/",
  },
  {
    name: "ORE OFE",
    type: "Street Style Partner",
    image: "/spons/OREOFE_Vertical_Logo-01.jpg",
    link: "https://www.ore-ofe.com/",
  },
  {
    name: "Play Bae Cafe ",
    type: "Game Partner",
    image: "/spons/PlayBae Cafe Informas 2.png",
    link: "https://www.playbae.cafe/",
  },
  {
    name: "Purple Fire",
    type: "Brownie Partner",
    image: "/spons/purple fire informals.jpg",
    link: "https://www.purplefirecakes.com/",
  },

  {
    name: "Rebel",
    type: "Customized Jewels Partner",
    image: "/spons/Rebel logo.jpg",
    link: "",
  },
  {
    name: "Reynolds ",
    type: "Official Pen Partner ",
    image: "/spons/reynolds-logo-footer.png",
    link: "https://www.reynolds-pens.com/",
  },
  {
    name: "Sangamika Herbals",
    type: "Lip Balm Sponsor ",
    image: "/spons/Sangamika Herbals .png",
    link: "https://www.sangamikaherbals.in/home",
  },
  {
    name: "Sirona",
    type: "Hygiene Partner",
    image: "/spons/sirona .png",
    link: "https://www.thesirona.com/?srsltid=AfmBOoqxLpp7ustPNYaDtna0ISiWtG9wlyydJDH8xf8RrREGISGS4lF_",
  },
  {
    name: "Spykar",
    type: "Wardrobe partner",
    image: "/spons/spykar logo.jpg",
    link: "https://www.spykar.com/",
  },
  {
    name: "Srishti Publishers",
    type: "Publishing Partner",
    image: "/spons/sristi.jpg",
    link: "https://srishtipublishers.com",
  },
  {
    name: "Sruti Magazine",
    type: "",
    image: "/spons/sruti logo1.jpg",
    link: "https://www.sruti.com/",
  },
  {
    name: "StockEdge",
    type: "Financial Training Partner",
    image: "/spons/SE Logo_page-0001.jpg",
    link: "https://stockedge.com/",
  },
  
  
  {
    name: "The Dopamine Store",
    type: "Gifting Partner",
    image: "/spons/logo white with name (1).png",
    link: "https://www.instagram.com/dop.aminestore/",
  },
  {
    name: "Theatrekaran",
    type: "Regional Theatre Partner",
    image: "/spons/Logo-Yellow.png",
    link: "https://www.instagram.com/theatrekaran",
  },
  
  {
    name: "Untangle",
    type: "Puzzle Partner",
    image: "/spons/image.jpg",
    link: "https://linktr.ee/untangleindia",
  },
  {
    name: "Vanila",
    type: "Online jewels Sponsor",
    image: "/spons/2.png",
    link: "http://www.vanila.in/",
  },
  {
    name: "Zebronics",
    type: "Electronics Partner",
    image: "/spons/WhatsApp Image 2024-10-01 at 12.35.21.jpeg",
    link: "https://zebronics.com/",
  },
  {
    name: "FUJIFILM INSTAX",
    type: "Moments Partner",
    image: "/spons/instax x FUJIFILM Brand Color_logo with TM Mark_20 (1).png",
    link: "https://www.instax.in/collections/all-products?utm_source=google&utm_medium=cpc&utm_campaign=Traffic&utm_id=19890994253&gad_source=1&gclid=Cj0KCQiA4fi7BhC5ARIsAEV1YibdlhKckVdKFoIAL7KmILgiXafyllbWIPtyJIRFzPO3d57YXTVWqKYaAptpEALw_wcB",
  },
  {
    name: "Tune Clinical Aesthetics",
    type: "Clinical Aesthetics Partner",
    image: "/spons/WhatsApp Image 2025-01-09 at 1.39.30 AM.jpeg",
    link: "https://tuneaesthetics.com/",
  },
  {
    name: "Parisukkadai",
    type: "Handcrafted bags Partner",
    image: "/spons/WhatsApp Image 2025-01-09 at 1.39.30 AM (1).jpeg",
    link: "https://www.instagram.com/parisukkadai/",
  },
  {
    name: "Bubbles4U",
    type: "",
    image: "/spons/WhatsApp Image 2025-01-12 at 1.37.02 AM.jpeg",
    link: "https://amzn.in/d/7Fp4Ybi",
  },
  {
    name: "Ultratech Cement",
    type: "",
    image: "/spons/Ultratech Logo - English_page-0001.jpg",
    link: "https://www.ultratechcement.com/",
  },

  {
    name: "Brevistay",
    type: "",
    image: "/spons/Brevistay-Logomark-Purple.png",
    link: "https://brevistay.com/",
  },
  {
    name: "CM Eco Pen",
    type: "",
    image: "/spons/seedpen_page-0001.jpg",
    link: "https://www.cmseedpen.com/home",
  },

  {
    name: "Cool Vibe",
    type: "",
    image: "/spons/coolvibesfinal2 word games.png",
    link: "https://coolvibe.in/?srsltid=AfmBOorjiy7vYBwa0Wv2FuB8pLjhSBYDcmGlcXvM5f3IvAOGWdbYsUV-",
  },
  {
    name: "Fastrack",
    type: "Fragrance Partner",
    image: "",
    link: "https://www.fastrack.in/shop/fastrack-sale?utm_source=google&utm_medium=cpc&utm_campaign=MS_FasTrack_Google_Brand-search_AnalogPerformingkwds_Exact_Perf_20042023&utm_content=19146789532&gad_source=1&gclid=Cj0KCQiAj9m7BhD1ARIsANsIIvCGDG_E9akzzN67VaPz3hvVlLXD1xX5lbu1wDNHR77RM-ggaenzKfcaAh7bEALw_wcB",
  },
  {
    name: "indicrate",
    type: "",
    image: "/spons/IMG_1181-modified.png",
    link: "https://www.instagram.com/indi_crate/",
  },
  {
    name: "The Bombay Diaries",
    type: "Premium bags sponsor",
    image: "",
    link: "",
  },
  {
    name: "Toni & Guy",
    type: "",
    image: "",
    link: "https://toniandguyindia.com/",
  },
  {
    name: "Talerang",
    type: "Work-Readiness Sponsor",
    image: "",
    link: "",
  },

  // {
  //   name: "Beardo",
  //   type: "Grooming Partner",
  //   image: "/spons/Beardo Logo White.png",
  //   link: "https://beardo.in/?srsltid=AfmBOornnSd8Eqn9hVJugDD68J1Mxx84qckzx6Ot8Qu7EmX_LNdMlDw2",
  // },
];
const mediaSponsers = [
  {
    name: "anonymous_squad__",
    type: "Online Media Sponsor",
    image: "/spons/anonymoussquad.png",
    link: "https://www.instagram.com/anonymous_squad___?igsh=N3dlbTk0eXRwdm14",
  },
  {
    name: "c_h_e_n_n_a_i",
    type: "Online Media Sponsor",
    image: "/spons/c_h_e_n_n_a_i.jpg",
    link: "https://www.instagram.com/c__h__e__n__n__a__i/?igsh=MW81ZDZxMGxqcXVjcg%3D%3D#",
  },
  {
    name: "DU Beats",
    type: "Online Media Partner",
    image: "/spons/dubeat.png",
    link: "https://dubeat.com/",
  },
  {
    name: "All Events",
    type: "Online Media Sponsor",
    image: "/spons/ae-logo-portrait-white-vector.png",
    link: "https://allevents.in/chennai",
  },
  {
    name: "The Indian Music Diaries",
    type: "Online Media Sponsor",
    image: "/spons/timd.png",
    link: "https://theindianmusicdiaries.com",
  },
  {
    name: "Score Magazine",
    type: "Online Media Partner",
    image: "/spons/Score_LOGO_Vector_White Text.png",
    link: "https://highonscore.com/",
  },
  {
    name: "local_madras",
    type: "Online Media Partner",
    image: "/spons/LOCAL MADRAS.jpg",
    link: "https://www.instagram.com/local_madras?igsh=bnZnMXljNms0a2wx",
  },
  {
    name: "GrooveNexus",
    type: "Online Media Sponsor ",
    image: "/spons/groovenexus Logooo.jpg",
    link: "https://www.groovenexus.com/",
  },
  {
    name: "RagaToRock",
    type: "Online Media Partner",
    image: "/spons/R2R music_page.jpg",
    link: "https://raga2rock.com/",
  },

  {
    name: "chennai_journey",
    type: "Online Media Partner",
    image: "/spons/chennai-journey .jpg",
    link: "https://www.instagram.com/chennai_journey?igsh=MWczd3kzY3Q3b3o3MQ==",
  },
];
const workshopSponsers = [
  {
    name: "Techobytes ",
    image: "/spons/WhatsApp Image 2024-12-06 at 12.41.48.jpeg",
    link: "http://www.techobytes.com/",
    instagram: "http://instagram.com/_u/techobytes/",
    workshop: "https://techobytes.com/siitm/",
  },
  {
    name: "Entrench Electronics ",
    image: "/spons/logo (1) (1).png",
    link: "https://www.entrench.in/",
    instagram: "http://instagram.com/_u/entrenchelectronics/",
    workshop: "https://www.kartji.in/category/409220/workshops",
  },
  {
    name: "Techgyan",
    image: "/spons/Techgyan Logo.png",
    link: "",
    instagram: "https://www.instagram.com/techgyan.technologies/?hl=en",
    workshop: "https://tech-gyan.in/workshop-iit-madras-checkout/",
  },
  {
    name: "Clay beach studio",
    image: "/spons/CBS logo informals_page-0001.jpg",
    link: "https://www.claybeachstudio.com/",
    instagram: "",
    workshop: "",
  },
  {
    name: "IMS",
    type: "Workshop Partner ",
    image: "/spons/ims LOGO Saarang'25.jpeg",
    link: "https://www.imsindia.com/",
  },
];
const worldfestSponsers = [
  {
    name: "Italian consulate",
    image: "/spons/MAECI-consolato-generale-italia-V-EN-58.png",
  },
  {
    name: "Japanese consulate",
    image: "/spons/CG Logo.png",
  },
];

export {
  associateSponsers,
  mediaSponsers,
  workshopSponsers,
  worldfestSponsers,
};
