import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { loadRazorpay } from "../../utils/razorpay";
import { format } from "date-fns";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useCreateEscapeRoomRegistrationMutation } from "../../generated/graphql";
import { Calendar, MapPin, Tag, Clock } from "lucide-react";
import { useGetMe } from "../../hooks/useGetMe";
interface EscapeRoomRegistrationModalProps {
  isOpen: boolean;
  onClose: () => void;
  escapeRoom: {
    id: string;
    title: string;
    price: number;
    event_date: string;
    event_location: string;
    slot: string;
    registrations_closed: boolean;
  };
  selectedSlot: string;
}

export const EscapeRoomRegistrationModal = ({
  isOpen,
  onClose,
  escapeRoom,
  selectedSlot,
}: EscapeRoomRegistrationModalProps) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [createRegistration] = useCreateEscapeRoomRegistrationMutation();
  const navigate = useNavigate();
  const { refetch: refetchUser } = useGetMe();

  const isIITMStudent = user?.email_primary?.endsWith("smail.iitm.ac.in");

  const handleRegistration = async () => {
    if (!user) {
      toast.error("Please login to continue");
      window.location.href = "/auth";
      return;
    }
    await refetchUser();
    if (user.mobile_number_primary?.length === 0) {
      toast.error("Please complete your profile to continue");
      navigate("/profile/setup");
      return;
    }

    setLoading(true);
    try {
      console.log(user);
      if (!user.verified) {
        console.log("User not verified");
        toast.error("Please verify your email to continue");
        navigate("/sendVerificationEmail");
        return;
      }

      if (escapeRoom.registrations_closed) {
        toast.error("Registrations are closed for this escape room");
        return;
      }

      if (isIITMStudent) {
        const { data } = await createRegistration({
          variables: {
            userId: user.user_id,
            escapeRoomId: escapeRoom.id,
            slot: selectedSlot,
          },
        });
        toast.success("Registration successful!");
        onClose();
        return;
      }

      const Razorpay = await loadRazorpay();
      const { data } = await createRegistration({
        variables: {
          userId: user.user_id,
          escapeRoomId: escapeRoom.id,
          slot: selectedSlot,
        },
      });

      const registration = data?.createEscapeRoomRegistration;
      if (!registration?.paid) {
        toast.success("Registration successful using Club Pass!");
        onClose();
        return;
      }

      const options = {
        key:
          process.env.NEXT_PUBLIC_RAZORPAY_KEY_ID || "rzp_live_VgVO9uzPsxViN5",
        amount: escapeRoom.price * 100,
        currency: "INR",
        name: "Escape Room Registration",
        description: `${escapeRoom.title} - ${selectedSlot}`,
        order_id: registration?.razorpay_order_id,
        handler: function (response: any) {
          console.log("Payment success response:", response);
          toast.success(
            "Payment successful! You're registered for the escape room."
          );
          onClose();
        },
        prefill: {
          email: user.email_primary,
          contact: user.mobile_number_primary,
        },
        modal: {
          ondismiss: function () {
            console.log("user check -> ", user);
            console.log("Payment modal closed by user");
          },
        },
      };

      console.log("Initializing Razorpay with options:", {
        amount: options.amount,
        orderId: options.order_id,
      });
      const paymentObject = new (Razorpay as any)(options);
      console.log("Opening payment modal");
      paymentObject.open();
    } catch (error: any) {
      toast.error(error.message || "Failed to process registration");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/75 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-[#FDB951] p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-bold text-black font-roboto"
                >
                  Confirm Escape Room Registration
                </Dialog.Title>

                <div className="mt-6">
                  <div className="space-y-4">
                    <div>
                      <h4 className="text-xl font-semibold text-black font-roboto">
                        {escapeRoom.title}
                      </h4>
                      <p className="text-gray-700 flex items-center gap-2">
                        <MapPin className="w-5 h-5" />{" "}
                        {escapeRoom.event_location}
                      </p>
                    </div>

                    <div className="text-gray-700 space-y-2">
                      <p className="flex items-center gap-2">
                        <Calendar className="w-5 h-5" />
                        {format(new Date(escapeRoom.event_date), "PPP")}
                      </p>
                      <p className="flex items-center gap-2">
                        <Clock className="w-5 h-5" />
                        {selectedSlot}
                      </p>
                      <p className="flex items-center gap-2 font-medium text-black">
                        <Tag className="w-5 h-5" />
                        {isIITMStudent ? "Free" : `₹${escapeRoom.price}`}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-8 flex justify-end gap-3">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-lg px-4 py-2 text-sm font-medium bg-yellow-500 text-black hover:bg-yellow-600 transition-colors"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`inline-flex justify-center rounded-lg px-4 py-2 text-sm font-medium bg-yellow-600 text-black hover:bg-yellow-700 transition-colors ${
                      loading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={handleRegistration}
                    disabled={loading}
                  >
                    {loading ? "Processing..." : "Confirm Registration"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
