import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  useGetClubsQuery,
  useGetEventByClubQuery,
  useGetEscapeRoomsQuery,
  useGetAvailableSlotsByDateQuery,
} from "../../generated/graphql";
import { ClubCard } from "./ClubCard";
import { EventsList } from "./EventsList";
import { ClubPassCard } from "./ClubPassCard";
import { motion } from "framer-motion";
import { useAuth } from "../../contexts/AuthContext";
import { EscapeRoomRegistrationModal } from "../escapeRoom/EscapeRoomRegistrationModal";
import Navbar from "../Navbar/Navbar";
import { Dialog } from "@headlessui/react";
import { MapPin, Calendar, Clock, Tag } from "lucide-react";
import { format } from "date-fns";
import toast from "react-hot-toast";
export default function CategoryClubs() {
  const { category } = useParams();
  const [selectedClubId, setSelectedClubId] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedSlot, setSelectedSlot] = useState<string>("");
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [isEscapeRoomModalOpen, setIsEscapeRoomModalOpen] = useState(false);

  const { data, loading } = useGetClubsQuery();
  const { data: eventData } = useGetEventByClubQuery({
    variables: { clubId: selectedClubId || "" },
    skip: !selectedClubId,
  });

  const filteredClubs = data?.getClubs?.filter(
    (club: any) => club.category.toLowerCase() === category?.toLowerCase()
  );

  const selectedClub = filteredClubs?.find(
    (club: any) => club.id === selectedClubId
  );

  const isInformalsSelected = selectedClub?.clubName === "Informals Club";

  const { data: allEscapeRooms } = useGetEscapeRoomsQuery({
    skip: !isInformalsSelected,
  });

  const selectedRoom =
    selectedDate && selectedSlot
      ? allEscapeRooms?.getEscapeRooms?.find(
          (room) =>
            room.event_date === selectedDate && room.slot === selectedSlot
        )
      : null;

  const { data: availableSlots } = useGetAvailableSlotsByDateQuery({
    variables: {
      eventDate: selectedDate,
      escapeRoomId: selectedRoom?.id || "",
    },
    skip: !selectedDate || !isInformalsSelected,
  });

  const { user } = useAuth();
  const isIITMStudent = user?.email_primary?.endsWith("smail.iitm.ac.in");

  if (loading) return null;

  const dateOptions = Array.from(
    new Set(
      allEscapeRooms?.getEscapeRooms?.map((room) => room.event_date) || []
    )
  );

  const handleBooking = () => {
    console.log(user);
    if (!user) {
      toast.error("Please login to continue");
      window.location.href = "/auth";
      return;
    }
    setShowRegistrationModal(true);
  };

  return (
    <div
      className="min-h-screen pt-[10vh] relative overflow-hidden"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/Rectangle.png)`,
      }}
    >
      <Navbar />

      <img
        src="/cd.png"
        alt="Right Top Image"
        className="fixed top-[10vh] right-[-20%] w-[40vw] lg:block hidden animate-spin z-[0]"
        style={{ width: "350px" }}
      />

      <div>
        <h1 className="text-center md:text-7xl text-4xl relative z-10 font-bold font-unlock text-[#FFFB00] mb-8 pt-8 capitalize">
          {category} Clubs
        </h1>

        <div className="flex flex-col md:flex-row lg:flex-row lg:gap-20 md:gap-5 overflow-hidden md:overflow-hidden">
          {/* Clubs List - Left Side */}
          <div
            className="flex flex-row mt-0 lg:mt-[0vh] md:mt-30 md:flex-col lg:flex-col md:w-56 md:ml-12 ml-2 w-[98%] gap-8 
            overflow-y-auto scrollbar-hide sm:overflow-y-auto sm:scrollbar-hide lg:overflow-y-visible"
          >
            {filteredClubs?.map((club: any) => (
              <ClubCard
                key={club.id}
                {...club}
                onSelect={setSelectedClubId}
                selected={club.id === selectedClubId}
              />
            ))}
          </div>

          {/* Club Events - Right Side */}
          <div className="lg:w-3/4 rounded-xl p-6 pt-[0%] z-10 shadow-sm flex flex-col dark:border-gray-700">
            {selectedClub ? (
              <div>
                <div className="flex items-center gap-8 mb-6">
                  <h2 className="text-3xl md:text-6xl font-bold font-unlock text-white pt-9">
                    {selectedClub.clubName} Events
                  </h2>

                </div>
                {selectedClub.clubName==="Quiz Club"?<div className="flex items-center text-md md:text-lg font-unlock text-white"><div className="text">Presented by</div><img src='/spons/LatentView White Logo.png' className="w-[140px] md:w-[160px]"/></div>:""}


                {isInformalsSelected ? (
                  // Escape Room Section
                  <div className="space-y-8">
                    <div className="mb-8">
                      <ClubPassCard
                        id={selectedClub.id}
                        clubName={selectedClub.clubName}
                        clubPassPrice={selectedClub.clubPassPrice}
                        events={{ events: selectedClub.events || [] }}
                        logo={selectedClub.logo || ""}
                        category={selectedClub.category}
                        contactNumber={selectedClub.ContactNumber || ""}
                        contactName={selectedClub.ContactName || ""}
                      />
                    </div>

                    {/* Add Events List */}
                    <EventsList
                      selectedCategory={null}
                      selectedClubId={selectedClubId}
                      onClubSelect={setSelectedClubId}
                    />

                    <button
                      onClick={() => setIsEscapeRoomModalOpen(true)}
                      className="w-full bg-[#FDB951] text-black p-6 hover:bg-yellow-600 transition-colors border-2 border-black hover:border-black shadow-md"
                    >
                      <h3 className="text-2xl font-semibold font-unlock">
                        Book Escape Room
                      </h3>
                      <p className="text-gray-800 mt-2 text-center font-bold">
                        Step into a world of mystery and puzzles! Work against
                        the clock to solve mind-bending riddles, uncover hidden
                        clues, and escape before time runs out.
                      </p>
                      <p className="text-black mt-2 text-center font-bold">
                        Click to Register
                      </p>
                    </button>

                    <Dialog
                      open={isEscapeRoomModalOpen}
                      onClose={() => setIsEscapeRoomModalOpen(false)}
                      className="relative z-50"
                    >
                      <div
                        className="fixed inset-0 bg-black/70"
                        aria-hidden="true"
                      />

                      <div className="fixed inset-0 flex items-center justify-center p-4">
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden relative">
                          {/* Black Rectangle (Shadow) */}
                          <div
                            className="absolute inset-0 bg-black"
                            style={{ transform: "translate(8px, 8px)" }}
                          />

                          {/* Main Content Panel */}
                          <div className="relative z-10 bg-[#FDB951] p-6 text-left">
                            <Dialog.Title
                              as="h3"
                              className="text-2xl font-bold text-black font-roboto"
                            >
                              Book Escape Room
                            </Dialog.Title>

                            <div className="mt-6 space-y-6">
                              <div>
                                <h3 className="text-xl font-semibold text-black font-roboto mb-4">
                                  Select Date
                                </h3>
                                <select
                                  value={selectedDate}
                                  onChange={(e) => {
                                    setSelectedDate(e.target.value);
                                    setSelectedSlot("");
                                  }}
                                  className="w-full bg-yellow-50 border-2 border-black rounded-none px-3 py-2 text-gray-900 focus:outline-none"
                                >
                                  <option value="">Choose a date</option>
                                  {dateOptions.map((date) => (
                                    <option key={date} value={date}>
                                      {new Date(date).toLocaleDateString()}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {selectedDate && (
                                <div>
                                  <h3 className="text-xl font-semibold text-black font-roboto mb-4">
                                    Select Time Slot
                                  </h3>
                                  <select
                                    value={selectedSlot}
                                    onChange={(e) =>
                                      setSelectedSlot(e.target.value)
                                    }
                                    className="w-full bg-yellow-50 border-2 border-black rounded-none px-3 py-2 text-gray-900 focus:outline-none"
                                  >
                                    <option value="">Choose a slot</option>
                                    {availableSlots?.getAvailableSlotsByDate.map(
                                      (slot) => {
                                        // Find the escape room for this date and slot
                                        const roomForSlot =
                                          allEscapeRooms?.getEscapeRooms?.find(
                                            (room) =>
                                              room.event_date ===
                                                selectedDate &&
                                              room.slot === slot
                                          );

                                        // If the room is found and registrations are closed, disable the option
                                        const isRegistrationClosed =
                                          roomForSlot?.registrations_closed ||
                                          false;

                                        return (
                                          <option
                                            key={slot}
                                            value={slot}
                                            disabled={isRegistrationClosed}
                                            className={
                                              isRegistrationClosed
                                                ? "text-gray-400"
                                                : ""
                                            }
                                          >
                                            {slot}{" "}
                                            {isRegistrationClosed
                                              ? "(Closed)"
                                              : ""}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                              )}

                              {selectedRoom && selectedSlot && (
                                <motion.div
                                  initial={{ opacity: 0, y: 20 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  className="space-y-4 bg-yellow-50 border-2 border-black p-4"
                                >
                                  <h4 className="text-xl font-semibold text-black font-roboto">
                                    {selectedRoom.title}
                                  </h4>
                                  <div className="text-gray-700 space-y-2">
                                    <p className="flex items-center gap-2">
                                      <MapPin className="w-5 h-5" />{" "}
                                      {selectedRoom.event_location}
                                    </p>
                                    <p className="flex items-center gap-2">
                                      <Calendar className="w-5 h-5" />
                                      {format(
                                        new Date(selectedRoom.event_date),
                                        "PPP"
                                      )}
                                    </p>
                                    <p className="flex items-center gap-2">
                                      <Clock className="w-5 h-5" />
                                      {selectedSlot}
                                    </p>
                                    <p className="flex items-center gap-2 font-medium text-black">
                                      <Tag className="w-5 h-5" />
                                      {isIITMStudent
                                        ? "Free"
                                        : `₹${selectedRoom.price}`}
                                    </p>
                                  </div>
                                  <div className="relative">
                                    {/* Black Rectangle (Button Shadow) */}
                                    <div
                                      className="absolute inset-0 bg-black"
                                      style={{
                                        transform: "translate(4px, 4px)",
                                      }}
                                    />
                                    <button
                                      onClick={handleBooking}
                                      className="relative z-10 w-full bg-[#FDB951] text-black font-semibold py-3 border-2 border-black hover:translate-x-[4px] hover:translate-y-[4px] transition-transform"
                                    >
                                      Book Now
                                    </button>
                                  </div>
                                </motion.div>
                              )}
                            </div>

                            <button
                              onClick={() => setIsEscapeRoomModalOpen(false)}
                              className="absolute right-4 top-4 text-black hover:text-gray-700"
                            >
                              <svg
                                className="h-6 w-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                        </Dialog.Panel>
                      </div>
                    </Dialog>
                  </div>
                ) : (
                  // Regular Club Events
                  <>
                    <div className="mb-8">
                      <ClubPassCard
                        id={selectedClub.id}
                        clubName={selectedClub.clubName}
                        clubPassPrice={selectedClub.clubPassPrice}
                        events={{ events: selectedClub.events || [] }}
                        logo={selectedClub.logo || ""}
                        category={selectedClub.category}
                        contactNumber={selectedClub.ContactNumber || ""}
                        contactName={selectedClub.ContactName || ""}
                      />
                    </div>

                    <EventsList
                      selectedCategory={null}
                      selectedClubId={selectedClubId}
                      onClubSelect={setSelectedClubId}
                    />
                  </>
                )}
              </div>
            ) : (
              <div className="text-white font-unlock md:pt-0 pt-5">
                Select a club to view its events
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Escape Room Registration Modal */}
      {isInformalsSelected && selectedRoom && selectedSlot && (
        <EscapeRoomRegistrationModal
          isOpen={showRegistrationModal}
          onClose={() => setShowRegistrationModal(false)}
          escapeRoom={{
            ...selectedRoom,
            slot: selectedSlot,
          }}
          selectedSlot={selectedSlot}
        />
      )}
    </div>
  );
}
