// contactDetails.ts
export const contactDetails = [
  {
    sectionId: "section1",
    sectionName: "Cultural Secretaries",
    contacts: [
      {
        id: 1,
        role: "CulSec-Literary",
        name: "Akash",
        phone: "+919381975425",
        email: "sec_lit@smail.iitm.ac.in",
        image: require("../../assets/SaarangTeam/Screenshot_20241218-054507.Bumble~2 - Akash S.B ce21b005.png"),
        linkedin: "https://www.linkedin.com/in/akash-s-b-5903b8265",
        instagram: "https://www.instagram.com/saarang_iitmadras/?hl=en",
      },
      {
        id: 2,
        role: "CulSec-Arts",
        name: "Kesav",
        phone: "+919995314184",
        email: "sec_arts@smail.iitm.ac.in",
        image: require("../../assets/SaarangTeam/E27E19CF-AAB3-494E-9F8B-C3C4CDA4995C - Aaromal S Kesav hs20h061.JPG"),
        linkedin: "https://www.linkedin.com/in/aaromal-kesav-911a341bb?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BIsw%2B0XJoRtSC133StMykcQ%3D%3D",
        instagram: "https://www.instagram.com/kesav._/profilecard/?igsh=MXh6OTAxczJ2bDk4NA==",
      },
    ],
  },
  {
    sectionId: "section2",
    sectionName: "Development Operations",
    contacts: [
      {
        id: 3,
        role: "Core",
        name: "Sagar",
        phone: "+919620916448",
        email: "Sagar.Nagapnor@saarang.org",
        image: require("../../assets/SaarangTeam/sagar pics art.jpg"),
        linkedin: "https://www.linkedin.com/in/SagarNagapnor/",
        instagram: "https://www.instagram.com/SagarNagapnor/",
      },
      {
        id: 4,
        role: "Core",
        name: "Amjad Khan",
        phone: "9633488450",
        email: "Amjad.Khan@saarang.org",
        image: require("../../assets/SaarangTeam/IMG_20241230_214409 - Amjad Khan.jpg"),
        linkedin: "https://www.linkedin.com/in/amjad-khan-83471226b/",
        instagram: "https://www.instagram.com/khan_._amjad/profilecard/?igsh=YTV4anFqZ2x3dGd5",
      },
    ].sort((a, b) => a.name.localeCompare(b.name)),
  },
  {
    sectionId: "section3",
    sectionName: "Events",
    contacts: [
      {
        id: 5,
        role: "Core",
        name: "Swathy Suresh",
        phone: "+918848977767",
        email: "events@saarang.org",
        image: require("../../assets/SaarangTeam/SwathySuresh.jpg"),
        linkedin: "https://www.linkedin.com/in/swathy-suresh-iitm/",
        instagram:
          "https://www.instagram.com/swaesthetic_/profilecard/?igsh=MWd3OWE1dTVyZjJycQ==",
      },
      {
        id: 6,
        role: "Core",
        name: "Kshitish Saurav",
        phone: "+917091197977",
        email: "events@saarang.org",
        image: require("../../assets/SaarangTeam/KshitishSaurav.jpeg"),
        linkedin: "https://in.linkedin.com/in/kshitish-saurav-5b7756245",
        instagram: "https://www.instagram.com/kshitish.__/",
      },
      {
        id: 7,
        role: "Core",
        name: "Poojha D",
        phone: "+919566181789",
        email: "events@saarang.org",
        image: require("../../assets/SaarangTeam/D Poojha_Events Core 1.JPG"),
        linkedin: "www.linkedin.com/in/poojha-d-12aaa1242",
        instagram: "",
      },
    ].sort((a, b) => a.name.localeCompare(b.name)),
  },
  {
    sectionId: "section4",
    sectionName: "Facilities & Requirements ",
    contacts: [
      {
        id: 8,
        role: "Core",
        name: "Sarath K",
        phone: "+919848055407",
        email: "fr@saarang.org",
        image: require("../../assets/SaarangTeam/SarathchandraK.jpg"),
        linkedin:
          "https://www.linkedin.com/in/sarathchandra-k-ab2b09268?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:
          "https://www.instagram.com/sarath_k_12/profilecard/?igsh=cGFpYTNtbTZpdGk5",
      },
      {
        id: 9,
        role: "Core",
        name: "Dheeraj Maloth ",
        phone: "+916304287188",
        email: "fr@saarang.org",
        image: require("../../assets/SaarangTeam/DheerajNaik.jpg"),
        linkedin:
          "https://www.linkedin.com/in/dheeraj-naik-maloth-ab272123b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:
          "https://www.instagram.com/maloth_dheeraj/profilecard/?igsh=bHZnczZiMmVhYjFp",
      },
      {
        id: 10,
        role: "Core",
        name: "K Sai Chandra",
        phone: "+918185998085",
        email: "fr@saarang.org",
        image: require("../../assets/SaarangTeam/Kethavath Sai Chandra Naik ee21b070.jpg"),
        linkedin: "https://www.linkedin.com/in/sai-chandra-7a868324b/",
        instagram: "https://www.instagram.com/chandraaaaaa9/",
      },
    ].sort((a, b) => a.name.localeCompare(b.name)),
  },

  {
    sectionId: "section5",
    sectionName: "Finance ",
    contacts: [
      {
        id: 11,
        role: "Core",
        name: "Srilikhitha ",
        phone: "+919391619271",
        email: "finance@saarang.org",
        image: require("../../assets/SaarangTeam/Srilikhitha.jpeg"),
        linkedin: "https://www.linkedin.com/in/srilikhitha-sriramula-1997b0238",
        instagram:
          "https://www.instagram.com/saarangfinance?igsh=MW16aHVzM25memFndQ==",
      },
      {
        id: 12,
        role: "Core",
        name: "Gandi Sudheer",
        // phone: "+919666810655",
        phone: "",
        email: "finance@saarang.org",
        image: require("../../assets/SaarangTeam/IMG_2687 - Saarang Finance.jpg"),
        linkedin: "https://www.linkedin.com",
        instagram: "https://www.instagram.com",
      },
    ],
  },
  {
    sectionId: "section6",
    sectionName: "Marketing and Sales",
    contacts: [
      {
        id: 13,
        role: "Core",
        name: "C Anvesh",
        phone: "+918328607055",
        email: "ceelamanvesh@gmail.com",
        image: require("../../assets/SaarangTeam/Anvesh - Ceelam Anvesh ce21b037.jpg"),
        linkedin: "https://www.linkedin.com/in/ceelam-anvesh-838a44233",
        instagram: "https://www.instagram.com/ceelamanvesh/",
      },
      {
        id: 14,
        role: "Core",
        name: "Pranav",
        phone: "+919490901300",
        email: "mns@saarang.org",
        image: require("../../assets/SaarangTeam/Pranav.jpg"),
        linkedin: "https://www.linkedin.com/in/pranav-bheemavarapu/",
        instagram: "https://www.instagram.com/pranavforreal/",
      },
    ],
  },
  {
    sectionId: "section7",
    sectionName: "Media, Ambience & Design",
    contacts: [
      {
        id: 15,
        role: "Core",
        name: "Vinay Kumar",
        phone: "+917794936495",
        email: "media@smail.iitm.ac.in",
        image: require("../../assets/SaarangTeam/Vinaykumar.jpeg"),
        instagram: "https://www.instagram.com/vinay.__.7?igsh=bTNtanZjZmp6dWJj",
        linkedin: "",
      },
      {
        id: 16,
        role: "Core",
        name: "Indu Kalla",
        phone: "+917032645836",
        email: "mad@saarang.org",
        image: require("../../assets/SaarangTeam/Indukalla.png"),
        linkedin:
          "https://www.linkedin.com/in/indu-kalla-1a732b287/?originalSubdomain=in",
        instagram: "",
      },
      {
        id: 17,
        role: "Core",
        name: "Yaswand",
        phone: "+919025625078",
        email: "mad@saarang.org",
        image: require("../../assets/SaarangTeam/YaswandR.JPG"),
        linkedin: "https://www.linkedin.com/in/yaswand-1370a9268/",
        instagram:
          "https://www.instagram.com/yaswand._/?utm_source=ig_web_button_share_sheet",
        
      },
    ],
  },
  {
    sectionId: "section8",
    sectionName: "Professionals Shows",
    contacts: [
      {
        id: 18,
        role: "Core",
        name: "Abirami M",
        phone: "+919791497875",
        email: "professionalshows@saarang.org",
        image: require("../../assets/SaarangTeam/Abirami.jpg"),
        linkedin:
          "https://www.linkedin.com/in/abirami-m-914132234?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:
          "https://www.instagram.com/abii7___/profilecard/?igsh=d3lwcG5qcmE1cHNp",
      },
      {
        id: 19,
        role: "Core",
        name: "Vedant Jadhav",
        phone: "+918800889436",
        email: "professionalshows@saarang.org",
        image: require("../../assets/SaarangTeam/Vedant_CE21B128_basketball - Vedant Abhijeet Jadhav ce21b128.jpeg"),
        linkedin: "",
        instagram: "",
      },
    ],
  },

  {
    sectionId: "section9",
    sectionName: "Quality Management System",
    contacts: [
      {
        id: 20,
        role: "Core",
        name: "V J Vivek",
        phone: "+919400520126",
        email: "qms@saarang.org",
        image: require("../../assets/SaarangTeam/Vivek.jpg"),
        linkedin:
          "https://www.linkedin.com/in/vivek-v-j-a12777251?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:
          "https://www.instagram.com/v.j.vivek/profilecard/?igsh=cjY4M3g1bnJ4cDdh",
      },
      {
        id: 21,
        role: "Core",
        name: "Rohit Jadhav ",
        phone: "+917558693119",
        email: "qms@saarang.org",
        image: require("../../assets/SaarangTeam/rohit 2217.png"),
        linkedin: "",
        instagram: "https://www.instagram.com/r0hit_rocks/",
      },
    ].sort((a, b) => a.name.localeCompare(b.name)),
  },

  {
    sectionId: "section10",
    sectionName: "Safety and Security",
    contacts: [
      {
        id: 22,
        role: "Core",
        name: "Edla Laxman",
        phone: "+919550452012",
        email: "sns@saarang.org",
        image: require("../../assets/SaarangTeam/Laxman.jpg"),
        linkedin:
          "https://www.linkedin.com/in/laxman-edla-44b774272?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:
          "https://www.instagram.com/laxmanedla/profilecard/?igsh=Zzc4Z2F4cTlyenFk",
      },
      {
        id: 23,
        role: "Core",
        name: "Sahithi Suhana",
        phone: "+918008432473",
        email: "sns@saarang.org",
        image: require("../../assets/SaarangTeam/SahithiSuhana.jpeg"),
        linkedin:
          "https://www.linkedin.com/in/sahithi-suhana-mitte-3a5401234?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
        instagram:
          "https://www.instagram.com/sahithi_suhana/profilecard/?igsh=aGkxaWN0Y3o1ZnZv",
      },
    ],
  },
  {
    sectionId: "section11",
    sectionName: "Spons & PR",
    contacts: [
      {
        id: 24,
        role: "Core",
        name: "Arya Daoo",
        phone: "+917666372276",
        email: "publicrelations@saarang.org",
        image: require("../../assets/SaarangTeam/IMG_2521 - Saarang Public Relations.jpeg"),
        linkedin: "https://www.linkedin.com/in/arya-daoo-8b6260242?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
        instagram: "https://www.instagram.com/_aryayaya_ya/profilecard/?igsh=MXAwbjRtendlZXBzOQ==",
      },
      {
        id: 25,
        role: "Core",
        name: "Manas Maheshwari ",
        phone: "+919717471488",
        email: "sponsorship@saarang.org",
        image: require("../../assets/SaarangTeam/ManasMaheshwari.jpg"),
        linkedin: "https://www.linkedin.com/in/manas-maheshwari-4ba405232/",
        instagram: "https://www.instagram.com/manas.maheshwarii/",
      },
    ],
  },
  {
    sectionId: "section12",
    sectionName: "Student Relations",
    contacts: [
      {
        id: 26,
        role: "Core",
        name: "Eshwar Jilla",
        phone: "+919148001151",
        email: "studentrelations@saarang.org",
        image: require("../../assets/SaarangTeam/EshwarJilla.jpeg"),
        linkedin: "https://www.linkedin.com/in/eshwar-jilla-454713241/",
        instagram:
          "https://www.instagram.com/eshwar_jilla/profilecard/?igsh=MTcwcnk2amNuNzl6eQ==",
      },
      {
        id: 27,
        role: "Core",
        name: "Uday Kiran",
        phone: "+919381758113",
        email: "studentrelations@saarang.org",
        image: require("../../assets/SaarangTeam/UdayKiran.png"),
        linkedin:
          "https://www.linkedin.com/in/kota-uday-kiran-88057a227?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        instagram:
          "https://www.instagram.com/uday_kiran_kota/profilecard/?igsh=bnczbHVtMjBzNjcx",
      },
    ],
  },
];
