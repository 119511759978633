import { useNavigate } from "react-router-dom";
import { EventsLayout } from "../components/events/EventsLayout";
import { useGetClubsQuery } from "../generated/graphql";
import { ComboPassSection } from "../components/events/ComboPassSection";
// import Bg from "src/assets/Frame.jpg"
// import Dance from '../assets/'
// import '../styles/Events.scss'
import { motion } from "framer-motion";
import Footer from "../components/footer/footer";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { useGetMe } from "../hooks/useGetMe";
import Navbar from "../components/Navbar/Navbar";
export default function EventsPage() {
  const navigate = useNavigate();
  const { data, loading } = useGetClubsQuery();

  if (loading) return null;

  // Get unique categories
  const categories = Array.from(
    new Set(data?.getClubs?.map((club: any) => club.category))
  );

  const handleCategoryClick = (category: string) => {
    navigate(`/clubs/${category.toLowerCase()}`);
  };

  const fadeInVariant = {
    hidden: { opacity: 0 }, // Start hidden
    visible: {
      opacity: 1,
      transition: {
        duration: 1, // Duration of 1 second
        ease: "easeOut", // Easing for smooth transition
      },
    },
  };

  return (
    

    <motion.div
      className="h-full  overflow-hidden pt-[10vh]"
      style={{ backgroundImage: `url('Framebg.jpg')` }}
    >
      <Navbar />
      <div className="flex flex-col md:flex-row  justify-center py-[0px]">
        <div className="w-[50%] px-[5vw] py-[7vh]">
          <h1 className=" md:text-8xl text-4xl font-bold text-[#FFFB00] mb-[2vh] font-unlock tracking-wide">
            COMPETITIONS
          </h1>
          <h1 className="md:text-7xl text-4xl font-bold text-[white] mb-[2vh] font-unlock tracking-wider">
            SAARANG'25
          </h1>
          <p className="md:text-2xl text-1xl  text-[#FDB951] md:w-[120%] w-[90vw] mb-[2vh]">
            Unleash your creativity and passion at Saarang’s thrilling
            competitions, where Frames and Fables come alive. With cash prizes
            and exclusive opportunities awaiting the best, participants get a
            chance to etch their stories into Saarang’s legacy. Beyond the
            challenge, forge memories, friendships, and experiences that will
            linger as timeless tales.
          </p>
        </div>
        <div className="md:w-[50%] mr-[5%]">
          <img src="./dance.png" alt="img" />
        </div>
      </div>
      <motion.div
        variants={fadeInVariant} // Attach the fade-in variant
        initial="hidden" // Initial state: hidden
        whileInView="visible" // Trigger "visible" state when in view
        viewport={{ once: true }}
        className="relative w-[110vw] transform -translate-x-10 md:h-[20vh] h-[12vh] mt-[10%] rotate-15 overflow-hidden flex items-center justify-center bg-[#fffb00]"
      >
        <motion.div className="w-[full] whitespace-nowrap ">
          <span className="inline-block animate-scroll md:text-8xl text-4xl font-bold font-unlock text-black">
            REGISTER NOW &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; REGISTER NOW &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; REGISTER NOW
          </span>
        </motion.div>
      </motion.div>
      {/* <div className="flex justify-end w-[20%] "><img src='./cd.png'/></div> */}
      <div className="mt-[55vh] flex flex-col text-center justify-center ">
        <div>
          <h1 className=" md:text-8xl text-4xl font-bold text-[#FFFB00] mb-[15vh] font-unlock tracking-wide text-center">
            EVENT CATEGORIES
          </h1>
        </div>
        <motion.div className="flex md:flex-row  flex-col justify-center gap-10  items-center ">
          <motion.div  whileHover={{ scale: 1.02 }} // Scales up to 1.1 times its original size
      transition={{ type: "spring", stiffness: 300 }} // Smooth scaling with spring effect
            className="md:w-[30%] cursor-pointer"
            onClick={() => navigate("/clubs/performing arts")}
          >
            <img src="./perform.png" />{" "}
          </motion.div>
          <motion.div  whileHover={{ scale: 1.02 }} // Scales up to 1.1 times its original size
      transition={{ type: "spring", stiffness: 300 }} // Smooth scaling with spring effect
            className="md:w-[30%] cursor-pointer"
            onClick={() => navigate("/clubs/creative and digital arts")}
          >
            {" "}
            <img src="./creative.png" />{" "}
          </motion.div>
        </motion.div>
        <motion.div className="flex md:flex-row  flex-col justify-center gap-10  items-center">
          <motion.div  whileHover={{ scale: 1.01 }} // Scales up to 1.1 times its original size
      transition={{ type: "spring", stiffness: 300 }} // Smooth scaling with spring effect
            className="md:w-[30%] cursor-pointer"
            onClick={() => navigate("/clubs/literary arts")}
          >
            <img src="./literary.png" />{" "}
          </motion.div>
          <motion.div  whileHover={{ scale: 1.02 }} // Scales up to 1.1 times its original size
      transition={{ type: "spring", stiffness: 300 }} // Smooth scaling with spring effect
            className="md:w-[30%] cursor-pointer"
            onClick={() => navigate("/clubs/non competitive events")}
          >
            {" "}
            <img src="./noncomp.png" />{" "}
          </motion.div>
        </motion.div>
        {/* <div className="flex flex-row justify-center gap-10 mt-[10vh]"><div className="w-[50%]"> <img src="./Pop-corn.png"/> </div> <div className="w-[50%]"> <img src="./Pop-corn.png"/> </div></div> */}
      </div>
      <div className="mt-[16vh]">
        <ComboPassSection />
      </div>

      {/* footer */}
      {/* <div className=" z-99  flex flex-col min-h-[15vh] px-[10%]  bg-[#000000] text-white md:flex-row justify-center items-center align-center">
        <div className=" flex flex-col md:flex-row justify-between items-center w-full h-[7vh] md:px-[15px] px-[15px]">
          <div className="flex flex-row pl-[30px]">
            <MdEmail className="text-[22px] mt-[3px] mr-[3px] text-white" />
            <a
              href="mailto:events@saarang.org"
              className="flex flex-row md:text-1xl text-center text-white"
            >
              events@saarang.org
            </a>
          </div>

          <div className="flex flex-row text-[#0e0101]">
            <div className="text-center  font-[700] md:text-sm text-xs py-2 text-white">
              © Designed and Developed by Saarang DevOps
            </div>
          </div>
          <motion.div className="bb flex flex-row space-x-4 mr-[10px]">
            <motion.div whileHover={{ scale: 1.2, color: "#0e0101" }}>
              <a href="https://www.facebook.com/saarangiitm/">
                <FaFacebook className="text-[29px] text-[white] text-white" />{" "}
              </a>
            </motion.div>
            <motion.div whileHover={{ scale: 1.2, color: "#0e0101" }}>
              <a href="https://www.instagram.com/saarang_iitmadras">
                {" "}
                <FaInstagram className="text-[29px]  text-white" />
              </a>
            </motion.div>
            <motion.div whileHover={{ scale: 1.2, color: "#0e0101" }}>
              <a href="https://www.linkedin.com/company/saarang/posts/?feedView=all">
                {" "}
                <FaLinkedin className="text-[29px]  text-white" />
              </a>
            </motion.div>
            <motion.div whileHover={{ scale: 1.2, color: "#0e0101" }}>
              <a href="https://x.com/saarang_iitm">
                {" "}
                <FaTwitter className="text-[29px]  text-white" />
              </a>
            </motion.div>
          </motion.div>
        </div>
      </div> */}

    </motion.div>
  );
}
