import React, { createContext, useContext, useEffect, useState, useRef } from "react";
import { auth } from "../config/firebase";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  User as FirebaseUser,
} from "firebase/auth";
import { useMutation } from "@apollo/client";
import {
  GoogleSignInDocument,
  LoginDocument,
  Register_NewDocument,
  LogoutDocument,
} from "../generated/graphql";
// Define backend user interface based on GraphQL response
interface BackendUser {
  user_id: string;
  saarang_id: string;
  email_primary: string;
  email_secondary?: string;
  username: string;
  mobile_number_primary?: string;
  mobile_number_secondary?: string;
  positions?: string[];
  DOB?: string;
  verified: boolean;
  profile_picture?: string;
  college?: string;
  college_email_id?: string;
  college_location?: string;
  points_scored?: number;
  year_of_study?: string;
  region?: string;
  state?: string;
  isIITM?: boolean;
  google_id: string;
  new_user?: boolean;
}

interface AuthContextType {
  firebaseUser: FirebaseUser | null;
  user: BackendUser | null;
  loading: boolean;
  error: string | null;
  signInWithGoogle: () => Promise<BackendUser | null>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
  updateUserData: (userData: BackendUser) => void;
  signUpWithEmailAndPassword: (
    email: string,
    password: string,
    username: string
  ) => Promise<BackendUser | null>;
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<BackendUser | null>;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null);
  const [user, setUser] = useState<BackendUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const authStateMounted = useRef(false);
  const isLoggingOut = useRef(false);

  const [googleSignInMutation] = useMutation(GoogleSignInDocument);
  const [loginMutation] = useMutation(LoginDocument);
  const [registerMutation] = useMutation(Register_NewDocument);
  const [logoutMutation] = useMutation(LogoutDocument);
  
  useEffect(() => {
    if (authStateMounted.current) return;
    authStateMounted.current = true;

    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (isLoggingOut.current) {
        return;
      }

      setFirebaseUser(firebaseUser);
      try {
        if (firebaseUser) {
          const idToken = await firebaseUser.getIdToken();
          const response = await googleSignInMutation({
            variables: {
              uid: firebaseUser.uid,
              email: firebaseUser.email || "",
              displayName: firebaseUser.displayName || "",
              photoUrl: firebaseUser.photoURL || "",
              idToken: idToken,
            },
          });

          if (response.data?.googleSignIn) {
            setUser(response.data.googleSignIn);
            setIsAuthenticated(true);
          }
        } else {
          // Only check localStorage if we're not actively logging out
          // and there's no Firebase user
          const savedUser = localStorage.getItem("authUser");
          if (savedUser && !isLoggingOut.current) {
            const userData = JSON.parse(savedUser);
            setUser(userData);
            setIsAuthenticated(true);
          } else {
            // Clear states if no user is found
            setUser(null);
            setIsAuthenticated(false);
          }
        }
      } catch (err) {
        console.error("Error in auth state:", err);
        if (!isLoggingOut.current) {
          setUser(null);
          setIsAuthenticated(false);
        }
      } finally {
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
      authStateMounted.current = false;
    };
  }, [googleSignInMutation]);

  const signInWithGoogle = async () => {
    setError(null);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      setFirebaseUser(result.user);

      const response = await googleSignInMutation({
        variables: {
          uid: result.user.uid,
          email: result.user.email || "",
          displayName: result.user.displayName || "",
          photoUrl: result.user.photoURL || "",
        },
      });

      if (response.data?.googleSignIn) {
        setUser(response.data.googleSignIn);
        setIsAuthenticated(true);
      } else {
        throw new Error("Failed to get user data from backend");
      }
      return response.data?.googleSignIn;
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "Failed to sign in with Google"
      );
      console.error("Google Sign In Error:", err);
      return null;
    } 
  };  


  const logout = async () => {
    // Set logging out flag before any async operations
    isLoggingOut.current = true;
    
    try {
      // Clear all auth states first
      setUser(null);
      setFirebaseUser(null);
      setIsAuthenticated(false);

      // Clear storage
      localStorage.removeItem("authUser");
      localStorage.clear();
      sessionStorage.clear();

      // Call backend logout
      await logoutMutation();

      // Sign out from Firebase
      await signOut(auth);
      
      // Force reload to ensure clean state
      window.location.href = '/';
    } catch (err) {
      console.error("Sign Out Error:", err);
      throw err;
    } finally {
      // Reset the logging out flag
      isLoggingOut.current = false;
    }
  };
  useEffect(() => {
    console.log("User state updated to:", user);
  }, [user]);
  const updateUserData = (userData: BackendUser) => {
    setUser(userData);
  };

  const signUpWithEmailAndPassword = async (
    email: string,
    password: string,
    username: string
  ) => {
    setError(null);
    try {
      const response = await registerMutation({
        variables: {
          emailPrimary: email,
          password: password,
          username: username,
        },
      });

      if (response.data?.register_new) {
        const userData = response.data.register_new;
        localStorage.setItem("authUser", JSON.stringify(userData));
        setUser(userData);
        setIsAuthenticated(true);
        return userData;
      }
      throw new Error("Failed to register user");
    } catch (err: any) {
      setError(err.message || "Failed to sign up");
      console.error("Registration Error:", err);
      throw err;
    }
  };

  const emailPasswordSignIn = async (email: string, password: string) => {
    setError(null);
    try {
      const response = await loginMutation({
        variables: {
          email,
          password,
        },
      });

      if (response.data?.login) {
        const userData = response.data.login;
        localStorage.setItem("authUser", JSON.stringify(userData));
        setUser(userData);
        setIsAuthenticated(true);
        return userData;
      }
      throw new Error("Failed to get user data from backend");
    } catch (err: any) {
      setError(err.message || "Authentication failed");
      throw new Error(err.message || "Authentication failed");
    }
  };

  const value = {
    firebaseUser,
    user,
    loading,
    error,
    signInWithGoogle,
    logout,
    isAuthenticated,
    updateUserData,
    signUpWithEmailAndPassword,
    signInWithEmailAndPassword: emailPasswordSignIn,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
