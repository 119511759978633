import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { useState } from 'react';
import { EventRegistrationModal } from './EventRegistrationModal';
import { useAuth } from '../../contexts/AuthContext';
import { Calendar, MapPin, Tag } from 'lucide-react'; // Import icons

interface WorkshopCardProps {
  id: string;
  title: string;
  description: string[];
  image_url: string;
  event_date: string;
  price: number;
  event_location: string;
  registrations_closed: boolean;
  Club: {
    id: string;
    clubName: string;
    logo: string;
    category: string;
  };
}

export const WorkshopCard = (event: WorkshopCardProps) => {
  const { user } = useAuth();
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const isIITMStudent = user?.email_primary?.endsWith('smail.iitm.ac.in');

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        whileHover={{ y: -5 }}
        className="bg-yellow-300 lg:w-[25vw] md:min-w-[5vw] sm:min-w-[80px] min-h-[7vw] rounded-xl shadow-sm overflow-hidden border border-gray-100 transition-all hover:shadow-m p-2"
      >
        <div className="font-roboto">
          {/* Image as a regular img element */}
          <img 
            src={event.image_url} 
            alt={event.title}
            className="w-full object-cover"
          />
          
          {/* Content section */}
          <div className="p-3 space-y-3">
            {/* <h3 className="text-xl font-bold text-black font-roboto text-center">
              {event.title.toUpperCase()}
            </h3> */}
            <div className="space-y-1">
              {/* Event Date with Calendar Icon */}
              {/* <div className="text-lg text-right text-black font-medium flex items-center justify-end gap-2">
                {format(new Date(event.event_date), 'PPP')}
                <Calendar className="w-5 h-5 text-black" />
              </div> */}
              {/* Event Location with MapPin Icon */}
              {/* <div className="text-lg text-right text-black font-medium flex items-center justify-end gap-2">
                {event.event_location}
                <MapPin className="w-5 h-5 text-black" />
              </div> */}
              {/* Price with Tag Icon */}
              <div className="text-lg text-right text-black font-semibold flex items-center justify-center gap-2">
               Registration Fee: &nbsp;{(isIITMStudent && event.Club.category.toLowerCase() !== 'workshops')||event.price===0 ? 'Free' : `  ₹${event.price}`}
                {/* <Tag className="w-5 h-5 text-black" /> */}
              </div>
            </div>
            <div className="flex gap-12 mt-2 justify-center">
              <button
                onClick={() => setShowDetailsModal(true)}
                className="py-2 px-4 rounded-lg font-semibold bg-yellow-500 text-black hover:bg-yellow-600 transition-colors text-sm "
              >
                View Details
              </button>
              <button
                onClick={() => setShowRegistrationModal(true)}
                disabled={event.registrations_closed}
                className={`py-2 px-4 rounded-lg font-semibold transition-colors text-sm ${
                  !event.registrations_closed
                    ? 'bg-yellow-500 text-black hover:bg-yellow-600'
                    : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                }`}
              >
                Register Now
              </button>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Event Details Modal */}
     {showDetailsModal && (
  <div className="fixed inset-0 bg-black/75 backdrop-blur-sm flex items-center justify-center p-4 z-50">
    <div className="bg-[#FDB951] rounded-xl max-w-md md:max-w-lg w-full p-6 border border-gray-100 dark:border-gray-700 shadow-2xl">
      <h3 className="text-lg font-bold text-black mb-3">{event.title}</h3>
      <ul className="space-y-2 mb-5 list-inside list-disc">
        {event.description.map((point, index) => (
          <li
            key={index}
            className="text-sm text-black"
            dangerouslySetInnerHTML={{ __html: point }}
          ></li>
        ))}
      </ul>
      <button
        onClick={() => setShowDetailsModal(false)}
        className="w-full py-2 px-4 rounded-lg font-semibold bg-yellow-600 text-black hover:bg-yellow-700 transition-colors"
      >
        Close
      </button>
    </div>
  </div>
)}

      {/* Event Registration Modal */}
      <EventRegistrationModal
        isOpen={showRegistrationModal}
        onClose={() => setShowRegistrationModal(false)}
        event={event}
      />
    </>
  );
};
