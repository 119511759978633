import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
};

export type Accommodation = {
  __typename?: 'Accommodation';
  aadhaar: Scalars['String']['output'];
  amount: Scalars['Float']['output'];
  arrival: Scalars['DateTimeISO']['output'];
  college_name: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  departure: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mobile_no: Scalars['String']['output'];
  name: Scalars['String']['output'];
  num_females: Scalars['Float']['output'];
  num_males: Scalars['Float']['output'];
  razorpay_order_id: Scalars['String']['output'];
  razorpay_payment_id?: Maybe<Scalars['String']['output']>;
  refunds?: Maybe<Array<Refund>>;
  student_id: Scalars['String']['output'];
  user: Users;
  verified: Scalars['Boolean']['output'];
};

export type CancelAccommodationInput = {
  id: Scalars['String']['input'];
  num_females: Scalars['Float']['input'];
  num_males: Scalars['Float']['input'];
};

export type Club = {
  __typename?: 'Club';
  ContactName?: Maybe<Scalars['String']['output']>;
  ContactNumber?: Maybe<Scalars['String']['output']>;
  category: Scalars['String']['output'];
  clubName: Scalars['String']['output'];
  clubPassPrice: Scalars['Float']['output'];
  events?: Maybe<Array<Events>>;
  id: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
};

export type ClubPassRegistration = {
  __typename?: 'ClubPassRegistration';
  club_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  razorpay_order_id: Scalars['String']['output'];
  reg_date: Scalars['String']['output'];
  registered_event_ids?: Maybe<Array<Scalars['String']['output']>>;
  user_id: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type ClubPassRegistrationWithDetails = {
  __typename?: 'ClubPassRegistrationWithDetails';
  club: Club;
  registration: ClubPassRegistration;
};

export type ClubPassRegistrationWithDetailsWithUser = {
  __typename?: 'ClubPassRegistrationWithDetailsWithUser';
  club: Club;
  registration: ClubPassRegistration;
  user: Users;
};

export type ComboPass = {
  __typename?: 'ComboPass';
  club_ids: Array<Scalars['String']['output']>;
  current_registrations?: Maybe<Scalars['Float']['output']>;
  description: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  image_url?: Maybe<Scalars['String']['output']>;
  is_active?: Maybe<Scalars['Boolean']['output']>;
  max_registrations?: Maybe<Scalars['Float']['output']>;
  price: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  valid_till?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ComboPassRegistration = {
  __typename?: 'ComboPassRegistration';
  club_pass_registration_ids?: Maybe<Array<Scalars['String']['output']>>;
  combo_pass_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  payment_id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  razorpay_order_id: Scalars['String']['output'];
  reg_date: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type ComboPassRegistrationDetails = {
  __typename?: 'ComboPassRegistrationDetails';
  clubs: Array<Club>;
  comboPass: ComboPass;
  registration: ComboPassRegistration;
  user: Users;
};

export type ComboPassWithClubs = {
  __typename?: 'ComboPassWithClubs';
  clubs: Array<Club>;
  comboPass: ComboPass;
};

export type CreateAccommodationInput = {
  aadhaar: Scalars['String']['input'];
  arrival: Scalars['DateTimeISO']['input'];
  college_name: Scalars['String']['input'];
  departure: Scalars['DateTimeISO']['input'];
  email: Scalars['String']['input'];
  mobile_no: Scalars['String']['input'];
  name: Scalars['String']['input'];
  num_females: Scalars['Float']['input'];
  num_males: Scalars['Float']['input'];
  student_id: Scalars['String']['input'];
};

export type EscapeRoom = {
  __typename?: 'EscapeRoom';
  description: Array<Scalars['String']['output']>;
  event_date: Scalars['DateTimeISO']['output'];
  event_location: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image_url?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  registrations_closed: Scalars['Boolean']['output'];
  slot: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type EscapeRoomRegistration = {
  __typename?: 'EscapeRoomRegistration';
  escape_room_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  razorpay_order_id: Scalars['String']['output'];
  reg_date?: Maybe<Scalars['DateTimeISO']['output']>;
  slot: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type EscapeRoomRegistrationDetails = {
  __typename?: 'EscapeRoomRegistrationDetails';
  escapeRoom: EscapeRoom;
  registration: EscapeRoomRegistration;
  user: Users;
};

export type Events = {
  __typename?: 'Events';
  Club: Club;
  description: Array<Scalars['String']['output']>;
  event_date: Scalars['DateTimeISO']['output'];
  event_location?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  image_url?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  registrations_closed?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
};

export type MilanEvent = {
  __typename?: 'MilanEvent';
  city: Scalars['String']['output'];
  date: Scalars['DateTimeISO']['output'];
  description: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  maxParticipants: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  venue: Scalars['String']['output'];
};

export type MilanRegistration = {
  __typename?: 'MilanRegistration';
  amount?: Maybe<Scalars['Float']['output']>;
  event_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  participants?: Maybe<Scalars['Float']['output']>;
  razorpay_order_id: Scalars['String']['output'];
  reg_date?: Maybe<Scalars['DateTimeISO']['output']>;
  user_id: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type MilanRegistrationDetails = {
  __typename?: 'MilanRegistrationDetails';
  event: MilanEvent;
  registration: MilanRegistration;
  user: Users;
};

export type Mutation = {
  __typename?: 'Mutation';
  approveSubmission: Scalars['Boolean']['output'];
  check_forgot: Scalars['Boolean']['output'];
  check_reg: Users;
  commonRegister: Users;
  createAccommodation: Accommodation;
  createAllSlotEscapeRoomSameTitleDescription: Scalars['Boolean']['output'];
  createClub: Club;
  createClubPassRegistration: ClubPassRegistration;
  createComboPass: ComboPass;
  createComboPassRegistration: ComboPassRegistration;
  createDirectClubPassRegistration: ClubPassRegistration;
  createDirectComboPassRegistration: ComboPassRegistration;
  createDirectEventRegistration: SingleEventsRegistration;
  createEscapeRoom: EscapeRoom;
  createEscapeRoomRegistration: EscapeRoomRegistration;
  createEvents: Events;
  createMilanEvent: MilanEvent;
  createMilanRegistration: MilanRegistration;
  createSingleEventRegistration: SingleEventsRegistration;
  createSubmission: Scalars['Boolean']['output'];
  createTask: Task;
  deleteAccommodation: Scalars['Boolean']['output'];
  deleteClub: Scalars['Boolean']['output'];
  deleteComboPass: Scalars['Boolean']['output'];
  deleteEscapeRoom: Scalars['Boolean']['output'];
  deleteEvents: Scalars['Boolean']['output'];
  deleteMilanEvent: Scalars['Boolean']['output'];
  deleteTask: Scalars['Boolean']['output'];
  getAccommodationById: Accommodation;
  googleSignIn: Users;
  incAcco: Scalars['Boolean']['output'];
  login: Users;
  logout: Scalars['Float']['output'];
  refundAccommodation: Scalars['Boolean']['output'];
  register: Users;
  registerSa: Users;
  register_new: Users;
  sendVerificationEmail: Scalars['Boolean']['output'];
  updateClub: Club;
  updateComboPass: ComboPass;
  updateEscapeRoom: EscapeRoom;
  updateEvents?: Maybe<Events>;
  updateMilanEvent?: Maybe<MilanEvent>;
  updateProfile: Users;
  updateTask: Task;
  updateUserPosition: Users;
  update_points: Scalars['Boolean']['output'];
  verifyEventsPayment: SingleEventsRegistration;
  verifyPayment: MilanRegistration;
};


export type MutationApproveSubmissionArgs = {
  data: ApproveSubmissionInput;
};


export type MutationCheck_ForgotArgs = {
  email: Scalars['String']['input'];
  pass: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationCheck_RegArgs = {
  email_base64: Scalars['String']['input'];
  login_hash: Scalars['String']['input'];
};


export type MutationCommonRegisterArgs = {
  college: Scalars['String']['input'];
  email_primary: Scalars['String']['input'];
  email_secondary?: InputMaybe<Scalars['String']['input']>;
  isIITM: Scalars['String']['input'];
  mobile_number_primary: Scalars['String']['input'];
  mobile_number_secondary?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  portal: Scalars['String']['input'];
  positions?: Scalars['String']['input'];
  region: Scalars['String']['input'];
  state: Scalars['String']['input'];
  username: Scalars['String']['input'];
  year_of_study: Scalars['String']['input'];
};


export type MutationCreateAccommodationArgs = {
  data: CreateAccommodationInput;
};


export type MutationCreateClubArgs = {
  ContactName?: InputMaybe<Scalars['String']['input']>;
  ContactNumber?: InputMaybe<Scalars['String']['input']>;
  category: Scalars['String']['input'];
  clubName: Scalars['String']['input'];
  clubPassPrice: Scalars['Float']['input'];
  logo?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateClubPassRegistrationArgs = {
  club_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationCreateComboPassArgs = {
  club_ids: Array<Scalars['String']['input']>;
  description: Array<Scalars['String']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  max_registrations?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
  valid_till?: InputMaybe<Scalars['DateTimeISO']['input']>;
};


export type MutationCreateComboPassRegistrationArgs = {
  combo_pass_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationCreateDirectClubPassRegistrationArgs = {
  club_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationCreateDirectComboPassRegistrationArgs = {
  combo_pass_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationCreateDirectEventRegistrationArgs = {
  events_id: Scalars['String']['input'];
  razorpay_order_id?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateEscapeRoomArgs = {
  description: Array<Scalars['String']['input']>;
  event_date: Scalars['DateTimeISO']['input'];
  event_location: Scalars['String']['input'];
  image_url?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  registrations_closed: Scalars['Boolean']['input'];
  slot: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationCreateEscapeRoomRegistrationArgs = {
  escape_room_id: Scalars['String']['input'];
  razorpay_order_id?: InputMaybe<Scalars['String']['input']>;
  slot: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateEventsArgs = {
  clubId: Scalars['String']['input'];
  description: Array<Scalars['String']['input']>;
  event_date: Scalars['DateTimeISO']['input'];
  event_location: Scalars['String']['input'];
  image_url?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  registrations_closed: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};


export type MutationCreateMilanEventArgs = {
  city: Scalars['String']['input'];
  date: Scalars['DateTimeISO']['input'];
  description: Array<Scalars['String']['input']>;
  maxParticipants: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  venue: Scalars['String']['input'];
};


export type MutationCreateMilanRegistrationArgs = {
  event_id: Scalars['String']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  razorpay_order_id?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateSingleEventRegistrationArgs = {
  events_id: Scalars['String']['input'];
  razorpay_order_id?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateSubmissionArgs = {
  data: CreateSubmissionInput;
};


export type MutationCreateTaskArgs = {
  created_at: Scalars['String']['input'];
  deadline: Scalars['String']['input'];
  points: Scalars['Float']['input'];
  task_desc: Scalars['String']['input'];
  task_name: Scalars['String']['input'];
};


export type MutationDeleteAccommodationArgs = {
  AccommodationID: Scalars['String']['input'];
};


export type MutationDeleteClubArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteComboPassArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteEscapeRoomArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteEventsArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMilanEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteTaskArgs = {
  id: Scalars['String']['input'];
};


export type MutationGetAccommodationByIdArgs = {
  id: Scalars['String']['input'];
};


export type MutationGoogleSignInArgs = {
  displayName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  photoURL: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type MutationIncAccoArgs = {
  id: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRefundAccommodationArgs = {
  data: CancelAccommodationInput;
};


export type MutationRegisterArgs = {
  DOB?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email_primary: Scalars['String']['input'];
  email_secondary?: InputMaybe<Scalars['String']['input']>;
  mobile_number_primary?: InputMaybe<Scalars['String']['input']>;
  mobile_number_secondary?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  positions?: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationRegisterSaArgs = {
  sa_answer: Scalars['String']['input'];
  sa_answer2: Scalars['String']['input'];
  sa_answer3: Scalars['String']['input'];
  sa_referral?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegister_NewArgs = {
  email_primary: Scalars['String']['input'];
  password: Scalars['String']['input'];
  positions?: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationSendVerificationEmailArgs = {
  email_primary: Scalars['String']['input'];
};


export type MutationUpdateClubArgs = {
  ContactName?: InputMaybe<Scalars['String']['input']>;
  ContactNumber?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  clubName?: InputMaybe<Scalars['String']['input']>;
  clubPassPrice?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  logo?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateComboPassArgs = {
  club_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  image_url?: InputMaybe<Scalars['String']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  max_registrations?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  valid_till?: InputMaybe<Scalars['DateTimeISO']['input']>;
};


export type MutationUpdateEscapeRoomArgs = {
  description: Array<Scalars['String']['input']>;
  event_date: Scalars['DateTimeISO']['input'];
  event_location: Scalars['String']['input'];
  id: Scalars['String']['input'];
  image_url?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  registrations_closed: Scalars['Boolean']['input'];
  slot: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationUpdateEventsArgs = {
  clubId: Scalars['String']['input'];
  description: Array<Scalars['String']['input']>;
  event_date: Scalars['DateTimeISO']['input'];
  event_location: Scalars['String']['input'];
  id: Scalars['String']['input'];
  image_url?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  registrations_closed: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};


export type MutationUpdateMilanEventArgs = {
  city: Scalars['String']['input'];
  date: Scalars['DateTimeISO']['input'];
  description: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  maxParticipants: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  venue: Scalars['String']['input'];
};


export type MutationUpdateProfileArgs = {
  college: Scalars['String']['input'];
  mobile_number_primary: Scalars['String']['input'];
  region: Scalars['String']['input'];
  state: Scalars['String']['input'];
  year_of_study: Scalars['String']['input'];
};


export type MutationUpdateTaskArgs = {
  deadline?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  task_desc?: InputMaybe<Scalars['String']['input']>;
  task_name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserPositionArgs = {
  positions: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationUpdate_PointsArgs = {
  points_awarded: Scalars['Float']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationVerifyEventsPaymentArgs = {
  orderId: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};


export type MutationVerifyPaymentArgs = {
  orderId: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  checkInformalsClubPassStatus: Scalars['Boolean']['output'];
  dummy?: Maybe<Scalars['String']['output']>;
  forgotPassword: Scalars['Boolean']['output'];
  geEventsRegistrationsByEventId: Array<SingleEventsRegistrationDetails>;
  getActiveComboPassesWithAvailability: Array<ComboPassWithClubs>;
  getAllAccommodations: Array<Accommodation>;
  getAllComboPassRegistrations: Array<ComboPassRegistrationDetails>;
  getAllComboPasses: Array<ComboPassWithClubs>;
  getAllEventRegistrations: Array<SingleEventsRegistrationDetails>;
  getAllRefunds: Array<Refund>;
  getAllUsers: Array<Users>;
  getAvailableSlots: Array<Scalars['String']['output']>;
  getAvailableSlotsByDate: Array<Scalars['String']['output']>;
  getClubPassHolderRegistrations: Array<EscapeRoomRegistrationDetails>;
  getClubPassRegistrations: Array<ClubPassRegistrationWithDetailsWithUser>;
  getClubs: Array<Club>;
  getComboPass: ComboPassWithClubs;
  getEscapeRoomById?: Maybe<EscapeRoom>;
  getEscapeRoomByLocation: Array<EscapeRoom>;
  getEscapeRoomRegistrations: Array<EscapeRoomRegistrationDetails>;
  getEscapeRooms: Array<EscapeRoom>;
  getEventByClub: Array<Events>;
  getEventBylocation: Array<Events>;
  getEvents: Array<Events>;
  getEventsById?: Maybe<Events>;
  getLeaderboard: Array<Users>;
  getMe: Users;
  getMilanEvent: Array<MilanEvent>;
  getMilanEventByCity: Array<MilanEvent>;
  getMilanEventById?: Maybe<MilanEvent>;
  getMilanRegistrationsByEventId: Array<MilanRegistrationDetails>;
  getMyAccommodations: Array<Accommodation>;
  getMyRefunds: Array<Refund>;
  getRegistrationsBySlot: Array<EscapeRoomRegistrationDetails>;
  getSlotAvailabilityByDate: Array<Array<Scalars['String']['output']>>;
  getSlotCapacity: Scalars['Float']['output'];
  getSubmissions: Array<Submission>;
  getTasks: Array<Task>;
  getUnsubmittedTasks?: Maybe<Array<Task>>;
  getUserClubPassRegistrations: Array<ClubPassRegistrationWithDetails>;
  getUserComboPassRegistrations: Array<UserComboPassRegistrationDetails>;
  getUserEscapeRoomRegistrations: Array<EscapeRoomRegistrationDetails>;
  getUserMilanRegistrations: Array<MilanRegistration>;
  getUserSingleEventRegistrations: Array<SingleEventsRegistrationDetails>;
  getUserSubmissions?: Maybe<Array<Submission>>;
  getUsersByPosition: Array<Users>;
};


export type QueryCheckInformalsClubPassStatusArgs = {
  user_id: Scalars['String']['input'];
};


export type QueryForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type QueryGeEventsRegistrationsByEventIdArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryGetAvailableSlotsArgs = {
  escape_room_id: Scalars['String']['input'];
};


export type QueryGetAvailableSlotsByDateArgs = {
  escape_room_id: Scalars['String']['input'];
  event_date: Scalars['DateTimeISO']['input'];
};


export type QueryGetClubPassHolderRegistrationsArgs = {
  escape_room_id: Scalars['String']['input'];
};


export type QueryGetClubPassRegistrationsArgs = {
  clubId: Scalars['String']['input'];
};


export type QueryGetComboPassArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetEscapeRoomByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetEscapeRoomByLocationArgs = {
  location: Scalars['String']['input'];
};


export type QueryGetEventByClubArgs = {
  clubId: Scalars['String']['input'];
};


export type QueryGetEventBylocationArgs = {
  location: Scalars['String']['input'];
};


export type QueryGetEventsByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMilanEventByCityArgs = {
  city: Scalars['String']['input'];
};


export type QueryGetMilanEventByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMilanRegistrationsByEventIdArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryGetRegistrationsBySlotArgs = {
  escape_room_id: Scalars['String']['input'];
  slot: Scalars['String']['input'];
};


export type QueryGetSlotAvailabilityByDateArgs = {
  escape_room_id: Scalars['String']['input'];
  event_date: Scalars['DateTimeISO']['input'];
};


export type QueryGetSlotCapacityArgs = {
  escape_room_id: Scalars['String']['input'];
  slot: Scalars['String']['input'];
};


export type QueryGetUserClubPassRegistrationsArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUserComboPassRegistrationsArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUserEscapeRoomRegistrationsArgs = {
  user_id: Scalars['String']['input'];
};


export type QueryGetUserMilanRegistrationsArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUserSingleEventRegistrationsArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUsersByPositionArgs = {
  positions: Scalars['String']['input'];
};

export type Refund = {
  __typename?: 'Refund';
  accommodation: Accommodation;
  amountExtra: Scalars['Float']['output'];
  amountRefunded: Scalars['Float']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  num_females: Scalars['Float']['output'];
  num_males: Scalars['Float']['output'];
  razorpay_refund_id: Scalars['String']['output'];
};

export type SingleEventsRegistration = {
  __typename?: 'SingleEventsRegistration';
  events_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  razorpay_order_id: Scalars['String']['output'];
  reg_date?: Maybe<Scalars['DateTimeISO']['output']>;
  user_id: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type SingleEventsRegistrationDetails = {
  __typename?: 'SingleEventsRegistrationDetails';
  event: Events;
  registration: SingleEventsRegistration;
  user: Users;
};

export type Submission = {
  __typename?: 'Submission';
  created_at: Scalars['DateTimeISO']['output'];
  files: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  points_awarded: Scalars['Float']['output'];
  task: Task;
  user: Users;
  verification_status: Scalars['Boolean']['output'];
};

export type Task = {
  __typename?: 'Task';
  created_at: Scalars['DateTimeISO']['output'];
  deadline: Scalars['String']['output'];
  id: Scalars['String']['output'];
  points: Scalars['Float']['output'];
  submission?: Maybe<Array<Submission>>;
  task_desc: Scalars['String']['output'];
  task_name: Scalars['String']['output'];
};

export type UserComboPassRegistrationDetails = {
  __typename?: 'UserComboPassRegistrationDetails';
  clubs: Array<Club>;
  comboPass: ComboPass;
  registration: ComboPassRegistration;
};

export type ApproveSubmissionInput = {
  points: Scalars['Float']['input'];
  sub_id: Scalars['String']['input'];
};

export type CreateSubmissionInput = {
  files: Array<Scalars['String']['input']>;
  task_id: Scalars['String']['input'];
};

export type Users = {
  __typename?: 'users';
  DOB?: Maybe<Scalars['DateTimeISO']['output']>;
  accommodation?: Maybe<Accommodation>;
  college?: Maybe<Scalars['String']['output']>;
  college_email_id?: Maybe<Scalars['String']['output']>;
  college_location?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTimeISO']['output']>;
  email_primary: Scalars['String']['output'];
  email_secondary?: Maybe<Scalars['String']['output']>;
  google_id?: Maybe<Scalars['String']['output']>;
  isIITM?: Maybe<Scalars['String']['output']>;
  mobile_number_primary?: Maybe<Scalars['String']['output']>;
  mobile_number_secondary?: Maybe<Scalars['String']['output']>;
  new_user?: Maybe<Scalars['Boolean']['output']>;
  points_scored?: Maybe<Scalars['Float']['output']>;
  positions: Scalars['String']['output'];
  profile_picture?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  sa_answer?: Maybe<Scalars['String']['output']>;
  sa_answer2?: Maybe<Scalars['String']['output']>;
  sa_answer3?: Maybe<Scalars['String']['output']>;
  sa_referral?: Maybe<Scalars['String']['output']>;
  saarang_id: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  submission?: Maybe<Submission>;
  updated_at?: Maybe<Scalars['DateTimeISO']['output']>;
  user_id: Scalars['String']['output'];
  username: Scalars['String']['output'];
  verified?: Maybe<Scalars['Boolean']['output']>;
  year_of_study?: Maybe<Scalars['String']['output']>;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', getMe: { __typename?: 'users', user_id: string, saarang_id: string, email_primary: string, email_secondary?: string | null, username: string, mobile_number_primary?: string | null, mobile_number_secondary?: string | null, positions: string, DOB?: any | null, verified?: boolean | null, profile_picture?: string | null, college?: string | null, college_email_id?: string | null, college_location?: string | null, points_scored?: number | null, year_of_study?: string | null, region?: string | null, state?: string | null, created_at?: any | null, updated_at?: any | null, isIITM?: string | null, sa_answer?: string | null, sa_answer2?: string | null, sa_answer3?: string | null, sa_referral?: string | null, google_id?: string | null, new_user?: boolean | null } };

export type GoogleSignInMutationVariables = Exact<{
  uid: Scalars['String']['input'];
  email: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  photoUrl: Scalars['String']['input'];
}>;


export type GoogleSignInMutation = { __typename?: 'Mutation', googleSignIn: { __typename?: 'users', user_id: string, saarang_id: string, email_primary: string, email_secondary?: string | null, username: string, mobile_number_primary?: string | null, mobile_number_secondary?: string | null, positions: string, DOB?: any | null, verified?: boolean | null, profile_picture?: string | null, college?: string | null, college_email_id?: string | null, college_location?: string | null, points_scored?: number | null, year_of_study?: string | null, region?: string | null, state?: string | null, isIITM?: string | null, google_id?: string | null, new_user?: boolean | null } };

export type UpdateProfileMutationVariables = Exact<{
  mobileNumberPrimary: Scalars['String']['input'];
  college: Scalars['String']['input'];
  yearOfStudy: Scalars['String']['input'];
  region: Scalars['String']['input'];
  state: Scalars['String']['input'];
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'users', user_id: string, saarang_id: string, email_primary: string, username: string, mobile_number_primary?: string | null, positions: string, DOB?: any | null, verified?: boolean | null, profile_picture?: string | null, college?: string | null, year_of_study?: string | null, region?: string | null, created_at?: any | null, isIITM?: string | null, google_id?: string | null, state?: string | null, new_user?: boolean | null } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'users', user_id: string, saarang_id: string, email_primary: string, email_secondary?: string | null, username: string, mobile_number_primary?: string | null, mobile_number_secondary?: string | null, positions: string, DOB?: any | null, verified?: boolean | null, profile_picture?: string | null, college?: string | null, college_email_id?: string | null, college_location?: string | null, points_scored?: number | null, year_of_study?: string | null, region?: string | null, state?: string | null, created_at?: any | null, updated_at?: any | null, isIITM?: string | null, sa_answer?: string | null, sa_answer2?: string | null, sa_answer3?: string | null, sa_referral?: string | null, google_id?: string | null, new_user?: boolean | null } };

export type Register_NewMutationVariables = Exact<{
  emailPrimary: Scalars['String']['input'];
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type Register_NewMutation = { __typename?: 'Mutation', register_new: { __typename?: 'users', user_id: string, saarang_id: string, email_primary: string, email_secondary?: string | null, username: string, mobile_number_primary?: string | null, mobile_number_secondary?: string | null, positions: string, DOB?: any | null, verified?: boolean | null, profile_picture?: string | null, college?: string | null, college_email_id?: string | null, college_location?: string | null, points_scored?: number | null, year_of_study?: string | null, region?: string | null, state?: string | null, created_at?: any | null, updated_at?: any | null, isIITM?: string | null, sa_answer?: string | null, sa_answer2?: string | null, sa_answer3?: string | null, sa_referral?: string | null, google_id?: string | null, new_user?: boolean | null } };

export type Check_RegMutationVariables = Exact<{
  loginHash: Scalars['String']['input'];
  emailBase64: Scalars['String']['input'];
}>;


export type Check_RegMutation = { __typename?: 'Mutation', check_reg: { __typename?: 'users', user_id: string, saarang_id: string, email_primary: string, username: string, mobile_number_primary?: string | null, verified?: boolean | null, college?: string | null, year_of_study?: string | null, region?: string | null, state?: string | null, google_id?: string | null, new_user?: boolean | null } };

export type SendVerificationEmailMutationVariables = Exact<{
  emailPrimary: Scalars['String']['input'];
}>;


export type SendVerificationEmailMutation = { __typename?: 'Mutation', sendVerificationEmail: boolean };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: number };

export type GetClubsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClubsQuery = { __typename?: 'Query', getClubs: Array<{ __typename?: 'Club', id: string, clubName: string, logo?: string | null, clubPassPrice: number, category: string, ContactNumber?: string | null, ContactName?: string | null, events?: Array<{ __typename?: 'Events', Club: { __typename?: 'Club', events?: Array<{ __typename?: 'Events', description: Array<string>, event_date: any, event_location?: string | null, id: string, image_url?: string | null, price?: number | null, registrations_closed?: boolean | null, title: string }> | null } }> | null }> };

export type CreateClubMutationVariables = Exact<{
  category: Scalars['String']['input'];
  clubPassPrice: Scalars['Float']['input'];
  clubName: Scalars['String']['input'];
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateClubMutation = { __typename?: 'Mutation', createClub: { __typename?: 'Club', id: string, clubName: string, category: string, logo?: string | null, clubPassPrice: number, ContactNumber?: string | null, ContactName?: string | null, events?: Array<{ __typename?: 'Events', event_location?: string | null, event_date: any, description: Array<string> }> | null } };

export type UpdateClubMutationVariables = Exact<{
  updateClubId: Scalars['String']['input'];
  clubName: Scalars['String']['input'];
  logo: Scalars['String']['input'];
  clubPassPrice: Scalars['Float']['input'];
  category: Scalars['String']['input'];
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateClubMutation = { __typename?: 'Mutation', updateClub: { __typename?: 'Club', id: string, clubName: string, logo?: string | null, clubPassPrice: number, category: string, ContactNumber?: string | null, ContactName?: string | null } };

export type DeleteClubMutationVariables = Exact<{
  deleteClubId: Scalars['String']['input'];
}>;


export type DeleteClubMutation = { __typename?: 'Mutation', deleteClub: boolean };

export type CreateComboPassMutationVariables = Exact<{
  clubIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  description: Array<Scalars['String']['input']> | Scalars['String']['input'];
  title: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
}>;


export type CreateComboPassMutation = { __typename?: 'Mutation', createComboPass: { __typename?: 'ComboPass', id: string, title: string, description: Array<string>, club_ids: Array<string>, price: number, image_url?: string | null, is_active?: boolean | null, valid_till?: any | null, max_registrations?: number | null, current_registrations?: number | null } };

export type DeleteComboPassMutationVariables = Exact<{
  deleteComboPassId: Scalars['String']['input'];
}>;


export type DeleteComboPassMutation = { __typename?: 'Mutation', deleteComboPass: boolean };

export type GetAllComboPassesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllComboPassesQuery = { __typename?: 'Query', getAllComboPasses: Array<{ __typename?: 'ComboPassWithClubs', comboPass: { __typename?: 'ComboPass', club_ids: Array<string>, current_registrations?: number | null, description: Array<string>, id: string, image_url?: string | null, is_active?: boolean | null, max_registrations?: number | null, price: number, title: string, valid_till?: any | null }, clubs: Array<{ __typename?: 'Club', category: string, clubName: string, clubPassPrice: number, id: string, logo?: string | null }> }> };

export type UpdateComboPassMutationVariables = Exact<{
  updateComboPassId: Scalars['String']['input'];
  description?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  clubIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  validTill?: InputMaybe<Scalars['DateTimeISO']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  maxRegistrations?: InputMaybe<Scalars['Float']['input']>;
}>;


export type UpdateComboPassMutation = { __typename?: 'Mutation', updateComboPass: { __typename?: 'ComboPass', id: string, title: string, description: Array<string>, club_ids: Array<string>, price: number, image_url?: string | null, is_active?: boolean | null, valid_till?: any | null, max_registrations?: number | null, current_registrations?: number | null } };

export type GetUserSingleEventRegistrationsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type GetUserSingleEventRegistrationsQuery = { __typename?: 'Query', getUserSingleEventRegistrations: Array<{ __typename?: 'SingleEventsRegistrationDetails', registration: { __typename?: 'SingleEventsRegistration', id: string, user_id: string, reg_date?: any | null }, user: { __typename?: 'users', username: string, user_id: string }, event: { __typename?: 'Events', description: Array<string>, id: string, title: string, event_location?: string | null, event_date: any } }> };

export type GetUserComboPassRegistrationsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type GetUserComboPassRegistrationsQuery = { __typename?: 'Query', getUserComboPassRegistrations: Array<{ __typename?: 'UserComboPassRegistrationDetails', registration: { __typename?: 'ComboPassRegistration', combo_pass_id: string, reg_date: string }, comboPass: { __typename?: 'ComboPass', image_url?: string | null, id: string, club_ids: Array<string>, price: number, description: Array<string>, is_active?: boolean | null, valid_till?: any | null, title: string }, clubs: Array<{ __typename?: 'Club', clubName: string }> }> };

export type GetUserClubPassRegistrationsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type GetUserClubPassRegistrationsQuery = { __typename?: 'Query', getUserClubPassRegistrations: Array<{ __typename?: 'ClubPassRegistrationWithDetails', registration: { __typename?: 'ClubPassRegistration', reg_date: string, id: string, user_id: string, club_id: string }, club: { __typename?: 'Club', clubName: string, events?: Array<{ __typename?: 'Events', title: string }> | null } }> };

export type GetUserEscapeRoomRegistrationsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type GetUserEscapeRoomRegistrationsQuery = { __typename?: 'Query', getUserEscapeRoomRegistrations: Array<{ __typename?: 'EscapeRoomRegistrationDetails', registration: { __typename?: 'EscapeRoomRegistration', price?: number | null, id: string, user_id: string, paid: boolean, verified: boolean }, user: { __typename?: 'users', user_id: string, mobile_number_primary?: string | null, email_primary: string, username: string }, escapeRoom: { __typename?: 'EscapeRoom', description: Array<string>, event_date: any, event_location: string, id: string, slot: string, title: string, price: number } }> };

export type GetAvailableSlotsQueryVariables = Exact<{
  escapeRoomId: Scalars['String']['input'];
}>;


export type GetAvailableSlotsQuery = { __typename?: 'Query', getAvailableSlots: Array<string> };

export type CheckInformalsClubPassStatusQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type CheckInformalsClubPassStatusQuery = { __typename?: 'Query', checkInformalsClubPassStatus: boolean };

export type GetAvailableSlotsByDateQueryVariables = Exact<{
  eventDate: Scalars['DateTimeISO']['input'];
  escapeRoomId: Scalars['String']['input'];
}>;


export type GetAvailableSlotsByDateQuery = { __typename?: 'Query', getAvailableSlotsByDate: Array<string> };

export type GetClubPassHolderRegistrationsQueryVariables = Exact<{
  escapeRoomId: Scalars['String']['input'];
}>;


export type GetClubPassHolderRegistrationsQuery = { __typename?: 'Query', getClubPassHolderRegistrations: Array<{ __typename?: 'EscapeRoomRegistrationDetails', registration: { __typename?: 'EscapeRoomRegistration', id: string, razorpay_order_id: string, reg_date?: any | null, verified: boolean }, user: { __typename?: 'users', DOB?: any | null, username: string, user_id: string, email_primary: string, mobile_number_primary?: string | null }, escapeRoom: { __typename?: 'EscapeRoom', id: string, title: string, slot: string } }> };

export type GetEscapeRoomByIdQueryVariables = Exact<{
  getEscapeRoomByIdId: Scalars['String']['input'];
}>;


export type GetEscapeRoomByIdQuery = { __typename?: 'Query', getEscapeRoomById?: { __typename?: 'EscapeRoom', id: string, title: string, description: Array<string>, image_url?: string | null, event_date: any, price: number, event_location: string, registrations_closed: boolean, slot: string } | null };

export type GetEscapeRoomByLocationQueryVariables = Exact<{
  location: Scalars['String']['input'];
}>;


export type GetEscapeRoomByLocationQuery = { __typename?: 'Query', getEscapeRoomByLocation: Array<{ __typename?: 'EscapeRoom', id: string, title: string, description: Array<string>, image_url?: string | null, event_date: any, price: number, event_location: string, registrations_closed: boolean, slot: string }> };

export type GetEscapeRoomRegistrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEscapeRoomRegistrationsQuery = { __typename?: 'Query', getEscapeRoomRegistrations: Array<{ __typename?: 'EscapeRoomRegistrationDetails', registration: { __typename?: 'EscapeRoomRegistration', id: string, price?: number | null, razorpay_order_id: string, user_id: string, reg_date?: any | null, verified: boolean }, user: { __typename?: 'users', email_primary: string, mobile_number_primary?: string | null, username: string, user_id: string }, escapeRoom: { __typename?: 'EscapeRoom', description: Array<string>, event_date: any, event_location: string, id: string, price: number, slot: string, registrations_closed: boolean, title: string } }> };

export type GetEscapeRoomsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEscapeRoomsQuery = { __typename?: 'Query', getEscapeRooms: Array<{ __typename?: 'EscapeRoom', id: string, title: string, description: Array<string>, image_url?: string | null, event_date: any, price: number, event_location: string, registrations_closed: boolean, slot: string }> };

export type GetRegistrationsBySlotQueryVariables = Exact<{
  escapeRoomId: Scalars['String']['input'];
  slot: Scalars['String']['input'];
}>;


export type GetRegistrationsBySlotQuery = { __typename?: 'Query', getRegistrationsBySlot: Array<{ __typename?: 'EscapeRoomRegistrationDetails', registration: { __typename?: 'EscapeRoomRegistration', id: string, paid: boolean, escape_room_id: string, razorpay_order_id: string, reg_date?: any | null, slot: string }, user: { __typename?: 'users', email_primary: string, mobile_number_primary?: string | null, user_id: string, username: string }, escapeRoom: { __typename?: 'EscapeRoom', title: string, slot: string, registrations_closed: boolean, id: string, event_location: string, event_date: any, description: Array<string> } }> };

export type GetSlotAvailabilityByDateQueryVariables = Exact<{
  eventDate: Scalars['DateTimeISO']['input'];
  escapeRoomId: Scalars['String']['input'];
}>;


export type GetSlotAvailabilityByDateQuery = { __typename?: 'Query', getSlotAvailabilityByDate: Array<Array<string>> };

export type CreateAllSlotEscapeRoomSameTitleDescriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateAllSlotEscapeRoomSameTitleDescriptionMutation = { __typename?: 'Mutation', createAllSlotEscapeRoomSameTitleDescription: boolean };

export type CreateEscapeRoomMutationVariables = Exact<{
  title: Scalars['String']['input'];
  description: Array<Scalars['String']['input']> | Scalars['String']['input'];
  eventDate: Scalars['DateTimeISO']['input'];
  price: Scalars['Float']['input'];
  eventLocation: Scalars['String']['input'];
  slot: Scalars['String']['input'];
  registrationsClosed: Scalars['Boolean']['input'];
}>;


export type CreateEscapeRoomMutation = { __typename?: 'Mutation', createEscapeRoom: { __typename?: 'EscapeRoom', id: string, title: string, description: Array<string>, image_url?: string | null, event_date: any, price: number, event_location: string, registrations_closed: boolean, slot: string } };

export type CreateEscapeRoomRegistrationMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  escapeRoomId: Scalars['String']['input'];
  slot: Scalars['String']['input'];
}>;


export type CreateEscapeRoomRegistrationMutation = { __typename?: 'Mutation', createEscapeRoomRegistration: { __typename?: 'EscapeRoomRegistration', id: string, user_id: string, escape_room_id: string, slot: string, reg_date?: any | null, price?: number | null, razorpay_order_id: string, verified: boolean, paid: boolean } };

export type DeleteEscapeRoomMutationVariables = Exact<{
  deleteEscapeRoomId: Scalars['String']['input'];
}>;


export type DeleteEscapeRoomMutation = { __typename?: 'Mutation', deleteEscapeRoom: boolean };

export type UpdateEscapeRoomMutationVariables = Exact<{
  title: Scalars['String']['input'];
  description: Array<Scalars['String']['input']> | Scalars['String']['input'];
  eventDate: Scalars['DateTimeISO']['input'];
  price: Scalars['Float']['input'];
  eventLocation: Scalars['String']['input'];
  slot: Scalars['String']['input'];
  registrationsClosed: Scalars['Boolean']['input'];
  updateEscapeRoomId: Scalars['String']['input'];
}>;


export type UpdateEscapeRoomMutation = { __typename?: 'Mutation', updateEscapeRoom: { __typename?: 'EscapeRoom', id: string, title: string, description: Array<string>, image_url?: string | null, event_date: any, price: number, event_location: string, registrations_closed: boolean, slot: string } };

export type GetEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventsQuery = { __typename?: 'Query', getEvents: Array<{ __typename?: 'Events', id: string, title: string, description: Array<string>, image_url?: string | null, event_date: any, price?: number | null, event_location?: string | null, registrations_closed?: boolean | null, Club: { __typename?: 'Club', id: string, clubPassPrice: number, clubName: string, logo?: string | null, category: string } }> };

export type GetEventByClubQueryVariables = Exact<{
  clubId: Scalars['String']['input'];
}>;


export type GetEventByClubQuery = { __typename?: 'Query', getEventByClub: Array<{ __typename?: 'Events', id: string, title: string, description: Array<string>, image_url?: string | null, event_date: any, price?: number | null, event_location?: string | null, registrations_closed?: boolean | null, Club: { __typename?: 'Club', clubName: string, clubPassPrice: number, id: string, logo?: string | null, category: string } }> };

export type GetEventsByIdQueryVariables = Exact<{
  getEventsByIdId: Scalars['String']['input'];
}>;


export type GetEventsByIdQuery = { __typename?: 'Query', getEventsById?: { __typename?: 'Events', id: string, title: string, description: Array<string>, image_url?: string | null, event_date: any, price?: number | null, event_location?: string | null, registrations_closed?: boolean | null, Club: { __typename?: 'Club', id: string, clubPassPrice: number, clubName: string, logo?: string | null, category: string } } | null };

export type GetEventBylocationQueryVariables = Exact<{
  location: Scalars['String']['input'];
}>;


export type GetEventBylocationQuery = { __typename?: 'Query', getEventBylocation: Array<{ __typename?: 'Events', id: string, title: string, description: Array<string>, image_url?: string | null, event_date: any, price?: number | null, event_location?: string | null, registrations_closed?: boolean | null, Club: { __typename?: 'Club', id: string, clubPassPrice: number, clubName: string, logo?: string | null, category: string } }> };

export type CreateEventsMutationVariables = Exact<{
  title: Scalars['String']['input'];
  description: Array<Scalars['String']['input']> | Scalars['String']['input'];
  eventDate: Scalars['DateTimeISO']['input'];
  price: Scalars['Float']['input'];
  eventLocation: Scalars['String']['input'];
  clubId: Scalars['String']['input'];
  imageUrl: Scalars['String']['input'];
  registrationsClosed: Scalars['Boolean']['input'];
}>;


export type CreateEventsMutation = { __typename?: 'Mutation', createEvents: { __typename?: 'Events', id: string, title: string, description: Array<string>, image_url?: string | null, event_date: any, price?: number | null, event_location?: string | null, registrations_closed?: boolean | null, Club: { __typename?: 'Club', id: string, clubPassPrice: number, clubName: string, logo?: string | null, category: string } } };

export type UpdateEventsMutationVariables = Exact<{
  title: Scalars['String']['input'];
  description: Array<Scalars['String']['input']> | Scalars['String']['input'];
  eventDate: Scalars['DateTimeISO']['input'];
  price: Scalars['Float']['input'];
  eventLocation: Scalars['String']['input'];
  clubId: Scalars['String']['input'];
  imageUrl: Scalars['String']['input'];
  registrationsClosed: Scalars['Boolean']['input'];
  updateEventsId: Scalars['String']['input'];
}>;


export type UpdateEventsMutation = { __typename?: 'Mutation', updateEvents?: { __typename?: 'Events', id: string, title: string, description: Array<string>, image_url?: string | null, event_date: any, price?: number | null, event_location?: string | null, registrations_closed?: boolean | null, Club: { __typename?: 'Club', clubName: string, clubPassPrice: number, id: string, logo?: string | null, category: string } } | null };

export type DeleteEventsMutationVariables = Exact<{
  deleteEventsId: Scalars['String']['input'];
}>;


export type DeleteEventsMutation = { __typename?: 'Mutation', deleteEvents: boolean };

export type CreateSingleEventRegistrationMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  eventsId: Scalars['String']['input'];
}>;


export type CreateSingleEventRegistrationMutation = { __typename?: 'Mutation', createSingleEventRegistration: { __typename?: 'SingleEventsRegistration', id: string, user_id: string, events_id: string, reg_date?: any | null, razorpay_order_id: string, verified: boolean, paid: boolean } };

export type CreateDirectEventRegistrationMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  eventsId: Scalars['String']['input'];
}>;


export type CreateDirectEventRegistrationMutation = { __typename?: 'Mutation', createDirectEventRegistration: { __typename?: 'SingleEventsRegistration', id: string, user_id: string, events_id: string, reg_date?: any | null, razorpay_order_id: string, verified: boolean } };

export type GetAllEventRegistrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllEventRegistrationsQuery = { __typename?: 'Query', getAllEventRegistrations: Array<{ __typename?: 'SingleEventsRegistrationDetails', registration: { __typename?: 'SingleEventsRegistration', id: string, paid: boolean, price?: number | null, reg_date?: any | null, verified: boolean, razorpay_order_id: string }, user: { __typename?: 'users', isIITM?: string | null, username: string, user_id: string, mobile_number_primary?: string | null, email_primary: string }, event: { __typename?: 'Events', description: Array<string>, title: string, id: string } }> };

export type GeEventsRegistrationsByEventIdQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;


export type GeEventsRegistrationsByEventIdQuery = { __typename?: 'Query', geEventsRegistrationsByEventId: Array<{ __typename?: 'SingleEventsRegistrationDetails', registration: { __typename?: 'SingleEventsRegistration', events_id: string, id: string, user_id: string, verified: boolean, reg_date?: any | null, razorpay_order_id: string, paid: boolean }, user: { __typename?: 'users', user_id: string, email_primary: string, isIITM?: string | null, username: string, mobile_number_primary?: string | null }, event: { __typename?: 'Events', description: Array<string>, event_date: any, event_location?: string | null, id: string, image_url?: string | null, price?: number | null, registrations_closed?: boolean | null, title: string } }> };

export type GetAllComboPassRegistrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllComboPassRegistrationsQuery = { __typename?: 'Query', getAllComboPassRegistrations: Array<{ __typename?: 'ComboPassRegistrationDetails', registration: { __typename?: 'ComboPassRegistration', reg_date: string, razorpay_order_id: string, payment_id?: string | null, user_id: string }, comboPass: { __typename?: 'ComboPass', id: string, title: string, current_registrations?: number | null, club_ids: Array<string>, description: Array<string> }, clubs: Array<{ __typename?: 'Club', clubName: string, id: string, category: string }>, user: { __typename?: 'users', username: string, mobile_number_primary?: string | null, email_primary: string } }> };

export type GetClubPassRegistrationsQueryVariables = Exact<{
  clubId: Scalars['String']['input'];
}>;


export type GetClubPassRegistrationsQuery = { __typename?: 'Query', getClubPassRegistrations: Array<{ __typename?: 'ClubPassRegistrationWithDetailsWithUser', registration: { __typename?: 'ClubPassRegistration', paid: boolean, razorpay_order_id: string, reg_date: string, user_id: string, verified: boolean, price?: number | null, id: string }, club: { __typename?: 'Club', clubName: string, clubPassPrice: number, category: string, id: string }, user: { __typename?: 'users', username: string, user_id: string, email_primary: string, mobile_number_primary?: string | null } }> };

export type GetComboPassQueryVariables = Exact<{
  getComboPassId: Scalars['String']['input'];
}>;


export type GetComboPassQuery = { __typename?: 'Query', getComboPass: { __typename?: 'ComboPassWithClubs', comboPass: { __typename?: 'ComboPass', club_ids: Array<string>, current_registrations?: number | null, description: Array<string>, id: string, image_url?: string | null, is_active?: boolean | null, max_registrations?: number | null, price: number, title: string, valid_till?: any | null }, clubs: Array<{ __typename?: 'Club', clubName: string, id: string }> } };

export type CreateDirectClubPassRegistrationMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  clubId: Scalars['String']['input'];
}>;


export type CreateDirectClubPassRegistrationMutation = { __typename?: 'Mutation', createDirectClubPassRegistration: { __typename?: 'ClubPassRegistration', id: string, club_id: string, user_id: string, razorpay_order_id: string, verified: boolean, reg_date: string, registered_event_ids?: Array<string> | null } };

export type CreateClubPassRegistrationMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  clubId: Scalars['String']['input'];
}>;


export type CreateClubPassRegistrationMutation = { __typename?: 'Mutation', createClubPassRegistration: { __typename?: 'ClubPassRegistration', id: string, club_id: string, user_id: string, razorpay_order_id: string, verified: boolean, reg_date: string, registered_event_ids?: Array<string> | null } };

export type CreateComboPassRegistrationMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  comboPassId: Scalars['String']['input'];
}>;


export type CreateComboPassRegistrationMutation = { __typename?: 'Mutation', createComboPassRegistration: { __typename?: 'ComboPassRegistration', id: string, combo_pass_id: string, user_id: string, razorpay_order_id: string, payment_id?: string | null, verified: boolean, reg_date: string } };

export type CreateDirectComboPassRegistrationMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  comboPassId: Scalars['String']['input'];
}>;


export type CreateDirectComboPassRegistrationMutation = { __typename?: 'Mutation', createDirectComboPassRegistration: { __typename?: 'ComboPassRegistration', id: string, combo_pass_id: string, user_id: string, razorpay_order_id: string, payment_id?: string | null, verified: boolean, reg_date: string } };


export const GetMeDocument = gql`
    query GetMe {
  getMe {
    user_id
    saarang_id
    email_primary
    email_secondary
    username
    mobile_number_primary
    mobile_number_secondary
    positions
    DOB
    verified
    profile_picture
    college
    college_email_id
    college_location
    points_scored
    year_of_study
    region
    state
    created_at
    updated_at
    isIITM
    sa_answer
    sa_answer2
    sa_answer3
    sa_referral
    google_id
    new_user
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export function useGetMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeSuspenseQueryHookResult = ReturnType<typeof useGetMeSuspenseQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GoogleSignInDocument = gql`
    mutation GoogleSignIn($uid: String!, $email: String!, $displayName: String!, $photoUrl: String!) {
  googleSignIn(
    uid: $uid
    email: $email
    displayName: $displayName
    photoURL: $photoUrl
  ) {
    user_id
    saarang_id
    email_primary
    email_secondary
    username
    mobile_number_primary
    mobile_number_secondary
    positions
    DOB
    verified
    profile_picture
    college
    college_email_id
    college_location
    points_scored
    year_of_study
    region
    state
    isIITM
    google_id
    new_user
  }
}
    `;
export type GoogleSignInMutationFn = Apollo.MutationFunction<GoogleSignInMutation, GoogleSignInMutationVariables>;

/**
 * __useGoogleSignInMutation__
 *
 * To run a mutation, you first call `useGoogleSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleSignInMutation, { data, loading, error }] = useGoogleSignInMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      email: // value for 'email'
 *      displayName: // value for 'displayName'
 *      photoUrl: // value for 'photoUrl'
 *   },
 * });
 */
export function useGoogleSignInMutation(baseOptions?: Apollo.MutationHookOptions<GoogleSignInMutation, GoogleSignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleSignInMutation, GoogleSignInMutationVariables>(GoogleSignInDocument, options);
      }
export type GoogleSignInMutationHookResult = ReturnType<typeof useGoogleSignInMutation>;
export type GoogleSignInMutationResult = Apollo.MutationResult<GoogleSignInMutation>;
export type GoogleSignInMutationOptions = Apollo.BaseMutationOptions<GoogleSignInMutation, GoogleSignInMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($mobileNumberPrimary: String!, $college: String!, $yearOfStudy: String!, $region: String!, $state: String!) {
  updateProfile(
    mobile_number_primary: $mobileNumberPrimary
    college: $college
    year_of_study: $yearOfStudy
    region: $region
    state: $state
  ) {
    user_id
    saarang_id
    email_primary
    username
    mobile_number_primary
    positions
    DOB
    verified
    profile_picture
    college
    year_of_study
    region
    created_at
    isIITM
    google_id
    state
    new_user
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      mobileNumberPrimary: // value for 'mobileNumberPrimary'
 *      college: // value for 'college'
 *      yearOfStudy: // value for 'yearOfStudy'
 *      region: // value for 'region'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    user_id
    saarang_id
    email_primary
    email_secondary
    username
    mobile_number_primary
    mobile_number_secondary
    positions
    DOB
    verified
    profile_picture
    college
    college_email_id
    college_location
    points_scored
    year_of_study
    region
    state
    created_at
    updated_at
    isIITM
    sa_answer
    sa_answer2
    sa_answer3
    sa_referral
    google_id
    new_user
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const Register_NewDocument = gql`
    mutation Register_new($emailPrimary: String!, $username: String!, $password: String!) {
  register_new(
    email_primary: $emailPrimary
    username: $username
    password: $password
  ) {
    user_id
    saarang_id
    email_primary
    email_secondary
    username
    mobile_number_primary
    mobile_number_secondary
    positions
    DOB
    verified
    profile_picture
    college
    college_email_id
    college_location
    points_scored
    year_of_study
    region
    state
    created_at
    updated_at
    isIITM
    sa_answer
    sa_answer2
    sa_answer3
    sa_referral
    google_id
    new_user
  }
}
    `;
export type Register_NewMutationFn = Apollo.MutationFunction<Register_NewMutation, Register_NewMutationVariables>;

/**
 * __useRegister_NewMutation__
 *
 * To run a mutation, you first call `useRegister_NewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegister_NewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerNewMutation, { data, loading, error }] = useRegister_NewMutation({
 *   variables: {
 *      emailPrimary: // value for 'emailPrimary'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegister_NewMutation(baseOptions?: Apollo.MutationHookOptions<Register_NewMutation, Register_NewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Register_NewMutation, Register_NewMutationVariables>(Register_NewDocument, options);
      }
export type Register_NewMutationHookResult = ReturnType<typeof useRegister_NewMutation>;
export type Register_NewMutationResult = Apollo.MutationResult<Register_NewMutation>;
export type Register_NewMutationOptions = Apollo.BaseMutationOptions<Register_NewMutation, Register_NewMutationVariables>;
export const Check_RegDocument = gql`
    mutation Check_reg($loginHash: String!, $emailBase64: String!) {
  check_reg(login_hash: $loginHash, email_base64: $emailBase64) {
    user_id
    saarang_id
    email_primary
    username
    mobile_number_primary
    verified
    college
    year_of_study
    region
    state
    google_id
    new_user
  }
}
    `;
export type Check_RegMutationFn = Apollo.MutationFunction<Check_RegMutation, Check_RegMutationVariables>;

/**
 * __useCheck_RegMutation__
 *
 * To run a mutation, you first call `useCheck_RegMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheck_RegMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkRegMutation, { data, loading, error }] = useCheck_RegMutation({
 *   variables: {
 *      loginHash: // value for 'loginHash'
 *      emailBase64: // value for 'emailBase64'
 *   },
 * });
 */
export function useCheck_RegMutation(baseOptions?: Apollo.MutationHookOptions<Check_RegMutation, Check_RegMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Check_RegMutation, Check_RegMutationVariables>(Check_RegDocument, options);
      }
export type Check_RegMutationHookResult = ReturnType<typeof useCheck_RegMutation>;
export type Check_RegMutationResult = Apollo.MutationResult<Check_RegMutation>;
export type Check_RegMutationOptions = Apollo.BaseMutationOptions<Check_RegMutation, Check_RegMutationVariables>;
export const SendVerificationEmailDocument = gql`
    mutation SendVerificationEmail($emailPrimary: String!) {
  sendVerificationEmail(email_primary: $emailPrimary)
}
    `;
export type SendVerificationEmailMutationFn = Apollo.MutationFunction<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>;

/**
 * __useSendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useSendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationEmailMutation, { data, loading, error }] = useSendVerificationEmailMutation({
 *   variables: {
 *      emailPrimary: // value for 'emailPrimary'
 *   },
 * });
 */
export function useSendVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>(SendVerificationEmailDocument, options);
      }
export type SendVerificationEmailMutationHookResult = ReturnType<typeof useSendVerificationEmailMutation>;
export type SendVerificationEmailMutationResult = Apollo.MutationResult<SendVerificationEmailMutation>;
export type SendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const GetClubsDocument = gql`
    query GetClubs {
  getClubs {
    id
    clubName
    logo
    clubPassPrice
    category
    ContactNumber
    ContactName
    events {
      Club {
        events {
          description
          event_date
          event_location
          id
          image_url
          price
          registrations_closed
          title
        }
      }
    }
  }
}
    `;

/**
 * __useGetClubsQuery__
 *
 * To run a query within a React component, call `useGetClubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClubsQuery(baseOptions?: Apollo.QueryHookOptions<GetClubsQuery, GetClubsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClubsQuery, GetClubsQueryVariables>(GetClubsDocument, options);
      }
export function useGetClubsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClubsQuery, GetClubsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClubsQuery, GetClubsQueryVariables>(GetClubsDocument, options);
        }
export function useGetClubsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetClubsQuery, GetClubsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClubsQuery, GetClubsQueryVariables>(GetClubsDocument, options);
        }
export type GetClubsQueryHookResult = ReturnType<typeof useGetClubsQuery>;
export type GetClubsLazyQueryHookResult = ReturnType<typeof useGetClubsLazyQuery>;
export type GetClubsSuspenseQueryHookResult = ReturnType<typeof useGetClubsSuspenseQuery>;
export type GetClubsQueryResult = Apollo.QueryResult<GetClubsQuery, GetClubsQueryVariables>;
export const CreateClubDocument = gql`
    mutation CreateClub($category: String!, $clubPassPrice: Float!, $clubName: String!, $contactName: String, $contactNumber: String, $logo: String) {
  createClub(
    category: $category
    clubPassPrice: $clubPassPrice
    clubName: $clubName
    ContactName: $contactName
    ContactNumber: $contactNumber
    logo: $logo
  ) {
    id
    clubName
    category
    logo
    clubPassPrice
    ContactNumber
    ContactName
    events {
      event_location
      event_date
      description
    }
  }
}
    `;
export type CreateClubMutationFn = Apollo.MutationFunction<CreateClubMutation, CreateClubMutationVariables>;

/**
 * __useCreateClubMutation__
 *
 * To run a mutation, you first call `useCreateClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClubMutation, { data, loading, error }] = useCreateClubMutation({
 *   variables: {
 *      category: // value for 'category'
 *      clubPassPrice: // value for 'clubPassPrice'
 *      clubName: // value for 'clubName'
 *      contactName: // value for 'contactName'
 *      contactNumber: // value for 'contactNumber'
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useCreateClubMutation(baseOptions?: Apollo.MutationHookOptions<CreateClubMutation, CreateClubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClubMutation, CreateClubMutationVariables>(CreateClubDocument, options);
      }
export type CreateClubMutationHookResult = ReturnType<typeof useCreateClubMutation>;
export type CreateClubMutationResult = Apollo.MutationResult<CreateClubMutation>;
export type CreateClubMutationOptions = Apollo.BaseMutationOptions<CreateClubMutation, CreateClubMutationVariables>;
export const UpdateClubDocument = gql`
    mutation UpdateClub($updateClubId: String!, $clubName: String!, $logo: String!, $clubPassPrice: Float!, $category: String!, $contactNumber: String, $contactName: String) {
  updateClub(
    id: $updateClubId
    clubName: $clubName
    logo: $logo
    clubPassPrice: $clubPassPrice
    category: $category
    ContactNumber: $contactNumber
    ContactName: $contactName
  ) {
    id
    clubName
    logo
    clubPassPrice
    category
    ContactNumber
    ContactName
  }
}
    `;
export type UpdateClubMutationFn = Apollo.MutationFunction<UpdateClubMutation, UpdateClubMutationVariables>;

/**
 * __useUpdateClubMutation__
 *
 * To run a mutation, you first call `useUpdateClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClubMutation, { data, loading, error }] = useUpdateClubMutation({
 *   variables: {
 *      updateClubId: // value for 'updateClubId'
 *      clubName: // value for 'clubName'
 *      logo: // value for 'logo'
 *      clubPassPrice: // value for 'clubPassPrice'
 *      category: // value for 'category'
 *      contactNumber: // value for 'contactNumber'
 *      contactName: // value for 'contactName'
 *   },
 * });
 */
export function useUpdateClubMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClubMutation, UpdateClubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClubMutation, UpdateClubMutationVariables>(UpdateClubDocument, options);
      }
export type UpdateClubMutationHookResult = ReturnType<typeof useUpdateClubMutation>;
export type UpdateClubMutationResult = Apollo.MutationResult<UpdateClubMutation>;
export type UpdateClubMutationOptions = Apollo.BaseMutationOptions<UpdateClubMutation, UpdateClubMutationVariables>;
export const DeleteClubDocument = gql`
    mutation DeleteClub($deleteClubId: String!) {
  deleteClub(id: $deleteClubId)
}
    `;
export type DeleteClubMutationFn = Apollo.MutationFunction<DeleteClubMutation, DeleteClubMutationVariables>;

/**
 * __useDeleteClubMutation__
 *
 * To run a mutation, you first call `useDeleteClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClubMutation, { data, loading, error }] = useDeleteClubMutation({
 *   variables: {
 *      deleteClubId: // value for 'deleteClubId'
 *   },
 * });
 */
export function useDeleteClubMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClubMutation, DeleteClubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClubMutation, DeleteClubMutationVariables>(DeleteClubDocument, options);
      }
export type DeleteClubMutationHookResult = ReturnType<typeof useDeleteClubMutation>;
export type DeleteClubMutationResult = Apollo.MutationResult<DeleteClubMutation>;
export type DeleteClubMutationOptions = Apollo.BaseMutationOptions<DeleteClubMutation, DeleteClubMutationVariables>;
export const CreateComboPassDocument = gql`
    mutation CreateComboPass($clubIds: [String!]!, $description: [String!]!, $title: String!, $imageUrl: String, $price: Float) {
  createComboPass(
    club_ids: $clubIds
    description: $description
    title: $title
    image_url: $imageUrl
    price: $price
  ) {
    id
    title
    description
    club_ids
    price
    image_url
    is_active
    valid_till
    max_registrations
    current_registrations
  }
}
    `;
export type CreateComboPassMutationFn = Apollo.MutationFunction<CreateComboPassMutation, CreateComboPassMutationVariables>;

/**
 * __useCreateComboPassMutation__
 *
 * To run a mutation, you first call `useCreateComboPassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateComboPassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createComboPassMutation, { data, loading, error }] = useCreateComboPassMutation({
 *   variables: {
 *      clubIds: // value for 'clubIds'
 *      description: // value for 'description'
 *      title: // value for 'title'
 *      imageUrl: // value for 'imageUrl'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useCreateComboPassMutation(baseOptions?: Apollo.MutationHookOptions<CreateComboPassMutation, CreateComboPassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateComboPassMutation, CreateComboPassMutationVariables>(CreateComboPassDocument, options);
      }
export type CreateComboPassMutationHookResult = ReturnType<typeof useCreateComboPassMutation>;
export type CreateComboPassMutationResult = Apollo.MutationResult<CreateComboPassMutation>;
export type CreateComboPassMutationOptions = Apollo.BaseMutationOptions<CreateComboPassMutation, CreateComboPassMutationVariables>;
export const DeleteComboPassDocument = gql`
    mutation DeleteComboPass($deleteComboPassId: String!) {
  deleteComboPass(id: $deleteComboPassId)
}
    `;
export type DeleteComboPassMutationFn = Apollo.MutationFunction<DeleteComboPassMutation, DeleteComboPassMutationVariables>;

/**
 * __useDeleteComboPassMutation__
 *
 * To run a mutation, you first call `useDeleteComboPassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteComboPassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteComboPassMutation, { data, loading, error }] = useDeleteComboPassMutation({
 *   variables: {
 *      deleteComboPassId: // value for 'deleteComboPassId'
 *   },
 * });
 */
export function useDeleteComboPassMutation(baseOptions?: Apollo.MutationHookOptions<DeleteComboPassMutation, DeleteComboPassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteComboPassMutation, DeleteComboPassMutationVariables>(DeleteComboPassDocument, options);
      }
export type DeleteComboPassMutationHookResult = ReturnType<typeof useDeleteComboPassMutation>;
export type DeleteComboPassMutationResult = Apollo.MutationResult<DeleteComboPassMutation>;
export type DeleteComboPassMutationOptions = Apollo.BaseMutationOptions<DeleteComboPassMutation, DeleteComboPassMutationVariables>;
export const GetAllComboPassesDocument = gql`
    query GetAllComboPasses {
  getAllComboPasses {
    comboPass {
      club_ids
      current_registrations
      description
      id
      image_url
      is_active
      max_registrations
      price
      title
      valid_till
    }
    clubs {
      category
      clubName
      clubPassPrice
      id
      logo
    }
  }
}
    `;

/**
 * __useGetAllComboPassesQuery__
 *
 * To run a query within a React component, call `useGetAllComboPassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllComboPassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllComboPassesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllComboPassesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllComboPassesQuery, GetAllComboPassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllComboPassesQuery, GetAllComboPassesQueryVariables>(GetAllComboPassesDocument, options);
      }
export function useGetAllComboPassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllComboPassesQuery, GetAllComboPassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllComboPassesQuery, GetAllComboPassesQueryVariables>(GetAllComboPassesDocument, options);
        }
export function useGetAllComboPassesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllComboPassesQuery, GetAllComboPassesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllComboPassesQuery, GetAllComboPassesQueryVariables>(GetAllComboPassesDocument, options);
        }
export type GetAllComboPassesQueryHookResult = ReturnType<typeof useGetAllComboPassesQuery>;
export type GetAllComboPassesLazyQueryHookResult = ReturnType<typeof useGetAllComboPassesLazyQuery>;
export type GetAllComboPassesSuspenseQueryHookResult = ReturnType<typeof useGetAllComboPassesSuspenseQuery>;
export type GetAllComboPassesQueryResult = Apollo.QueryResult<GetAllComboPassesQuery, GetAllComboPassesQueryVariables>;
export const UpdateComboPassDocument = gql`
    mutation UpdateComboPass($updateComboPassId: String!, $description: [String!], $title: String, $clubIds: [String!], $price: Float, $imageUrl: String, $validTill: DateTimeISO, $isActive: Boolean, $maxRegistrations: Float) {
  updateComboPass(
    id: $updateComboPassId
    description: $description
    title: $title
    club_ids: $clubIds
    price: $price
    image_url: $imageUrl
    valid_till: $validTill
    is_active: $isActive
    max_registrations: $maxRegistrations
  ) {
    id
    title
    description
    club_ids
    price
    image_url
    is_active
    valid_till
    max_registrations
    current_registrations
  }
}
    `;
export type UpdateComboPassMutationFn = Apollo.MutationFunction<UpdateComboPassMutation, UpdateComboPassMutationVariables>;

/**
 * __useUpdateComboPassMutation__
 *
 * To run a mutation, you first call `useUpdateComboPassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateComboPassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateComboPassMutation, { data, loading, error }] = useUpdateComboPassMutation({
 *   variables: {
 *      updateComboPassId: // value for 'updateComboPassId'
 *      description: // value for 'description'
 *      title: // value for 'title'
 *      clubIds: // value for 'clubIds'
 *      price: // value for 'price'
 *      imageUrl: // value for 'imageUrl'
 *      validTill: // value for 'validTill'
 *      isActive: // value for 'isActive'
 *      maxRegistrations: // value for 'maxRegistrations'
 *   },
 * });
 */
export function useUpdateComboPassMutation(baseOptions?: Apollo.MutationHookOptions<UpdateComboPassMutation, UpdateComboPassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateComboPassMutation, UpdateComboPassMutationVariables>(UpdateComboPassDocument, options);
      }
export type UpdateComboPassMutationHookResult = ReturnType<typeof useUpdateComboPassMutation>;
export type UpdateComboPassMutationResult = Apollo.MutationResult<UpdateComboPassMutation>;
export type UpdateComboPassMutationOptions = Apollo.BaseMutationOptions<UpdateComboPassMutation, UpdateComboPassMutationVariables>;
export const GetUserSingleEventRegistrationsDocument = gql`
    query GetUserSingleEventRegistrations($userId: String!) {
  getUserSingleEventRegistrations(userId: $userId) {
    registration {
      id
      user_id
      reg_date
    }
    user {
      username
      user_id
    }
    event {
      description
      id
      title
      event_location
      event_date
    }
  }
}
    `;

/**
 * __useGetUserSingleEventRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetUserSingleEventRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSingleEventRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSingleEventRegistrationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserSingleEventRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<GetUserSingleEventRegistrationsQuery, GetUserSingleEventRegistrationsQueryVariables> & ({ variables: GetUserSingleEventRegistrationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSingleEventRegistrationsQuery, GetUserSingleEventRegistrationsQueryVariables>(GetUserSingleEventRegistrationsDocument, options);
      }
export function useGetUserSingleEventRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSingleEventRegistrationsQuery, GetUserSingleEventRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSingleEventRegistrationsQuery, GetUserSingleEventRegistrationsQueryVariables>(GetUserSingleEventRegistrationsDocument, options);
        }
export function useGetUserSingleEventRegistrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserSingleEventRegistrationsQuery, GetUserSingleEventRegistrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserSingleEventRegistrationsQuery, GetUserSingleEventRegistrationsQueryVariables>(GetUserSingleEventRegistrationsDocument, options);
        }
export type GetUserSingleEventRegistrationsQueryHookResult = ReturnType<typeof useGetUserSingleEventRegistrationsQuery>;
export type GetUserSingleEventRegistrationsLazyQueryHookResult = ReturnType<typeof useGetUserSingleEventRegistrationsLazyQuery>;
export type GetUserSingleEventRegistrationsSuspenseQueryHookResult = ReturnType<typeof useGetUserSingleEventRegistrationsSuspenseQuery>;
export type GetUserSingleEventRegistrationsQueryResult = Apollo.QueryResult<GetUserSingleEventRegistrationsQuery, GetUserSingleEventRegistrationsQueryVariables>;
export const GetUserComboPassRegistrationsDocument = gql`
    query GetUserComboPassRegistrations($userId: String!) {
  getUserComboPassRegistrations(userId: $userId) {
    registration {
      combo_pass_id
      reg_date
    }
    comboPass {
      image_url
      id
      club_ids
      price
      description
      is_active
      valid_till
      title
    }
    clubs {
      clubName
    }
  }
}
    `;

/**
 * __useGetUserComboPassRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetUserComboPassRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserComboPassRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserComboPassRegistrationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserComboPassRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<GetUserComboPassRegistrationsQuery, GetUserComboPassRegistrationsQueryVariables> & ({ variables: GetUserComboPassRegistrationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserComboPassRegistrationsQuery, GetUserComboPassRegistrationsQueryVariables>(GetUserComboPassRegistrationsDocument, options);
      }
export function useGetUserComboPassRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserComboPassRegistrationsQuery, GetUserComboPassRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserComboPassRegistrationsQuery, GetUserComboPassRegistrationsQueryVariables>(GetUserComboPassRegistrationsDocument, options);
        }
export function useGetUserComboPassRegistrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserComboPassRegistrationsQuery, GetUserComboPassRegistrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserComboPassRegistrationsQuery, GetUserComboPassRegistrationsQueryVariables>(GetUserComboPassRegistrationsDocument, options);
        }
export type GetUserComboPassRegistrationsQueryHookResult = ReturnType<typeof useGetUserComboPassRegistrationsQuery>;
export type GetUserComboPassRegistrationsLazyQueryHookResult = ReturnType<typeof useGetUserComboPassRegistrationsLazyQuery>;
export type GetUserComboPassRegistrationsSuspenseQueryHookResult = ReturnType<typeof useGetUserComboPassRegistrationsSuspenseQuery>;
export type GetUserComboPassRegistrationsQueryResult = Apollo.QueryResult<GetUserComboPassRegistrationsQuery, GetUserComboPassRegistrationsQueryVariables>;
export const GetUserClubPassRegistrationsDocument = gql`
    query GetUserClubPassRegistrations($userId: String!) {
  getUserClubPassRegistrations(userId: $userId) {
    registration {
      reg_date
      id
      user_id
      club_id
    }
    club {
      clubName
      events {
        title
      }
    }
  }
}
    `;

/**
 * __useGetUserClubPassRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetUserClubPassRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserClubPassRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserClubPassRegistrationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserClubPassRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<GetUserClubPassRegistrationsQuery, GetUserClubPassRegistrationsQueryVariables> & ({ variables: GetUserClubPassRegistrationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserClubPassRegistrationsQuery, GetUserClubPassRegistrationsQueryVariables>(GetUserClubPassRegistrationsDocument, options);
      }
export function useGetUserClubPassRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserClubPassRegistrationsQuery, GetUserClubPassRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserClubPassRegistrationsQuery, GetUserClubPassRegistrationsQueryVariables>(GetUserClubPassRegistrationsDocument, options);
        }
export function useGetUserClubPassRegistrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserClubPassRegistrationsQuery, GetUserClubPassRegistrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserClubPassRegistrationsQuery, GetUserClubPassRegistrationsQueryVariables>(GetUserClubPassRegistrationsDocument, options);
        }
export type GetUserClubPassRegistrationsQueryHookResult = ReturnType<typeof useGetUserClubPassRegistrationsQuery>;
export type GetUserClubPassRegistrationsLazyQueryHookResult = ReturnType<typeof useGetUserClubPassRegistrationsLazyQuery>;
export type GetUserClubPassRegistrationsSuspenseQueryHookResult = ReturnType<typeof useGetUserClubPassRegistrationsSuspenseQuery>;
export type GetUserClubPassRegistrationsQueryResult = Apollo.QueryResult<GetUserClubPassRegistrationsQuery, GetUserClubPassRegistrationsQueryVariables>;
export const GetUserEscapeRoomRegistrationsDocument = gql`
    query GetUserEscapeRoomRegistrations($userId: String!) {
  getUserEscapeRoomRegistrations(user_id: $userId) {
    registration {
      price
      id
      user_id
      paid
      verified
    }
    user {
      user_id
      mobile_number_primary
      email_primary
      username
    }
    escapeRoom {
      description
      event_date
      event_location
      id
      slot
      title
      price
    }
  }
}
    `;

/**
 * __useGetUserEscapeRoomRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetUserEscapeRoomRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEscapeRoomRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEscapeRoomRegistrationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserEscapeRoomRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<GetUserEscapeRoomRegistrationsQuery, GetUserEscapeRoomRegistrationsQueryVariables> & ({ variables: GetUserEscapeRoomRegistrationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserEscapeRoomRegistrationsQuery, GetUserEscapeRoomRegistrationsQueryVariables>(GetUserEscapeRoomRegistrationsDocument, options);
      }
export function useGetUserEscapeRoomRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEscapeRoomRegistrationsQuery, GetUserEscapeRoomRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserEscapeRoomRegistrationsQuery, GetUserEscapeRoomRegistrationsQueryVariables>(GetUserEscapeRoomRegistrationsDocument, options);
        }
export function useGetUserEscapeRoomRegistrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserEscapeRoomRegistrationsQuery, GetUserEscapeRoomRegistrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserEscapeRoomRegistrationsQuery, GetUserEscapeRoomRegistrationsQueryVariables>(GetUserEscapeRoomRegistrationsDocument, options);
        }
export type GetUserEscapeRoomRegistrationsQueryHookResult = ReturnType<typeof useGetUserEscapeRoomRegistrationsQuery>;
export type GetUserEscapeRoomRegistrationsLazyQueryHookResult = ReturnType<typeof useGetUserEscapeRoomRegistrationsLazyQuery>;
export type GetUserEscapeRoomRegistrationsSuspenseQueryHookResult = ReturnType<typeof useGetUserEscapeRoomRegistrationsSuspenseQuery>;
export type GetUserEscapeRoomRegistrationsQueryResult = Apollo.QueryResult<GetUserEscapeRoomRegistrationsQuery, GetUserEscapeRoomRegistrationsQueryVariables>;
export const GetAvailableSlotsDocument = gql`
    query GetAvailableSlots($escapeRoomId: String!) {
  getAvailableSlots(escape_room_id: $escapeRoomId)
}
    `;

/**
 * __useGetAvailableSlotsQuery__
 *
 * To run a query within a React component, call `useGetAvailableSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableSlotsQuery({
 *   variables: {
 *      escapeRoomId: // value for 'escapeRoomId'
 *   },
 * });
 */
export function useGetAvailableSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables> & ({ variables: GetAvailableSlotsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>(GetAvailableSlotsDocument, options);
      }
export function useGetAvailableSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>(GetAvailableSlotsDocument, options);
        }
export function useGetAvailableSlotsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>(GetAvailableSlotsDocument, options);
        }
export type GetAvailableSlotsQueryHookResult = ReturnType<typeof useGetAvailableSlotsQuery>;
export type GetAvailableSlotsLazyQueryHookResult = ReturnType<typeof useGetAvailableSlotsLazyQuery>;
export type GetAvailableSlotsSuspenseQueryHookResult = ReturnType<typeof useGetAvailableSlotsSuspenseQuery>;
export type GetAvailableSlotsQueryResult = Apollo.QueryResult<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>;
export const CheckInformalsClubPassStatusDocument = gql`
    query CheckInformalsClubPassStatus($userId: String!) {
  checkInformalsClubPassStatus(user_id: $userId)
}
    `;

/**
 * __useCheckInformalsClubPassStatusQuery__
 *
 * To run a query within a React component, call `useCheckInformalsClubPassStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckInformalsClubPassStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckInformalsClubPassStatusQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCheckInformalsClubPassStatusQuery(baseOptions: Apollo.QueryHookOptions<CheckInformalsClubPassStatusQuery, CheckInformalsClubPassStatusQueryVariables> & ({ variables: CheckInformalsClubPassStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckInformalsClubPassStatusQuery, CheckInformalsClubPassStatusQueryVariables>(CheckInformalsClubPassStatusDocument, options);
      }
export function useCheckInformalsClubPassStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckInformalsClubPassStatusQuery, CheckInformalsClubPassStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckInformalsClubPassStatusQuery, CheckInformalsClubPassStatusQueryVariables>(CheckInformalsClubPassStatusDocument, options);
        }
export function useCheckInformalsClubPassStatusSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckInformalsClubPassStatusQuery, CheckInformalsClubPassStatusQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckInformalsClubPassStatusQuery, CheckInformalsClubPassStatusQueryVariables>(CheckInformalsClubPassStatusDocument, options);
        }
export type CheckInformalsClubPassStatusQueryHookResult = ReturnType<typeof useCheckInformalsClubPassStatusQuery>;
export type CheckInformalsClubPassStatusLazyQueryHookResult = ReturnType<typeof useCheckInformalsClubPassStatusLazyQuery>;
export type CheckInformalsClubPassStatusSuspenseQueryHookResult = ReturnType<typeof useCheckInformalsClubPassStatusSuspenseQuery>;
export type CheckInformalsClubPassStatusQueryResult = Apollo.QueryResult<CheckInformalsClubPassStatusQuery, CheckInformalsClubPassStatusQueryVariables>;
export const GetAvailableSlotsByDateDocument = gql`
    query GetAvailableSlotsByDate($eventDate: DateTimeISO!, $escapeRoomId: String!) {
  getAvailableSlotsByDate(event_date: $eventDate, escape_room_id: $escapeRoomId)
}
    `;

/**
 * __useGetAvailableSlotsByDateQuery__
 *
 * To run a query within a React component, call `useGetAvailableSlotsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableSlotsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableSlotsByDateQuery({
 *   variables: {
 *      eventDate: // value for 'eventDate'
 *      escapeRoomId: // value for 'escapeRoomId'
 *   },
 * });
 */
export function useGetAvailableSlotsByDateQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableSlotsByDateQuery, GetAvailableSlotsByDateQueryVariables> & ({ variables: GetAvailableSlotsByDateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableSlotsByDateQuery, GetAvailableSlotsByDateQueryVariables>(GetAvailableSlotsByDateDocument, options);
      }
export function useGetAvailableSlotsByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableSlotsByDateQuery, GetAvailableSlotsByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableSlotsByDateQuery, GetAvailableSlotsByDateQueryVariables>(GetAvailableSlotsByDateDocument, options);
        }
export function useGetAvailableSlotsByDateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAvailableSlotsByDateQuery, GetAvailableSlotsByDateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAvailableSlotsByDateQuery, GetAvailableSlotsByDateQueryVariables>(GetAvailableSlotsByDateDocument, options);
        }
export type GetAvailableSlotsByDateQueryHookResult = ReturnType<typeof useGetAvailableSlotsByDateQuery>;
export type GetAvailableSlotsByDateLazyQueryHookResult = ReturnType<typeof useGetAvailableSlotsByDateLazyQuery>;
export type GetAvailableSlotsByDateSuspenseQueryHookResult = ReturnType<typeof useGetAvailableSlotsByDateSuspenseQuery>;
export type GetAvailableSlotsByDateQueryResult = Apollo.QueryResult<GetAvailableSlotsByDateQuery, GetAvailableSlotsByDateQueryVariables>;
export const GetClubPassHolderRegistrationsDocument = gql`
    query GetClubPassHolderRegistrations($escapeRoomId: String!) {
  getClubPassHolderRegistrations(escape_room_id: $escapeRoomId) {
    registration {
      id
      razorpay_order_id
      reg_date
      verified
    }
    user {
      DOB
      username
      user_id
      email_primary
      mobile_number_primary
    }
    escapeRoom {
      id
      title
      slot
    }
  }
}
    `;

/**
 * __useGetClubPassHolderRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetClubPassHolderRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubPassHolderRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubPassHolderRegistrationsQuery({
 *   variables: {
 *      escapeRoomId: // value for 'escapeRoomId'
 *   },
 * });
 */
export function useGetClubPassHolderRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<GetClubPassHolderRegistrationsQuery, GetClubPassHolderRegistrationsQueryVariables> & ({ variables: GetClubPassHolderRegistrationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClubPassHolderRegistrationsQuery, GetClubPassHolderRegistrationsQueryVariables>(GetClubPassHolderRegistrationsDocument, options);
      }
export function useGetClubPassHolderRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClubPassHolderRegistrationsQuery, GetClubPassHolderRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClubPassHolderRegistrationsQuery, GetClubPassHolderRegistrationsQueryVariables>(GetClubPassHolderRegistrationsDocument, options);
        }
export function useGetClubPassHolderRegistrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetClubPassHolderRegistrationsQuery, GetClubPassHolderRegistrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClubPassHolderRegistrationsQuery, GetClubPassHolderRegistrationsQueryVariables>(GetClubPassHolderRegistrationsDocument, options);
        }
export type GetClubPassHolderRegistrationsQueryHookResult = ReturnType<typeof useGetClubPassHolderRegistrationsQuery>;
export type GetClubPassHolderRegistrationsLazyQueryHookResult = ReturnType<typeof useGetClubPassHolderRegistrationsLazyQuery>;
export type GetClubPassHolderRegistrationsSuspenseQueryHookResult = ReturnType<typeof useGetClubPassHolderRegistrationsSuspenseQuery>;
export type GetClubPassHolderRegistrationsQueryResult = Apollo.QueryResult<GetClubPassHolderRegistrationsQuery, GetClubPassHolderRegistrationsQueryVariables>;
export const GetEscapeRoomByIdDocument = gql`
    query GetEscapeRoomById($getEscapeRoomByIdId: String!) {
  getEscapeRoomById(id: $getEscapeRoomByIdId) {
    id
    title
    description
    image_url
    event_date
    price
    event_location
    registrations_closed
    slot
  }
}
    `;

/**
 * __useGetEscapeRoomByIdQuery__
 *
 * To run a query within a React component, call `useGetEscapeRoomByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEscapeRoomByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEscapeRoomByIdQuery({
 *   variables: {
 *      getEscapeRoomByIdId: // value for 'getEscapeRoomByIdId'
 *   },
 * });
 */
export function useGetEscapeRoomByIdQuery(baseOptions: Apollo.QueryHookOptions<GetEscapeRoomByIdQuery, GetEscapeRoomByIdQueryVariables> & ({ variables: GetEscapeRoomByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEscapeRoomByIdQuery, GetEscapeRoomByIdQueryVariables>(GetEscapeRoomByIdDocument, options);
      }
export function useGetEscapeRoomByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEscapeRoomByIdQuery, GetEscapeRoomByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEscapeRoomByIdQuery, GetEscapeRoomByIdQueryVariables>(GetEscapeRoomByIdDocument, options);
        }
export function useGetEscapeRoomByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEscapeRoomByIdQuery, GetEscapeRoomByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEscapeRoomByIdQuery, GetEscapeRoomByIdQueryVariables>(GetEscapeRoomByIdDocument, options);
        }
export type GetEscapeRoomByIdQueryHookResult = ReturnType<typeof useGetEscapeRoomByIdQuery>;
export type GetEscapeRoomByIdLazyQueryHookResult = ReturnType<typeof useGetEscapeRoomByIdLazyQuery>;
export type GetEscapeRoomByIdSuspenseQueryHookResult = ReturnType<typeof useGetEscapeRoomByIdSuspenseQuery>;
export type GetEscapeRoomByIdQueryResult = Apollo.QueryResult<GetEscapeRoomByIdQuery, GetEscapeRoomByIdQueryVariables>;
export const GetEscapeRoomByLocationDocument = gql`
    query GetEscapeRoomByLocation($location: String!) {
  getEscapeRoomByLocation(location: $location) {
    id
    title
    description
    image_url
    event_date
    price
    event_location
    registrations_closed
    slot
  }
}
    `;

/**
 * __useGetEscapeRoomByLocationQuery__
 *
 * To run a query within a React component, call `useGetEscapeRoomByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEscapeRoomByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEscapeRoomByLocationQuery({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useGetEscapeRoomByLocationQuery(baseOptions: Apollo.QueryHookOptions<GetEscapeRoomByLocationQuery, GetEscapeRoomByLocationQueryVariables> & ({ variables: GetEscapeRoomByLocationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEscapeRoomByLocationQuery, GetEscapeRoomByLocationQueryVariables>(GetEscapeRoomByLocationDocument, options);
      }
export function useGetEscapeRoomByLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEscapeRoomByLocationQuery, GetEscapeRoomByLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEscapeRoomByLocationQuery, GetEscapeRoomByLocationQueryVariables>(GetEscapeRoomByLocationDocument, options);
        }
export function useGetEscapeRoomByLocationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEscapeRoomByLocationQuery, GetEscapeRoomByLocationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEscapeRoomByLocationQuery, GetEscapeRoomByLocationQueryVariables>(GetEscapeRoomByLocationDocument, options);
        }
export type GetEscapeRoomByLocationQueryHookResult = ReturnType<typeof useGetEscapeRoomByLocationQuery>;
export type GetEscapeRoomByLocationLazyQueryHookResult = ReturnType<typeof useGetEscapeRoomByLocationLazyQuery>;
export type GetEscapeRoomByLocationSuspenseQueryHookResult = ReturnType<typeof useGetEscapeRoomByLocationSuspenseQuery>;
export type GetEscapeRoomByLocationQueryResult = Apollo.QueryResult<GetEscapeRoomByLocationQuery, GetEscapeRoomByLocationQueryVariables>;
export const GetEscapeRoomRegistrationsDocument = gql`
    query GetEscapeRoomRegistrations {
  getEscapeRoomRegistrations {
    registration {
      id
      price
      razorpay_order_id
      user_id
      reg_date
      verified
    }
    user {
      email_primary
      mobile_number_primary
      username
      user_id
    }
    escapeRoom {
      description
      event_date
      event_location
      id
      price
      slot
      registrations_closed
      title
    }
  }
}
    `;

/**
 * __useGetEscapeRoomRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetEscapeRoomRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEscapeRoomRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEscapeRoomRegistrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEscapeRoomRegistrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetEscapeRoomRegistrationsQuery, GetEscapeRoomRegistrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEscapeRoomRegistrationsQuery, GetEscapeRoomRegistrationsQueryVariables>(GetEscapeRoomRegistrationsDocument, options);
      }
export function useGetEscapeRoomRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEscapeRoomRegistrationsQuery, GetEscapeRoomRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEscapeRoomRegistrationsQuery, GetEscapeRoomRegistrationsQueryVariables>(GetEscapeRoomRegistrationsDocument, options);
        }
export function useGetEscapeRoomRegistrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEscapeRoomRegistrationsQuery, GetEscapeRoomRegistrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEscapeRoomRegistrationsQuery, GetEscapeRoomRegistrationsQueryVariables>(GetEscapeRoomRegistrationsDocument, options);
        }
export type GetEscapeRoomRegistrationsQueryHookResult = ReturnType<typeof useGetEscapeRoomRegistrationsQuery>;
export type GetEscapeRoomRegistrationsLazyQueryHookResult = ReturnType<typeof useGetEscapeRoomRegistrationsLazyQuery>;
export type GetEscapeRoomRegistrationsSuspenseQueryHookResult = ReturnType<typeof useGetEscapeRoomRegistrationsSuspenseQuery>;
export type GetEscapeRoomRegistrationsQueryResult = Apollo.QueryResult<GetEscapeRoomRegistrationsQuery, GetEscapeRoomRegistrationsQueryVariables>;
export const GetEscapeRoomsDocument = gql`
    query GetEscapeRooms {
  getEscapeRooms {
    id
    title
    description
    image_url
    event_date
    price
    event_location
    registrations_closed
    slot
  }
}
    `;

/**
 * __useGetEscapeRoomsQuery__
 *
 * To run a query within a React component, call `useGetEscapeRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEscapeRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEscapeRoomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEscapeRoomsQuery(baseOptions?: Apollo.QueryHookOptions<GetEscapeRoomsQuery, GetEscapeRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEscapeRoomsQuery, GetEscapeRoomsQueryVariables>(GetEscapeRoomsDocument, options);
      }
export function useGetEscapeRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEscapeRoomsQuery, GetEscapeRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEscapeRoomsQuery, GetEscapeRoomsQueryVariables>(GetEscapeRoomsDocument, options);
        }
export function useGetEscapeRoomsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEscapeRoomsQuery, GetEscapeRoomsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEscapeRoomsQuery, GetEscapeRoomsQueryVariables>(GetEscapeRoomsDocument, options);
        }
export type GetEscapeRoomsQueryHookResult = ReturnType<typeof useGetEscapeRoomsQuery>;
export type GetEscapeRoomsLazyQueryHookResult = ReturnType<typeof useGetEscapeRoomsLazyQuery>;
export type GetEscapeRoomsSuspenseQueryHookResult = ReturnType<typeof useGetEscapeRoomsSuspenseQuery>;
export type GetEscapeRoomsQueryResult = Apollo.QueryResult<GetEscapeRoomsQuery, GetEscapeRoomsQueryVariables>;
export const GetRegistrationsBySlotDocument = gql`
    query GetRegistrationsBySlot($escapeRoomId: String!, $slot: String!) {
  getRegistrationsBySlot(escape_room_id: $escapeRoomId, slot: $slot) {
    registration {
      id
      paid
      escape_room_id
      razorpay_order_id
      reg_date
      slot
    }
    user {
      email_primary
      mobile_number_primary
      user_id
      username
    }
    escapeRoom {
      title
      slot
      registrations_closed
      id
      event_location
      event_date
      description
    }
  }
}
    `;

/**
 * __useGetRegistrationsBySlotQuery__
 *
 * To run a query within a React component, call `useGetRegistrationsBySlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistrationsBySlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistrationsBySlotQuery({
 *   variables: {
 *      escapeRoomId: // value for 'escapeRoomId'
 *      slot: // value for 'slot'
 *   },
 * });
 */
export function useGetRegistrationsBySlotQuery(baseOptions: Apollo.QueryHookOptions<GetRegistrationsBySlotQuery, GetRegistrationsBySlotQueryVariables> & ({ variables: GetRegistrationsBySlotQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegistrationsBySlotQuery, GetRegistrationsBySlotQueryVariables>(GetRegistrationsBySlotDocument, options);
      }
export function useGetRegistrationsBySlotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegistrationsBySlotQuery, GetRegistrationsBySlotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegistrationsBySlotQuery, GetRegistrationsBySlotQueryVariables>(GetRegistrationsBySlotDocument, options);
        }
export function useGetRegistrationsBySlotSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRegistrationsBySlotQuery, GetRegistrationsBySlotQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRegistrationsBySlotQuery, GetRegistrationsBySlotQueryVariables>(GetRegistrationsBySlotDocument, options);
        }
export type GetRegistrationsBySlotQueryHookResult = ReturnType<typeof useGetRegistrationsBySlotQuery>;
export type GetRegistrationsBySlotLazyQueryHookResult = ReturnType<typeof useGetRegistrationsBySlotLazyQuery>;
export type GetRegistrationsBySlotSuspenseQueryHookResult = ReturnType<typeof useGetRegistrationsBySlotSuspenseQuery>;
export type GetRegistrationsBySlotQueryResult = Apollo.QueryResult<GetRegistrationsBySlotQuery, GetRegistrationsBySlotQueryVariables>;
export const GetSlotAvailabilityByDateDocument = gql`
    query GetSlotAvailabilityByDate($eventDate: DateTimeISO!, $escapeRoomId: String!) {
  getSlotAvailabilityByDate(event_date: $eventDate, escape_room_id: $escapeRoomId)
}
    `;

/**
 * __useGetSlotAvailabilityByDateQuery__
 *
 * To run a query within a React component, call `useGetSlotAvailabilityByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlotAvailabilityByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlotAvailabilityByDateQuery({
 *   variables: {
 *      eventDate: // value for 'eventDate'
 *      escapeRoomId: // value for 'escapeRoomId'
 *   },
 * });
 */
export function useGetSlotAvailabilityByDateQuery(baseOptions: Apollo.QueryHookOptions<GetSlotAvailabilityByDateQuery, GetSlotAvailabilityByDateQueryVariables> & ({ variables: GetSlotAvailabilityByDateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSlotAvailabilityByDateQuery, GetSlotAvailabilityByDateQueryVariables>(GetSlotAvailabilityByDateDocument, options);
      }
export function useGetSlotAvailabilityByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSlotAvailabilityByDateQuery, GetSlotAvailabilityByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSlotAvailabilityByDateQuery, GetSlotAvailabilityByDateQueryVariables>(GetSlotAvailabilityByDateDocument, options);
        }
export function useGetSlotAvailabilityByDateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSlotAvailabilityByDateQuery, GetSlotAvailabilityByDateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSlotAvailabilityByDateQuery, GetSlotAvailabilityByDateQueryVariables>(GetSlotAvailabilityByDateDocument, options);
        }
export type GetSlotAvailabilityByDateQueryHookResult = ReturnType<typeof useGetSlotAvailabilityByDateQuery>;
export type GetSlotAvailabilityByDateLazyQueryHookResult = ReturnType<typeof useGetSlotAvailabilityByDateLazyQuery>;
export type GetSlotAvailabilityByDateSuspenseQueryHookResult = ReturnType<typeof useGetSlotAvailabilityByDateSuspenseQuery>;
export type GetSlotAvailabilityByDateQueryResult = Apollo.QueryResult<GetSlotAvailabilityByDateQuery, GetSlotAvailabilityByDateQueryVariables>;
export const CreateAllSlotEscapeRoomSameTitleDescriptionDocument = gql`
    mutation CreateAllSlotEscapeRoomSameTitleDescription {
  createAllSlotEscapeRoomSameTitleDescription
}
    `;
export type CreateAllSlotEscapeRoomSameTitleDescriptionMutationFn = Apollo.MutationFunction<CreateAllSlotEscapeRoomSameTitleDescriptionMutation, CreateAllSlotEscapeRoomSameTitleDescriptionMutationVariables>;

/**
 * __useCreateAllSlotEscapeRoomSameTitleDescriptionMutation__
 *
 * To run a mutation, you first call `useCreateAllSlotEscapeRoomSameTitleDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAllSlotEscapeRoomSameTitleDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAllSlotEscapeRoomSameTitleDescriptionMutation, { data, loading, error }] = useCreateAllSlotEscapeRoomSameTitleDescriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateAllSlotEscapeRoomSameTitleDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateAllSlotEscapeRoomSameTitleDescriptionMutation, CreateAllSlotEscapeRoomSameTitleDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAllSlotEscapeRoomSameTitleDescriptionMutation, CreateAllSlotEscapeRoomSameTitleDescriptionMutationVariables>(CreateAllSlotEscapeRoomSameTitleDescriptionDocument, options);
      }
export type CreateAllSlotEscapeRoomSameTitleDescriptionMutationHookResult = ReturnType<typeof useCreateAllSlotEscapeRoomSameTitleDescriptionMutation>;
export type CreateAllSlotEscapeRoomSameTitleDescriptionMutationResult = Apollo.MutationResult<CreateAllSlotEscapeRoomSameTitleDescriptionMutation>;
export type CreateAllSlotEscapeRoomSameTitleDescriptionMutationOptions = Apollo.BaseMutationOptions<CreateAllSlotEscapeRoomSameTitleDescriptionMutation, CreateAllSlotEscapeRoomSameTitleDescriptionMutationVariables>;
export const CreateEscapeRoomDocument = gql`
    mutation CreateEscapeRoom($title: String!, $description: [String!]!, $eventDate: DateTimeISO!, $price: Float!, $eventLocation: String!, $slot: String!, $registrationsClosed: Boolean!) {
  createEscapeRoom(
    title: $title
    description: $description
    event_date: $eventDate
    price: $price
    event_location: $eventLocation
    slot: $slot
    registrations_closed: $registrationsClosed
  ) {
    id
    title
    description
    image_url
    event_date
    price
    event_location
    registrations_closed
    slot
  }
}
    `;
export type CreateEscapeRoomMutationFn = Apollo.MutationFunction<CreateEscapeRoomMutation, CreateEscapeRoomMutationVariables>;

/**
 * __useCreateEscapeRoomMutation__
 *
 * To run a mutation, you first call `useCreateEscapeRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEscapeRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEscapeRoomMutation, { data, loading, error }] = useCreateEscapeRoomMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      eventDate: // value for 'eventDate'
 *      price: // value for 'price'
 *      eventLocation: // value for 'eventLocation'
 *      slot: // value for 'slot'
 *      registrationsClosed: // value for 'registrationsClosed'
 *   },
 * });
 */
export function useCreateEscapeRoomMutation(baseOptions?: Apollo.MutationHookOptions<CreateEscapeRoomMutation, CreateEscapeRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEscapeRoomMutation, CreateEscapeRoomMutationVariables>(CreateEscapeRoomDocument, options);
      }
export type CreateEscapeRoomMutationHookResult = ReturnType<typeof useCreateEscapeRoomMutation>;
export type CreateEscapeRoomMutationResult = Apollo.MutationResult<CreateEscapeRoomMutation>;
export type CreateEscapeRoomMutationOptions = Apollo.BaseMutationOptions<CreateEscapeRoomMutation, CreateEscapeRoomMutationVariables>;
export const CreateEscapeRoomRegistrationDocument = gql`
    mutation CreateEscapeRoomRegistration($userId: String!, $escapeRoomId: String!, $slot: String!) {
  createEscapeRoomRegistration(
    user_id: $userId
    escape_room_id: $escapeRoomId
    slot: $slot
  ) {
    id
    user_id
    escape_room_id
    slot
    reg_date
    price
    razorpay_order_id
    verified
    paid
  }
}
    `;
export type CreateEscapeRoomRegistrationMutationFn = Apollo.MutationFunction<CreateEscapeRoomRegistrationMutation, CreateEscapeRoomRegistrationMutationVariables>;

/**
 * __useCreateEscapeRoomRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateEscapeRoomRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEscapeRoomRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEscapeRoomRegistrationMutation, { data, loading, error }] = useCreateEscapeRoomRegistrationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      escapeRoomId: // value for 'escapeRoomId'
 *      slot: // value for 'slot'
 *   },
 * });
 */
export function useCreateEscapeRoomRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateEscapeRoomRegistrationMutation, CreateEscapeRoomRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEscapeRoomRegistrationMutation, CreateEscapeRoomRegistrationMutationVariables>(CreateEscapeRoomRegistrationDocument, options);
      }
export type CreateEscapeRoomRegistrationMutationHookResult = ReturnType<typeof useCreateEscapeRoomRegistrationMutation>;
export type CreateEscapeRoomRegistrationMutationResult = Apollo.MutationResult<CreateEscapeRoomRegistrationMutation>;
export type CreateEscapeRoomRegistrationMutationOptions = Apollo.BaseMutationOptions<CreateEscapeRoomRegistrationMutation, CreateEscapeRoomRegistrationMutationVariables>;
export const DeleteEscapeRoomDocument = gql`
    mutation DeleteEscapeRoom($deleteEscapeRoomId: String!) {
  deleteEscapeRoom(id: $deleteEscapeRoomId)
}
    `;
export type DeleteEscapeRoomMutationFn = Apollo.MutationFunction<DeleteEscapeRoomMutation, DeleteEscapeRoomMutationVariables>;

/**
 * __useDeleteEscapeRoomMutation__
 *
 * To run a mutation, you first call `useDeleteEscapeRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEscapeRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEscapeRoomMutation, { data, loading, error }] = useDeleteEscapeRoomMutation({
 *   variables: {
 *      deleteEscapeRoomId: // value for 'deleteEscapeRoomId'
 *   },
 * });
 */
export function useDeleteEscapeRoomMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEscapeRoomMutation, DeleteEscapeRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEscapeRoomMutation, DeleteEscapeRoomMutationVariables>(DeleteEscapeRoomDocument, options);
      }
export type DeleteEscapeRoomMutationHookResult = ReturnType<typeof useDeleteEscapeRoomMutation>;
export type DeleteEscapeRoomMutationResult = Apollo.MutationResult<DeleteEscapeRoomMutation>;
export type DeleteEscapeRoomMutationOptions = Apollo.BaseMutationOptions<DeleteEscapeRoomMutation, DeleteEscapeRoomMutationVariables>;
export const UpdateEscapeRoomDocument = gql`
    mutation UpdateEscapeRoom($title: String!, $description: [String!]!, $eventDate: DateTimeISO!, $price: Float!, $eventLocation: String!, $slot: String!, $registrationsClosed: Boolean!, $updateEscapeRoomId: String!) {
  updateEscapeRoom(
    title: $title
    description: $description
    event_date: $eventDate
    price: $price
    event_location: $eventLocation
    slot: $slot
    registrations_closed: $registrationsClosed
    id: $updateEscapeRoomId
  ) {
    id
    title
    description
    image_url
    event_date
    price
    event_location
    registrations_closed
    slot
  }
}
    `;
export type UpdateEscapeRoomMutationFn = Apollo.MutationFunction<UpdateEscapeRoomMutation, UpdateEscapeRoomMutationVariables>;

/**
 * __useUpdateEscapeRoomMutation__
 *
 * To run a mutation, you first call `useUpdateEscapeRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEscapeRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEscapeRoomMutation, { data, loading, error }] = useUpdateEscapeRoomMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      eventDate: // value for 'eventDate'
 *      price: // value for 'price'
 *      eventLocation: // value for 'eventLocation'
 *      slot: // value for 'slot'
 *      registrationsClosed: // value for 'registrationsClosed'
 *      updateEscapeRoomId: // value for 'updateEscapeRoomId'
 *   },
 * });
 */
export function useUpdateEscapeRoomMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEscapeRoomMutation, UpdateEscapeRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEscapeRoomMutation, UpdateEscapeRoomMutationVariables>(UpdateEscapeRoomDocument, options);
      }
export type UpdateEscapeRoomMutationHookResult = ReturnType<typeof useUpdateEscapeRoomMutation>;
export type UpdateEscapeRoomMutationResult = Apollo.MutationResult<UpdateEscapeRoomMutation>;
export type UpdateEscapeRoomMutationOptions = Apollo.BaseMutationOptions<UpdateEscapeRoomMutation, UpdateEscapeRoomMutationVariables>;
export const GetEventsDocument = gql`
    query GetEvents {
  getEvents {
    id
    title
    description
    image_url
    Club {
      id
      clubPassPrice
      clubName
      logo
      category
    }
    event_date
    price
    event_location
    registrations_closed
  }
}
    `;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export function useGetEventsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsSuspenseQueryHookResult = ReturnType<typeof useGetEventsSuspenseQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const GetEventByClubDocument = gql`
    query GetEventByClub($clubId: String!) {
  getEventByClub(clubId: $clubId) {
    id
    title
    description
    image_url
    event_date
    price
    event_location
    registrations_closed
    Club {
      clubName
      clubPassPrice
      id
      logo
      category
    }
  }
}
    `;

/**
 * __useGetEventByClubQuery__
 *
 * To run a query within a React component, call `useGetEventByClubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventByClubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventByClubQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useGetEventByClubQuery(baseOptions: Apollo.QueryHookOptions<GetEventByClubQuery, GetEventByClubQueryVariables> & ({ variables: GetEventByClubQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventByClubQuery, GetEventByClubQueryVariables>(GetEventByClubDocument, options);
      }
export function useGetEventByClubLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventByClubQuery, GetEventByClubQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventByClubQuery, GetEventByClubQueryVariables>(GetEventByClubDocument, options);
        }
export function useGetEventByClubSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEventByClubQuery, GetEventByClubQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEventByClubQuery, GetEventByClubQueryVariables>(GetEventByClubDocument, options);
        }
export type GetEventByClubQueryHookResult = ReturnType<typeof useGetEventByClubQuery>;
export type GetEventByClubLazyQueryHookResult = ReturnType<typeof useGetEventByClubLazyQuery>;
export type GetEventByClubSuspenseQueryHookResult = ReturnType<typeof useGetEventByClubSuspenseQuery>;
export type GetEventByClubQueryResult = Apollo.QueryResult<GetEventByClubQuery, GetEventByClubQueryVariables>;
export const GetEventsByIdDocument = gql`
    query GetEventsById($getEventsByIdId: String!) {
  getEventsById(id: $getEventsByIdId) {
    id
    title
    description
    image_url
    Club {
      id
      clubPassPrice
      clubName
      logo
      category
    }
    event_date
    price
    event_location
    registrations_closed
  }
}
    `;

/**
 * __useGetEventsByIdQuery__
 *
 * To run a query within a React component, call `useGetEventsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsByIdQuery({
 *   variables: {
 *      getEventsByIdId: // value for 'getEventsByIdId'
 *   },
 * });
 */
export function useGetEventsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetEventsByIdQuery, GetEventsByIdQueryVariables> & ({ variables: GetEventsByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsByIdQuery, GetEventsByIdQueryVariables>(GetEventsByIdDocument, options);
      }
export function useGetEventsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsByIdQuery, GetEventsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsByIdQuery, GetEventsByIdQueryVariables>(GetEventsByIdDocument, options);
        }
export function useGetEventsByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEventsByIdQuery, GetEventsByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEventsByIdQuery, GetEventsByIdQueryVariables>(GetEventsByIdDocument, options);
        }
export type GetEventsByIdQueryHookResult = ReturnType<typeof useGetEventsByIdQuery>;
export type GetEventsByIdLazyQueryHookResult = ReturnType<typeof useGetEventsByIdLazyQuery>;
export type GetEventsByIdSuspenseQueryHookResult = ReturnType<typeof useGetEventsByIdSuspenseQuery>;
export type GetEventsByIdQueryResult = Apollo.QueryResult<GetEventsByIdQuery, GetEventsByIdQueryVariables>;
export const GetEventBylocationDocument = gql`
    query GetEventBylocation($location: String!) {
  getEventBylocation(location: $location) {
    id
    title
    description
    image_url
    Club {
      id
      clubPassPrice
      clubName
      logo
      category
    }
    event_date
    price
    event_location
    registrations_closed
  }
}
    `;

/**
 * __useGetEventBylocationQuery__
 *
 * To run a query within a React component, call `useGetEventBylocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventBylocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventBylocationQuery({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useGetEventBylocationQuery(baseOptions: Apollo.QueryHookOptions<GetEventBylocationQuery, GetEventBylocationQueryVariables> & ({ variables: GetEventBylocationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventBylocationQuery, GetEventBylocationQueryVariables>(GetEventBylocationDocument, options);
      }
export function useGetEventBylocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventBylocationQuery, GetEventBylocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventBylocationQuery, GetEventBylocationQueryVariables>(GetEventBylocationDocument, options);
        }
export function useGetEventBylocationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEventBylocationQuery, GetEventBylocationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEventBylocationQuery, GetEventBylocationQueryVariables>(GetEventBylocationDocument, options);
        }
export type GetEventBylocationQueryHookResult = ReturnType<typeof useGetEventBylocationQuery>;
export type GetEventBylocationLazyQueryHookResult = ReturnType<typeof useGetEventBylocationLazyQuery>;
export type GetEventBylocationSuspenseQueryHookResult = ReturnType<typeof useGetEventBylocationSuspenseQuery>;
export type GetEventBylocationQueryResult = Apollo.QueryResult<GetEventBylocationQuery, GetEventBylocationQueryVariables>;
export const CreateEventsDocument = gql`
    mutation CreateEvents($title: String!, $description: [String!]!, $eventDate: DateTimeISO!, $price: Float!, $eventLocation: String!, $clubId: String!, $imageUrl: String!, $registrationsClosed: Boolean!) {
  createEvents(
    title: $title
    description: $description
    event_date: $eventDate
    price: $price
    event_location: $eventLocation
    clubId: $clubId
    image_url: $imageUrl
    registrations_closed: $registrationsClosed
  ) {
    id
    title
    description
    image_url
    event_date
    price
    event_location
    registrations_closed
    Club {
      id
      clubPassPrice
      clubName
      logo
      category
    }
  }
}
    `;
export type CreateEventsMutationFn = Apollo.MutationFunction<CreateEventsMutation, CreateEventsMutationVariables>;

/**
 * __useCreateEventsMutation__
 *
 * To run a mutation, you first call `useCreateEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventsMutation, { data, loading, error }] = useCreateEventsMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      eventDate: // value for 'eventDate'
 *      price: // value for 'price'
 *      eventLocation: // value for 'eventLocation'
 *      clubId: // value for 'clubId'
 *      imageUrl: // value for 'imageUrl'
 *      registrationsClosed: // value for 'registrationsClosed'
 *   },
 * });
 */
export function useCreateEventsMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventsMutation, CreateEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventsMutation, CreateEventsMutationVariables>(CreateEventsDocument, options);
      }
export type CreateEventsMutationHookResult = ReturnType<typeof useCreateEventsMutation>;
export type CreateEventsMutationResult = Apollo.MutationResult<CreateEventsMutation>;
export type CreateEventsMutationOptions = Apollo.BaseMutationOptions<CreateEventsMutation, CreateEventsMutationVariables>;
export const UpdateEventsDocument = gql`
    mutation UpdateEvents($title: String!, $description: [String!]!, $eventDate: DateTimeISO!, $price: Float!, $eventLocation: String!, $clubId: String!, $imageUrl: String!, $registrationsClosed: Boolean!, $updateEventsId: String!) {
  updateEvents(
    title: $title
    description: $description
    event_date: $eventDate
    price: $price
    event_location: $eventLocation
    clubId: $clubId
    image_url: $imageUrl
    registrations_closed: $registrationsClosed
    id: $updateEventsId
  ) {
    id
    title
    description
    image_url
    event_date
    price
    event_location
    registrations_closed
    Club {
      clubName
      clubPassPrice
      id
      logo
      category
    }
  }
}
    `;
export type UpdateEventsMutationFn = Apollo.MutationFunction<UpdateEventsMutation, UpdateEventsMutationVariables>;

/**
 * __useUpdateEventsMutation__
 *
 * To run a mutation, you first call `useUpdateEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventsMutation, { data, loading, error }] = useUpdateEventsMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      eventDate: // value for 'eventDate'
 *      price: // value for 'price'
 *      eventLocation: // value for 'eventLocation'
 *      clubId: // value for 'clubId'
 *      imageUrl: // value for 'imageUrl'
 *      registrationsClosed: // value for 'registrationsClosed'
 *      updateEventsId: // value for 'updateEventsId'
 *   },
 * });
 */
export function useUpdateEventsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventsMutation, UpdateEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventsMutation, UpdateEventsMutationVariables>(UpdateEventsDocument, options);
      }
export type UpdateEventsMutationHookResult = ReturnType<typeof useUpdateEventsMutation>;
export type UpdateEventsMutationResult = Apollo.MutationResult<UpdateEventsMutation>;
export type UpdateEventsMutationOptions = Apollo.BaseMutationOptions<UpdateEventsMutation, UpdateEventsMutationVariables>;
export const DeleteEventsDocument = gql`
    mutation DeleteEvents($deleteEventsId: String!) {
  deleteEvents(id: $deleteEventsId)
}
    `;
export type DeleteEventsMutationFn = Apollo.MutationFunction<DeleteEventsMutation, DeleteEventsMutationVariables>;

/**
 * __useDeleteEventsMutation__
 *
 * To run a mutation, you first call `useDeleteEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventsMutation, { data, loading, error }] = useDeleteEventsMutation({
 *   variables: {
 *      deleteEventsId: // value for 'deleteEventsId'
 *   },
 * });
 */
export function useDeleteEventsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventsMutation, DeleteEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventsMutation, DeleteEventsMutationVariables>(DeleteEventsDocument, options);
      }
export type DeleteEventsMutationHookResult = ReturnType<typeof useDeleteEventsMutation>;
export type DeleteEventsMutationResult = Apollo.MutationResult<DeleteEventsMutation>;
export type DeleteEventsMutationOptions = Apollo.BaseMutationOptions<DeleteEventsMutation, DeleteEventsMutationVariables>;
export const CreateSingleEventRegistrationDocument = gql`
    mutation CreateSingleEventRegistration($userId: String!, $eventsId: String!) {
  createSingleEventRegistration(user_id: $userId, events_id: $eventsId) {
    id
    user_id
    events_id
    reg_date
    razorpay_order_id
    verified
    paid
  }
}
    `;
export type CreateSingleEventRegistrationMutationFn = Apollo.MutationFunction<CreateSingleEventRegistrationMutation, CreateSingleEventRegistrationMutationVariables>;

/**
 * __useCreateSingleEventRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateSingleEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSingleEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSingleEventRegistrationMutation, { data, loading, error }] = useCreateSingleEventRegistrationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      eventsId: // value for 'eventsId'
 *   },
 * });
 */
export function useCreateSingleEventRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateSingleEventRegistrationMutation, CreateSingleEventRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSingleEventRegistrationMutation, CreateSingleEventRegistrationMutationVariables>(CreateSingleEventRegistrationDocument, options);
      }
export type CreateSingleEventRegistrationMutationHookResult = ReturnType<typeof useCreateSingleEventRegistrationMutation>;
export type CreateSingleEventRegistrationMutationResult = Apollo.MutationResult<CreateSingleEventRegistrationMutation>;
export type CreateSingleEventRegistrationMutationOptions = Apollo.BaseMutationOptions<CreateSingleEventRegistrationMutation, CreateSingleEventRegistrationMutationVariables>;
export const CreateDirectEventRegistrationDocument = gql`
    mutation CreateDirectEventRegistration($userId: String!, $eventsId: String!) {
  createDirectEventRegistration(user_id: $userId, events_id: $eventsId) {
    id
    user_id
    events_id
    reg_date
    razorpay_order_id
    verified
  }
}
    `;
export type CreateDirectEventRegistrationMutationFn = Apollo.MutationFunction<CreateDirectEventRegistrationMutation, CreateDirectEventRegistrationMutationVariables>;

/**
 * __useCreateDirectEventRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateDirectEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectEventRegistrationMutation, { data, loading, error }] = useCreateDirectEventRegistrationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      eventsId: // value for 'eventsId'
 *   },
 * });
 */
export function useCreateDirectEventRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateDirectEventRegistrationMutation, CreateDirectEventRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDirectEventRegistrationMutation, CreateDirectEventRegistrationMutationVariables>(CreateDirectEventRegistrationDocument, options);
      }
export type CreateDirectEventRegistrationMutationHookResult = ReturnType<typeof useCreateDirectEventRegistrationMutation>;
export type CreateDirectEventRegistrationMutationResult = Apollo.MutationResult<CreateDirectEventRegistrationMutation>;
export type CreateDirectEventRegistrationMutationOptions = Apollo.BaseMutationOptions<CreateDirectEventRegistrationMutation, CreateDirectEventRegistrationMutationVariables>;
export const GetAllEventRegistrationsDocument = gql`
    query GetAllEventRegistrations {
  getAllEventRegistrations {
    registration {
      id
      paid
      price
      reg_date
      verified
      razorpay_order_id
    }
    user {
      isIITM
      username
      user_id
      mobile_number_primary
      email_primary
    }
    event {
      description
      title
      id
    }
  }
}
    `;

/**
 * __useGetAllEventRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetAllEventRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEventRegistrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEventRegistrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEventRegistrationsQuery, GetAllEventRegistrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEventRegistrationsQuery, GetAllEventRegistrationsQueryVariables>(GetAllEventRegistrationsDocument, options);
      }
export function useGetAllEventRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEventRegistrationsQuery, GetAllEventRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEventRegistrationsQuery, GetAllEventRegistrationsQueryVariables>(GetAllEventRegistrationsDocument, options);
        }
export function useGetAllEventRegistrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllEventRegistrationsQuery, GetAllEventRegistrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllEventRegistrationsQuery, GetAllEventRegistrationsQueryVariables>(GetAllEventRegistrationsDocument, options);
        }
export type GetAllEventRegistrationsQueryHookResult = ReturnType<typeof useGetAllEventRegistrationsQuery>;
export type GetAllEventRegistrationsLazyQueryHookResult = ReturnType<typeof useGetAllEventRegistrationsLazyQuery>;
export type GetAllEventRegistrationsSuspenseQueryHookResult = ReturnType<typeof useGetAllEventRegistrationsSuspenseQuery>;
export type GetAllEventRegistrationsQueryResult = Apollo.QueryResult<GetAllEventRegistrationsQuery, GetAllEventRegistrationsQueryVariables>;
export const GeEventsRegistrationsByEventIdDocument = gql`
    query GeEventsRegistrationsByEventId($eventId: String!) {
  geEventsRegistrationsByEventId(eventId: $eventId) {
    registration {
      events_id
      id
      user_id
      verified
      reg_date
      razorpay_order_id
      paid
    }
    user {
      user_id
      email_primary
      isIITM
      username
      mobile_number_primary
    }
    event {
      description
      event_date
      event_location
      id
      image_url
      price
      registrations_closed
      title
    }
  }
}
    `;

/**
 * __useGeEventsRegistrationsByEventIdQuery__
 *
 * To run a query within a React component, call `useGeEventsRegistrationsByEventIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeEventsRegistrationsByEventIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeEventsRegistrationsByEventIdQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGeEventsRegistrationsByEventIdQuery(baseOptions: Apollo.QueryHookOptions<GeEventsRegistrationsByEventIdQuery, GeEventsRegistrationsByEventIdQueryVariables> & ({ variables: GeEventsRegistrationsByEventIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GeEventsRegistrationsByEventIdQuery, GeEventsRegistrationsByEventIdQueryVariables>(GeEventsRegistrationsByEventIdDocument, options);
      }
export function useGeEventsRegistrationsByEventIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeEventsRegistrationsByEventIdQuery, GeEventsRegistrationsByEventIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GeEventsRegistrationsByEventIdQuery, GeEventsRegistrationsByEventIdQueryVariables>(GeEventsRegistrationsByEventIdDocument, options);
        }
export function useGeEventsRegistrationsByEventIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GeEventsRegistrationsByEventIdQuery, GeEventsRegistrationsByEventIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GeEventsRegistrationsByEventIdQuery, GeEventsRegistrationsByEventIdQueryVariables>(GeEventsRegistrationsByEventIdDocument, options);
        }
export type GeEventsRegistrationsByEventIdQueryHookResult = ReturnType<typeof useGeEventsRegistrationsByEventIdQuery>;
export type GeEventsRegistrationsByEventIdLazyQueryHookResult = ReturnType<typeof useGeEventsRegistrationsByEventIdLazyQuery>;
export type GeEventsRegistrationsByEventIdSuspenseQueryHookResult = ReturnType<typeof useGeEventsRegistrationsByEventIdSuspenseQuery>;
export type GeEventsRegistrationsByEventIdQueryResult = Apollo.QueryResult<GeEventsRegistrationsByEventIdQuery, GeEventsRegistrationsByEventIdQueryVariables>;
export const GetAllComboPassRegistrationsDocument = gql`
    query GetAllComboPassRegistrations {
  getAllComboPassRegistrations {
    registration {
      reg_date
      razorpay_order_id
      payment_id
      user_id
    }
    comboPass {
      id
      title
      current_registrations
      club_ids
      description
    }
    clubs {
      clubName
      id
      category
    }
    user {
      username
      mobile_number_primary
      email_primary
    }
  }
}
    `;

/**
 * __useGetAllComboPassRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetAllComboPassRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllComboPassRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllComboPassRegistrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllComboPassRegistrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllComboPassRegistrationsQuery, GetAllComboPassRegistrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllComboPassRegistrationsQuery, GetAllComboPassRegistrationsQueryVariables>(GetAllComboPassRegistrationsDocument, options);
      }
export function useGetAllComboPassRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllComboPassRegistrationsQuery, GetAllComboPassRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllComboPassRegistrationsQuery, GetAllComboPassRegistrationsQueryVariables>(GetAllComboPassRegistrationsDocument, options);
        }
export function useGetAllComboPassRegistrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllComboPassRegistrationsQuery, GetAllComboPassRegistrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllComboPassRegistrationsQuery, GetAllComboPassRegistrationsQueryVariables>(GetAllComboPassRegistrationsDocument, options);
        }
export type GetAllComboPassRegistrationsQueryHookResult = ReturnType<typeof useGetAllComboPassRegistrationsQuery>;
export type GetAllComboPassRegistrationsLazyQueryHookResult = ReturnType<typeof useGetAllComboPassRegistrationsLazyQuery>;
export type GetAllComboPassRegistrationsSuspenseQueryHookResult = ReturnType<typeof useGetAllComboPassRegistrationsSuspenseQuery>;
export type GetAllComboPassRegistrationsQueryResult = Apollo.QueryResult<GetAllComboPassRegistrationsQuery, GetAllComboPassRegistrationsQueryVariables>;
export const GetClubPassRegistrationsDocument = gql`
    query GetClubPassRegistrations($clubId: String!) {
  getClubPassRegistrations(clubId: $clubId) {
    registration {
      paid
      razorpay_order_id
      reg_date
      user_id
      verified
      price
      id
    }
    club {
      clubName
      clubPassPrice
      category
      id
    }
    user {
      username
      user_id
      email_primary
      mobile_number_primary
    }
  }
}
    `;

/**
 * __useGetClubPassRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetClubPassRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubPassRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubPassRegistrationsQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useGetClubPassRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<GetClubPassRegistrationsQuery, GetClubPassRegistrationsQueryVariables> & ({ variables: GetClubPassRegistrationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClubPassRegistrationsQuery, GetClubPassRegistrationsQueryVariables>(GetClubPassRegistrationsDocument, options);
      }
export function useGetClubPassRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClubPassRegistrationsQuery, GetClubPassRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClubPassRegistrationsQuery, GetClubPassRegistrationsQueryVariables>(GetClubPassRegistrationsDocument, options);
        }
export function useGetClubPassRegistrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetClubPassRegistrationsQuery, GetClubPassRegistrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClubPassRegistrationsQuery, GetClubPassRegistrationsQueryVariables>(GetClubPassRegistrationsDocument, options);
        }
export type GetClubPassRegistrationsQueryHookResult = ReturnType<typeof useGetClubPassRegistrationsQuery>;
export type GetClubPassRegistrationsLazyQueryHookResult = ReturnType<typeof useGetClubPassRegistrationsLazyQuery>;
export type GetClubPassRegistrationsSuspenseQueryHookResult = ReturnType<typeof useGetClubPassRegistrationsSuspenseQuery>;
export type GetClubPassRegistrationsQueryResult = Apollo.QueryResult<GetClubPassRegistrationsQuery, GetClubPassRegistrationsQueryVariables>;
export const GetComboPassDocument = gql`
    query GetComboPass($getComboPassId: String!) {
  getComboPass(id: $getComboPassId) {
    comboPass {
      club_ids
      current_registrations
      description
      id
      image_url
      is_active
      max_registrations
      price
      title
      valid_till
    }
    clubs {
      clubName
      id
    }
  }
}
    `;

/**
 * __useGetComboPassQuery__
 *
 * To run a query within a React component, call `useGetComboPassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComboPassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComboPassQuery({
 *   variables: {
 *      getComboPassId: // value for 'getComboPassId'
 *   },
 * });
 */
export function useGetComboPassQuery(baseOptions: Apollo.QueryHookOptions<GetComboPassQuery, GetComboPassQueryVariables> & ({ variables: GetComboPassQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComboPassQuery, GetComboPassQueryVariables>(GetComboPassDocument, options);
      }
export function useGetComboPassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComboPassQuery, GetComboPassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComboPassQuery, GetComboPassQueryVariables>(GetComboPassDocument, options);
        }
export function useGetComboPassSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetComboPassQuery, GetComboPassQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetComboPassQuery, GetComboPassQueryVariables>(GetComboPassDocument, options);
        }
export type GetComboPassQueryHookResult = ReturnType<typeof useGetComboPassQuery>;
export type GetComboPassLazyQueryHookResult = ReturnType<typeof useGetComboPassLazyQuery>;
export type GetComboPassSuspenseQueryHookResult = ReturnType<typeof useGetComboPassSuspenseQuery>;
export type GetComboPassQueryResult = Apollo.QueryResult<GetComboPassQuery, GetComboPassQueryVariables>;
export const CreateDirectClubPassRegistrationDocument = gql`
    mutation CreateDirectClubPassRegistration($userId: String!, $clubId: String!) {
  createDirectClubPassRegistration(user_id: $userId, club_id: $clubId) {
    id
    club_id
    user_id
    razorpay_order_id
    verified
    reg_date
    registered_event_ids
  }
}
    `;
export type CreateDirectClubPassRegistrationMutationFn = Apollo.MutationFunction<CreateDirectClubPassRegistrationMutation, CreateDirectClubPassRegistrationMutationVariables>;

/**
 * __useCreateDirectClubPassRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateDirectClubPassRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectClubPassRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectClubPassRegistrationMutation, { data, loading, error }] = useCreateDirectClubPassRegistrationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useCreateDirectClubPassRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateDirectClubPassRegistrationMutation, CreateDirectClubPassRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDirectClubPassRegistrationMutation, CreateDirectClubPassRegistrationMutationVariables>(CreateDirectClubPassRegistrationDocument, options);
      }
export type CreateDirectClubPassRegistrationMutationHookResult = ReturnType<typeof useCreateDirectClubPassRegistrationMutation>;
export type CreateDirectClubPassRegistrationMutationResult = Apollo.MutationResult<CreateDirectClubPassRegistrationMutation>;
export type CreateDirectClubPassRegistrationMutationOptions = Apollo.BaseMutationOptions<CreateDirectClubPassRegistrationMutation, CreateDirectClubPassRegistrationMutationVariables>;
export const CreateClubPassRegistrationDocument = gql`
    mutation CreateClubPassRegistration($userId: String!, $clubId: String!) {
  createClubPassRegistration(user_id: $userId, club_id: $clubId) {
    id
    club_id
    user_id
    razorpay_order_id
    verified
    reg_date
    registered_event_ids
  }
}
    `;
export type CreateClubPassRegistrationMutationFn = Apollo.MutationFunction<CreateClubPassRegistrationMutation, CreateClubPassRegistrationMutationVariables>;

/**
 * __useCreateClubPassRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateClubPassRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClubPassRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClubPassRegistrationMutation, { data, loading, error }] = useCreateClubPassRegistrationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useCreateClubPassRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateClubPassRegistrationMutation, CreateClubPassRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClubPassRegistrationMutation, CreateClubPassRegistrationMutationVariables>(CreateClubPassRegistrationDocument, options);
      }
export type CreateClubPassRegistrationMutationHookResult = ReturnType<typeof useCreateClubPassRegistrationMutation>;
export type CreateClubPassRegistrationMutationResult = Apollo.MutationResult<CreateClubPassRegistrationMutation>;
export type CreateClubPassRegistrationMutationOptions = Apollo.BaseMutationOptions<CreateClubPassRegistrationMutation, CreateClubPassRegistrationMutationVariables>;
export const CreateComboPassRegistrationDocument = gql`
    mutation CreateComboPassRegistration($userId: String!, $comboPassId: String!) {
  createComboPassRegistration(user_id: $userId, combo_pass_id: $comboPassId) {
    id
    combo_pass_id
    user_id
    razorpay_order_id
    payment_id
    verified
    reg_date
  }
}
    `;
export type CreateComboPassRegistrationMutationFn = Apollo.MutationFunction<CreateComboPassRegistrationMutation, CreateComboPassRegistrationMutationVariables>;

/**
 * __useCreateComboPassRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateComboPassRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateComboPassRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createComboPassRegistrationMutation, { data, loading, error }] = useCreateComboPassRegistrationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      comboPassId: // value for 'comboPassId'
 *   },
 * });
 */
export function useCreateComboPassRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateComboPassRegistrationMutation, CreateComboPassRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateComboPassRegistrationMutation, CreateComboPassRegistrationMutationVariables>(CreateComboPassRegistrationDocument, options);
      }
export type CreateComboPassRegistrationMutationHookResult = ReturnType<typeof useCreateComboPassRegistrationMutation>;
export type CreateComboPassRegistrationMutationResult = Apollo.MutationResult<CreateComboPassRegistrationMutation>;
export type CreateComboPassRegistrationMutationOptions = Apollo.BaseMutationOptions<CreateComboPassRegistrationMutation, CreateComboPassRegistrationMutationVariables>;
export const CreateDirectComboPassRegistrationDocument = gql`
    mutation CreateDirectComboPassRegistration($userId: String!, $comboPassId: String!) {
  createDirectComboPassRegistration(user_id: $userId, combo_pass_id: $comboPassId) {
    id
    combo_pass_id
    user_id
    razorpay_order_id
    payment_id
    verified
    reg_date
  }
}
    `;
export type CreateDirectComboPassRegistrationMutationFn = Apollo.MutationFunction<CreateDirectComboPassRegistrationMutation, CreateDirectComboPassRegistrationMutationVariables>;

/**
 * __useCreateDirectComboPassRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateDirectComboPassRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectComboPassRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectComboPassRegistrationMutation, { data, loading, error }] = useCreateDirectComboPassRegistrationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      comboPassId: // value for 'comboPassId'
 *   },
 * });
 */
export function useCreateDirectComboPassRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateDirectComboPassRegistrationMutation, CreateDirectComboPassRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDirectComboPassRegistrationMutation, CreateDirectComboPassRegistrationMutationVariables>(CreateDirectComboPassRegistrationDocument, options);
      }
export type CreateDirectComboPassRegistrationMutationHookResult = ReturnType<typeof useCreateDirectComboPassRegistrationMutation>;
export type CreateDirectComboPassRegistrationMutationResult = Apollo.MutationResult<CreateDirectComboPassRegistrationMutation>;
export type CreateDirectComboPassRegistrationMutationOptions = Apollo.BaseMutationOptions<CreateDirectComboPassRegistrationMutation, CreateDirectComboPassRegistrationMutationVariables>;