import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { faqDetails } from "../../components/faq/faqDetails";
import "../../styles/faq.css"; // Make sure to import your CSS file
import Navbar from "../../components/Navbar/Navbar";

const popcornImg = require("../../assets/popcorn.png");

const Faq: React.FC = () => {
  useEffect(() => {
    const sections = document.querySelectorAll(".faq-section");
    const tocLinks = document.querySelectorAll(".faq-toc ul li a");

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1, // 50% of the section must be visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const id = entry.target.id;
        if (entry.isIntersecting) {
          tocLinks.forEach((link) => link.classList.remove("active"));
          const activeLink = document.querySelector(
            `.faq-toc ul li a[href="#${id}"]`
          );
          activeLink?.classList.add("active");
        }
      });
    }, observerOptions);

    sections.forEach((section) => observer.observe(section));
  }, []);
  const location = useLocation();

  const [isTocVisible, setIsTocVisible] = useState(false);

  const toggleToc = () => {
    setIsTocVisible(!isTocVisible);
  };
  const handleTocClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, sectionId: string
  ) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" }); // Smooth scroll to the section
    }
    setIsTocVisible(false);
  };
  return (
    <>
      <div className="faq-layout">
        {/* Floating TOC Button */}
        <Navbar/>
        <button className="toc-button" onClick={toggleToc}>
          <span style={{ fontSize: "30px", color: "#FFFB00BA" }}>
            {isTocVisible ? "\u2771" : "\u2770"}
          </span>
        </button>

        <div className={`faq-toc ${isTocVisible ? "tocvisible" : "tochidden"}`}>
          <h1 className="font-unlock">Faq</h1>

          <ul>
            {faqDetails.map((section) => (
              <li key={section.sectionId}>
                <a className="font-unlock " href={`#${section.sectionId}`} onClick={(e) => handleTocClick(e, section.sectionId)}>
                  {section.sectionName}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="popcorn-img">
          <img alt="" src={String(popcornImg)} />
        </div>
        <div className="faq-content">
          {faqDetails.map((section) => (
            <div
              id={section.sectionId}
              key={section.sectionId}
              className="faq-section"
            >
              <h1 className="font-unlock mb-[8vh]">{section.sectionName}</h1>
              <ol className="urbanist">
                {section.faqs.map((faq) => (
                  <li key={faq.id} className="font-unlock text-2xl">
                    <strong>{faq.Question}</strong>
                    <br />
                    {faq.Answer1 ? (
                      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li style={{ fontWeight: 'normal' }}>{faq.Answer}</li>
                        <li style={{ fontWeight: 'normal' }}>{faq.Answer1}</li>
                        <li style={{ fontWeight: 'normal' }}>{faq.Answer2}</li>
                        <li style={{ fontWeight: 'normal' }}>{faq.Answer3}</li>
                      </ul>
                    ) : (
                      <p>{faq.Answer}</p>
                    )}
                  </li>
                ))}
              </ol>
            </div>
          ))}
        </div>

        
      </div>
      {/* <Footer /> */}
    </>
    
  );
};

export default Faq;
