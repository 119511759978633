import { useAuth } from "../contexts/AuthContext";
import { useQuery } from "@apollo/client";
import { motion } from "framer-motion";
import {
  GetUserSingleEventRegistrationsDocument,
  GetUserComboPassRegistrationsDocument,
  GetUserClubPassRegistrationsDocument,
  GetUserEscapeRoomRegistrationsDocument,
} from "../generated/graphql";
import Navbar from "../components/Navbar/Navbar";

export default function Dashboard() {
  const { user } = useAuth();

  const { data: singleEventData, loading: singleEventLoading } = useQuery(
    GetUserSingleEventRegistrationsDocument,
    {
      variables: { userId: user?.user_id || "" },
      skip: !user?.user_id,
    }
  );

  const { data: comboPassData, loading: comboPassLoading } = useQuery(
    GetUserComboPassRegistrationsDocument,
    {
      variables: { userId: user?.user_id || "" },
      skip: !user?.user_id,
    }
  );

  const { data: clubPassData, loading: clubPassLoading } = useQuery(
    GetUserClubPassRegistrationsDocument,
    {
      variables: { userId: user?.user_id || "" },
      skip: !user?.user_id,
    }
  );

  const { data: escapeRoomData, loading: escapeRoomLoading } = useQuery(
    GetUserEscapeRoomRegistrationsDocument,
    {
      variables: { userId: user?.user_id || "" },
      skip: !user?.user_id,
    }
  );

  const fadeInVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 1, ease: "easeOut" },
    },
  };

  const renderLoadingState = () => (
    <div className="animate-pulse flex space-x-4">
      <div className="h-20 w-full bg-gray-700 rounded"></div>
    </div>
  );

  return (
    <motion.div
      className="min-h-screen pt-[10vh] overflow-x-hidden"
      style={{ backgroundImage: `url('./Rectangle.png')` }}
      variants={fadeInVariant}
      initial="hidden"
      animate="visible"
    >
      <Navbar />

      {/* User Profile Section */}
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="bg-[#ffc525] shadow rounded-lg p-6 mb-6 mx-3 border-4 border-black">
          <div className="flex items-center space-x-4">
            <div>
              <h2 className="text-2xl font-bold text-black font-unlock">
                {user?.username}
              </h2>
              <p className="text-black">{user?.email_primary}</p>
              <p className="text-black font-semibold">
                Saarang ID: {user?.saarang_id}
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#FFFB00] shadow rounded-lg p-6 mb-6 mx-3 border-2 border-black"> Note: If you purchased the Student Pass or the Madras Stay-cation Pass from the sales portal(sales.saarang.org), you will automatically gain access to all competitions during Saarang (workshops not included). These passes will not be reflected here, and there is no need to register separately for the competitions. </div>

        {/* Registrations Section */}

        <div className="grid grid-cols-1 md:grid-cols-2 mx-3 gap-6">
          {/* Combo Passes */}
          <div className="bg-[#FFFB00] shadow rounded-lg p-6 border-2 border-black">
            <h3 className="text-xl font-bold mb-4 text-black font-unlock">
              Your Combo Passes
            </h3>
            {comboPassLoading ? (
              renderLoadingState()
            ) : comboPassData?.getUserComboPassRegistrations?.length ? (
              comboPassData.getUserComboPassRegistrations.map((reg: any) => (
                <div
                  key={reg.comboPass.id}
                  className="mb-1 p-4 border-2 border-black rounded-lg bg-[#ff8f3f]"
                >
                  <h4 className="font-semibold text-black">
                    {reg.comboPass.title}
                  </h4>
                  {/* <p className="text-black font-medium">
                    Price: ₹{reg.comboPass.price}
                  </p> */}
                  <p className="text-black">
                    Registered:{" "}
                    {new Date(reg.registration.reg_date).toLocaleDateString()}
                  </p>
                  <p className="text-black">
                    Associated Clubs:{" "}
                    {reg.clubs?.map((club: any) => club.clubName).join(", ") ||
                      "None"}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-black">No combo passes found</p>
            )}
          </div>

          {/* Club Passes */}
          <div className="bg-[#FFFB00] shadow rounded-lg p-6 border-2 border-black">
            <h3 className="text-xl font-bold mb-4 text-black font-unlock">
              Your Club Passes
            </h3>
            {clubPassLoading ? (
              renderLoadingState()
            ) : clubPassData?.getUserClubPassRegistrations?.length ? (
              clubPassData.getUserClubPassRegistrations.map((reg: any) => (
                <div
                  key={reg.registration.id}
                  className="p-4 border-2 border-black rounded-lg bg-[#ff8f3f] m-1"
                >
                  <h4 className="font-semibold text-black">
                    {reg.club.clubName}
                  </h4>
                  <p className="text-black">
                    Registered:{" "}
                    {new Date(reg.registration.reg_date).toLocaleDateString()}
                  </p>
                  {/* <p className="text-black">
                    Events:{" "}
                    {reg.club.events
                      ?.map((event: any) => event.title)
                      .join(", ") || "None"}
                  </p> */}
                </div>
              ))
            ) : (
              <p className="text-black">No club passes found</p>
            )}
          </div>
        </div>

        {/* Single Events */}
        <div className="bg-[#FFFB00] shadow rounded-lg mx-3 p-6 mt-6 border-2 border-black">
          <h3 className="text-xl font-bold mb-4 text-black font-unlock">
            Your Event Registrations
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {singleEventLoading ? (
              renderLoadingState()
            ) : singleEventData?.getUserSingleEventRegistrations?.length ? (
              singleEventData.getUserSingleEventRegistrations.map(
                (reg: any) => (
                  <div
                    key={reg.registration.id}
                    className="mb-4 p-4 border-2 border-black rounded-lg bg-[#ff8f3f]"
                  >
                    <h4 className="font-semibold text-black">
                      {reg.event.title}
                    </h4>
                    <p className="text-black">
                      Registered:{" "}
                      {new Date(reg.registration.reg_date).toLocaleDateString()}
                    </p>
                    <p className="text-black">
                      Event Date:{" "}
                      {new Date(reg.event.event_date).toLocaleDateString()}
                    </p>
                    <p className="text-black">
                      Event Location: {reg.event.event_location}
                    </p>
                  </div>
                )
              )
            ) : (
              <p className="text-black">No event registrations found</p>
            )}
          </div>
        </div>

        {/* Escape Room Registrations */}
        {escapeRoomData?.getUserEscapeRoomRegistrations?.length > 0 && (
          <div className="bg-[#FFFB00] shadow rounded-lg mx-3 p-6 mt-6 border-2 border-black">
            <h3 className="text-xl font-bold mb-4 text-black font-unlock">
              Your Escape Room Registration
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {escapeRoomLoading
                ? renderLoadingState()
                : escapeRoomData.getUserEscapeRoomRegistrations.map(
                    (reg: any) => (
                      <div
                        key={reg.registration.id}
                        className="mb-4 p-4 border-2 border-black rounded-lg bg-[#ff8f3f]"
                      >
                        <h4 className="font-semibold text-black">
                          {reg.escapeRoom.title}
                        </h4>
                        <p className="text-black">
                          Time Slot: {reg.escapeRoom.slot}
                        </p>
                        <p className="text-black">
                          Event Date:{" "}
                          {new Date(
                            reg.escapeRoom.event_date
                          ).toLocaleDateString()}
                        </p>
                        <p className="text-black">
                          Location: {reg.escapeRoom.event_location}
                        </p>
                        {/* <p className="text-black">
                          Price: ₹{reg.registration.price}
                        </p> */}
                        {/* <div className="flex gap-2 mt-2">
                          <span
                            className={`px-2 py-1 rounded-full text-sm ${
                              reg.registration.paid
                                ? "bg-green-500/20 text-green-400"
                                : "bg-yellow-500/20 text-yellow-400"
                            }`}
                          >
                            {reg.registration.paid ? "Paid" : "Payment Pending"}
                          </span>
                          <span
                            className={`px-2 py-1 rounded-full text-sm ${
                              reg.registration.verified
                                ? "bg-green-500/20 text-green-400"
                                : "bg-yellow-500/20 text-yellow-400"
                            }`}
                          >
                            {reg.registration.verified
                              ? "Verified"
                              : "Pending Verification"}
                          </span>
                        </div> */}
                      </div>
                    )
                  )}
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}
