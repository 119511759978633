import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import {
  useCreateClubPassRegistrationMutation,
  useCreateDirectClubPassRegistrationMutation,
} from "../../generated/graphql";
import { useAuth } from "../../contexts/AuthContext";
import { loadRazorpay } from "../../utils/razorpay";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useGetMe } from "../../hooks/useGetMe";
interface ClubPassRegistrationModalProps {
  isOpen: boolean;
  onClose: () => void;
  club: {
    id: string;
    clubName: string;
    clubPassPrice: number;
    upcomingEvents: Array<{
      id: string;
      title: string;
      event_date: string;
    }>;
  };
}

export const ClubPassRegistrationModal = ({
  isOpen,
  onClose,
  club,
}: ClubPassRegistrationModalProps) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [createRegistration] = useCreateClubPassRegistrationMutation();
  const [createDirectRegistration] =
    useCreateDirectClubPassRegistrationMutation();
  const navigate = useNavigate();
  const { refetch: refetchUser } = useGetMe();
  const handleRegistration = async () => {
    console.log("Starting club pass registration process");
    if (!user) {
      console.log("No user found, redirecting to auth");
      toast.error("Please login to continue");
      window.location.href = "/auth";
      return;
    }

    await refetchUser();
    if (user.mobile_number_primary?.length === 0) {
      toast.error("Please complete your profile to continue");
      navigate("/profile/setup");
      return;
    }
    setLoading(true);
    try {
      const isIITMStudent = user.email_primary.endsWith("smail.iitm.ac.in") ;
      const isVerified = user.verified;
      if (!isVerified) {
        toast.error("Please verify your email to continue");
        navigate("/sendVerificationEmail");
        return;
      }
      console.log(
        "User type:",
        isIITMStudent ? "IITM Student" : "External User",
        club.clubName
      );

      if (isIITMStudent && !club.clubName.toLowerCase().includes("workshops")) {
        console.log("Processing direct club pass registration");
        const { errors } = await createDirectRegistration({
          variables: {
            userId: user.user_id,
            clubId: club.id,
          },
        });

        if (errors) {
          throw new Error(errors[0].message);
        }

        toast.success("Successfully registered for the club pass!");
        onClose();
        return;
      }

      console.log("Loading Razorpay...");
      const razorpay = await loadRazorpay();
      console.log("Razorpay loaded successfully");

      console.log("Creating club pass registration record...");
      const { data } = await createRegistration({
        variables: {
          userId: user.user_id,
          clubId: club.id,
        },
      });
      console.log("Registration record created:", data);

      const registration = data?.createClubPassRegistration;
      const options = {
        key: process.env.PUBLIC_RAZORPAY_KEY_ID || "rzp_live_VgVO9uzPsxViN5",
        amount: club.clubPassPrice * 100,
        currency: "INR",
        name: "Club Pass Registration",
        description: `Club Pass`,
        order_id: registration?.razorpay_order_id,
        handler: function (response: any) {
          console.log("Payment success response:", response);
          toast.success(
            "Payment successful! You're registered for the club pass."
          );
          onClose();
        },
        prefill: {
          email: user.email_primary,
          contact: user.mobile_number_primary,
        },
        modal: {
          ondismiss: function () {
            console.log("Payment modal closed by user");
          },
        },
      };

      console.log("Initializing Razorpay with options:", {
        amount: options.amount,
        orderId: options.order_id,
      });
      const paymentObject = razorpay(options);
      console.log("Opening payment modal");
      paymentObject.open();
    } catch (error: any) {
      console.error("Registration error details:", error);
      toast.error(
        error.message || "Failed to process registration. Please try again."
      );
    } finally {
      setLoading(false);
      console.log("Registration process completed");
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Confirm Club Pass Registration
                </Dialog.Title>

                <div className="mt-4">
                  <div className="space-y-4">
                    <div>
                      <h4 className="font-medium text-gray-900">
                        {club.clubName} Club Pass
                      </h4>
                      <p className="text-sm text-gray-500">
                        {club.clubName.toLowerCase().includes("workshops") 
                          ? "After purchasing workshop pass, you can register for any workshop without paying extra fees."
                          : "After purchasing this pass, you can register for any event hosted by this club without paying extra fees."}{" "}
                      </p>
                    </div>

                    {club.upcomingEvents.length > 0 && (
                      <div>
                        <h5 className="text-sm font-medium text-gray-900 mb-2">
                          Upcoming Events:
                        </h5>
                        <ul className="space-y-1">
                          {club.upcomingEvents.map((event) => (
                            <li key={event.id} className="text-sm text-black">
                              • {event.title}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    <div className="text-sm text-gray-600">
                      <p className="font-medium">
                        Amount:{" "}
                        {user?.email_primary?.endsWith("smail.iitm.ac.in") && !club.clubName.toLowerCase().includes("workshops")
                          ? "Free"
                          : `₹${club.clubPassPrice}`}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex justify-end gap-3">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-red-600 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-red-50"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-800 ${
                      loading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={handleRegistration}
                    disabled={loading}
                  >
                    {loading
                      ? "Processing..."
                      : user?.email_primary?.endsWith("smail.iitm.ac.in") && !club.clubName.toLowerCase().includes("workshops")
                      ? "Register"
                      : "Proceed to Pay"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
