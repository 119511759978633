import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UpdateProfileDocument } from "../../generated/graphql";
import toast from "react-hot-toast";
import { useGetMe } from "../../hooks/useGetMe";

interface ProfileFormData {
  mobile_number_primary: string;
  college: string;
  year_of_study: string;
  region: string;
  state: string;
}

const ProfileSetup: React.FC = () => {
  const { user, updateUserData } = useAuth();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [error, setError] = useState<string | null>(null);
  const [updateProfile] = useMutation(UpdateProfileDocument);
  const { refetch: refetchUser } = useGetMe();

  const [formData, setFormData] = useState<ProfileFormData>({
    mobile_number_primary: user?.mobile_number_primary || "",
    college: user?.college || "",
    year_of_study: user?.year_of_study || "",
    region: user?.region || "",
    state: user?.state || "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await updateProfile({
        variables: {
          mobileNumberPrimary: formData.mobile_number_primary,
          college: formData.college,
          yearOfStudy: formData.year_of_study,
          region: formData.region,
          state: formData.state,
        },
      });

      if (response.data?.updateProfile) {
        // Update the user data in context with the new profile data
        updateUserData({
          ...user,
          ...response.data.updateProfile,
        });

        // Fetch latest user data from backend
        await refetchUser();

        toast.success("Profile updated and Verification email sent!");
        navigate("/events");
      } else {
        throw new Error("Failed to update profile - no data returned");
      }
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : "Failed to update profile";
      setError(errorMessage);
      toast.error(errorMessage);
      console.error("Error updating profile:", err);
    }
  };

  const renderStep1 = () => {
    const isStep1Valid =
      formData.mobile_number_primary.trim() !== "" &&
      formData.state.trim() !== "" &&
      formData.region.trim() !== "";

    return (
      <div className="space-y-4 flex flex-col justify-center items-center">
        <div className="w-full">
          <label className="block text-sm font-medium text-gray-700">
            Mobile Number
          </label>
          <input
            type="tel"
            placeholder=" Mobile Number"
            className="appearance-none rounded-2xl relative block w-full h-[55px] px-3 py-2 border-2 border-[#c1204b] placeholder-black bg-[#febd02] text-gray-900  focus:bg-[#febd02]"
            value={formData.mobile_number_primary}
            onChange={(e) =>
              setFormData({
                ...formData,
                mobile_number_primary: e.target.value,
              })
            }
            required
          />
        </div>
        <div className="w-full">
          <label className="block text-sm font-medium text-gray-700">
            State
          </label>
          <select
            className="appearance-none rounded-2xl relative block w-full h-[55px] px-3 py-2 border-2 border-[#c1204b] placeholder-black  bg-[#febd02] text-gray-900  focus:bg-[#febd02]"
            value={formData.state}
            onChange={(e) =>
              setFormData({ ...formData, state: e.target.value })
            }
            required
          >
            <option value="">Select State</option>
            <option value="Andhra Pradesh">Andhra Pradesh</option>
            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
            <option value="Assam">Assam</option>
            <option value="Bihar">Bihar</option>
            <option value="Chhattisgarh">Chhattisgarh</option>
            <option value="Goa">Goa</option>
            <option value="Gujarat">Gujarat</option>
            <option value="Haryana">Haryana</option>
            <option value="Himachal Pradesh">Himachal Pradesh</option>
            <option value="Jammu and Kashmir">Jammu and Kashmir</option>
            <option value="Jharkhand">Jharkhand</option>
            <option value="Karnataka">Karnataka</option>
            <option value="Kerala">Kerala</option>
            <option value="Madhya Pradesh">Madhya Pradesh</option>
            <option value="Maharashtra">Maharashtra</option>
            <option value="Manipur">Manipur</option>
            <option value="Meghalaya">Meghalaya</option>
            <option value="Mizoram">Mizoram</option>
            <option value="Nagaland">Nagaland</option>
            <option value="Odisha">Odisha</option>
            <option value="Punjab">Punjab</option>
            <option value="Rajasthan">Rajasthan</option>
            <option value="Sikkim">Sikkim</option>
            <option value="Tamil Nadu">Tamil Nadu</option>
            <option value="Telangana">Telangana</option>
            <option value="Tripura">Tripura</option>
            <option value="Uttar Pradesh">Uttar Pradesh</option>
            <option value="Uttarakhand">Uttarakhand</option>
            <option value="West Bengal">West Bengal</option>
          </select>
        </div>

        <div className="w-full">
          <label className="block text-sm font-medium text-gray-700">
            Region
          </label>
          <input
            type="text"
            className="appearance-none rounded-2xl relative block w-full h-[55px] px-3 py-2 border-2 border-[#c1204b] placeholder-black bg-[#febd02] text-gray-900  focus:bg-[#febd02]"
            value={formData.region}
            onChange={(e) =>
              setFormData({ ...formData, region: e.target.value })
            }
            required
            placeholder="Enter your region"
          />
        </div>
        <button
          onClick={() => {
            if (isStep1Valid) {
              setStep(2);
            } else {
              toast.error("Please fill in all required fields");
            }
          }}
          className={`  w-[70%] py-3 px-4 border border-transparent rounded-3xl shadow-sm text-sm font-medium text-white bg-[#c1204b] hover:bg-[#8d1737] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
            isStep1Valid
              ? "bg-red-600 hover:bg-red-700 text-white"
              : "bg-gray-300 cursor-not-allowed text-gray-500"
          }`}
        >
          Next
        </button>
      </div>
    );
  };

  const renderStep2 = () => (
    <div className="space-y-4">
      <div className="w-full">
        <label className="block text-sm font-medium text-gray-700">
          College
        </label>
        <input
          type="text"
          placeholder="College"
          className="appearance-none rounded-2xl relative block w-full h-[55px] px-3 py-2 border-2 border-[#c1204b] placeholder-black bg-[#febd02] text-gray-900  focus:bg-[#febd02]"
          value={formData.college}
          onChange={(e) =>
            setFormData({ ...formData, college: e.target.value })
          }
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Year of Study
        </label>
        <select
          className="appearance-none rounded-2xl relative block w-full h-[55px] px-3 py-2 border-2 border-[#c1204b] placeholder-black bg-[#febd02] text-gray-900  focus:bg-[#febd02]"
          value={formData.year_of_study}
          onChange={(e) =>
            setFormData({ ...formData, year_of_study: e.target.value })
          }
          required
        >
          <option value="">Select Year</option>
          <option value="1">1st Year</option>
          <option value="2">2nd Year</option>
          <option value="3">3rd Year</option>
          <option value="4">4th Year</option>
          <option value="5">5th Year</option>
        </select>
      </div>

      <div className="flex gap-4">
        <button
          onClick={() => setStep(1)}
          className="w-[70%] py-3 px-4 border border-transparent rounded-3xl shadow-sm text-sm font-medium text-white bg-[#c1204b] hover:bg-[#8d1737] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Back
        </button>
        <button
          onClick={handleSubmit}
          className="w-[70%] py-3 px-4 border border-transparent rounded-3xl shadow-sm text-sm font-medium text-white bg-[#c1204b] hover:bg-[#8d1737] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Complete Setup
        </button>
      </div>
    </div>
  );

  return (
    <div className="relative h-[100vh] w-[100%] flex items-center justify-center bg-[#c1204b] overflow-hidden">
      <div className="absolute w-[100%] h-screen transform rotate-[125deg] overflow-hidden left-[-40%] hidden lg:block ">
        <div
          className="absolute top-0 left-0 w-[150vw] h-[50%] bg-cover bg-no-repeat animate-slide"
          style={{ backgroundImage: "url('/film1.png')" }}
        ></div>
      </div>

      <div className="absolute w-[100%] visible h-screen transform rotate-[50deg] overflow-hidden left-[30%] hidden lg:block">
        <div
          className="absolute top-0 left-0 w-[150vw] h-[50%] bg-cover bg-no-repeat animate-slide"
          style={{ backgroundImage: "url('/film2.png')" }}
        ></div>
      </div>
      <div className="absolute w-[100%] h-screen transform rotate-[-60deg] overflow-hidden left-[40%] hidden lg:block">
        <div
          className="absolute top-0 left-0 w-[150vw] h-[50%] bg-cover bg-no-repeat animate-slide"
          style={{ backgroundImage: "url('/film3.png')" }}
        ></div>
      </div>
      <div className="absolute w-[100%] h-screen transform rotate-[50deg] overflow-hidden left-[-40%] hidden lg:block">
        <div
          className="absolute top-0 left-0 w-[150vw] h-[50%] bg-cover bg-no-repeat animate-slide"
          style={{ backgroundImage: "url('/film4.png')" }}
        ></div>
      </div>

      <div className="md:w-[28rem] w-[90%] space-y-8 p-4 pb-6 bg-[#febd02] z-10 rounded-3xl shadow-lg">
        <div>
          {/* <div className="flex items-center justify-center">
            <img
              className="h-12 w-12 rounded-full"
              src={user?.profile_picture || ""}
              alt="Profile"
            />
          </div> */}
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Complete Your Profile
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Welcome {user?.username}! Please fill Additional Info if you are a
            student!
          </p>
        </div>

        {error && (
          <div className="mb-4 p-4 text-red-700 bg-red-100 rounded-md">
            {error}
          </div>
        )}

        <div className="mt-8">
          {/* Progress indicator */}
          <div className="mb-8">
            <div className="flex justify-between">
              <div
                className={`w-1/2 text-center ${
                  step === 1 ? "text-red-600" : "text-black-400"
                }`}
              >
                Basic Info
              </div>
              <div
                className={`w-1/2 text-center ${
                  step === 2 ? "text-red-600" : "text-black-400"
                }`}
              >
                Additional Info
              </div>
            </div>
            <div className="mt-2 h-2 bg-gray-200 rounded-full">
              <div
                className="h-full bg-red-600 rounded-full transition-all duration-300"
                style={{ width: `${(step / 2) * 100}%` }}
              />
            </div>
          </div>

          {step === 1 ? renderStep1() : renderStep2()}
        </div>
      </div>
    </div>
  );
};

export default ProfileSetup;
