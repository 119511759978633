import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { contactDetails } from "../../components/contactus/contactDetails";
import ContactCard from "../../components/contactus/ContactCard";
import "../../styles/contactus.css"; // Make sure to import your CSS file
import Navbar from "../../components/Navbar/Navbar";
import Camera from "../../components/Home/camers";
import { useScroll } from "framer-motion";

const contactusRTC = require("../../assets/contactus_rtc.png");
const contactusLBC = require("../../assets/contactus_lbc.png");
const contactusRBC = require("../../assets/contactus_rbc.png");
const contactusLTC = require("../../assets/contactus_ltc.png");
const contactusMB = require("../../assets/strip-film-reel.png");

const Contactus: React.FC = () => {
  useEffect(() => {
    const sections = document.querySelectorAll(".contact-section");
    const tocLinks = document.querySelectorAll(".contact-toc ul li a");

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const id = entry.target.id;
        if (entry.isIntersecting) {
          tocLinks.forEach((link) => link.classList.remove("active"));
          const activeLink = document.querySelector(
            `.contact-toc ul li a[href="#${id}"]`
          );
          activeLink?.classList.add("active");
        }
      });
    }, observerOptions);

    sections.forEach((section) => observer.observe(section));
  }, []);

  const location = useLocation();

  const [isTocVisible, setIsTocVisible] = useState(false);

  const toggleToc = () => {
    setIsTocVisible(!isTocVisible);
  };
  const containerRef=useRef<HTMLDivElement>(null);
  const {scrollYProgress}=useScroll({container:containerRef})
  const handleTocClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    sectionId: string
  ) => {
    e.preventDefault(); // Prevent default anchor behavior
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" }); // Smooth scroll to the section
    }
    setIsTocVisible(false);
  };

  return (
    <div className="contact-page pt-[10vh]">
       
      <div className="contact-background"> <div className='hidden lg:block w-1/4 sticky top-0 py-72 z-10 xl:w-1/4' style={{ transform: 'scale(1.5)', transformOrigin: 'left' }}>
               <Camera scrollYProgress={scrollYProgress}/>
            </div></div>
      <Navbar />
      <div className="contact-content">
        <h1 className="contact-heading">Contact us</h1>
        <div className="contact-layout">
          <button className="toc-button" onClick={toggleToc}>
            <span style={{ fontSize: "30px", color: "#FFFB00BA" }}>
              {isTocVisible ? "\u2771" : "\u2770"}
            </span>
          </button>
        
          {/* <div className="contacts-img1">
            <img alt="" className="c-bgi" src={String(contactusLBC)} />
          </div> */}
          <div className="contacts-img2">
            <img alt="" className="c-bgi" src={String(contactusRBC)} />
          </div>
          {/* <div className="contacts-img3">
            <img alt="" className="c-bgi" src={String(contactusLTC)} />
          </div> */}
          <div className="contacts-img4">
            <img alt="" className="c-bgi" src={String(contactusRTC)} />
          </div>
          {/* <div className="contacts-img5">
            <img alt="" className="c-bgi" src={String(contactusMB)} />
          </div> */}
          <div className="contact-content">
            {contactDetails.map((section) => (
              <div
                id={section.sectionId}
                key={section.sectionId}
                className="contact-section"
              >
                <h1>{section.sectionName}</h1>
                <div className="contact-img-section">
                  {section.contacts.map((contact) => (
                    <ContactCard
                      key={contact.id}
                      role={contact.role}
                      name={contact.name}
                      phone={contact.phone ?? ""}
                      email={contact.email ?? ""}
                      linkedin={contact.linkedin ?? ""}
                      instagram={contact.instagram ?? ""}
                      image={contact.image}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div
            className={`contact-toc ${
              isTocVisible ? "tocvisible" : "tochidden"
            }`}
          >
            <ul>
              {contactDetails.map((section) => (
                <li key={section.sectionId}>
                  <a
                    href={`#${section.sectionId}`}
                    onClick={(e) => handleTocClick(e, section.sectionId)} // Pass sectionId to the handler
                  >
                    {section.sectionName}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
