import { ApolloClient, InMemoryCache } from "@apollo/client";

const nodeEnv = process.env.NODE_ENV || "production";
const PORT = 7000;
let api_url = nodeEnv === "production" ? "https://api.saarang.org/graphql" : `http://localhost:${PORT}/graphql`;

const client = new ApolloClient({
  uri: api_url,
  cache: new InMemoryCache(),
  credentials: "include",
});

export default client;
