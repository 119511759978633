import React, { useState } from 'react';
import './check.css';
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Hnav from '../Home/HomeNavbar/Hnav';
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  // const [isOpenn, setIsOpenn] = useState(false);
  // Track whether the menu is open
  const { isAuthenticated, logout, user } = useAuth();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
 // Toggle the menu visibility
  };

  
  const navigate = useNavigate();
  
  const handleAuthAction = async () => {
    if (isAuthenticated) {
      try {
        await logout();
        navigate("/auth");
      } catch (error) {
        console.error("Logout failed:", error);
      }
    } else {
      navigate("/auth");
    }
  };

  return (
    <div className="navbar w-[100vw] px-[2vw] font-unlock ">
      {/* Hamburger Icon */}
      {isOpen ? (
        <div className="cross-icon" onClick={toggleMenu}>
          <div className="line line1"></div>
          <div className="line line2"></div>
        </div>
      ) : (
        <div className="hamburger-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      )}
      {/* <div className="hamburger-icon" onClick={toggleMenu}>
      <div className={`bar bar1 ${isOpen ? "open" : ""}`}></div>
      <div className={`bar bar2 ${isOpen ? "open" : ""}`}></div>
    </div> */}

      {/* Full-screen Menu */}
      <div className={`fullscreen-menu ${isOpen ? "open" : ""}`}>
        <div className="scrollable-menu flex md:flex-row flex-col md:gap-[10vw] gap-[0vw]">
          <ul id="nav" className="nav-ul">
            <li className="nav-link">
              <h1
                className="nav-title"
                onClick={() => navigate("/")}
                data-name="home"
              >
                HOME
              </h1>
            </li>
            <li className="nav-link">
              <h1
                className="nav-title"
                onClick={() => navigate("/events")}
                data-name="COMPETITIONS"
              >
                COMPETITIONS
              </h1>
            </li>
            <li className="nav-link">
              <h1
                className="nav-title"
                onClick={() => navigate("/workshops")}
                data-name="WORKSHOPS"
              >
                WORKSHOPS
              </h1>
            </li>
            <li className="nav-link">
              <h1
                className="nav-title"
                onClick={() => navigate("/dashboard")}
                data-name="REGISTRATIONS"
              >
                REGISTRATIONS
              </h1>
            </li>
            <li className="nav-link">
              <h1
                className="nav-title"
                onClick={() => navigate("/schedule")}
                data-name="SCHEDULE"
              >
                SCHEDULE
              </h1>
            </li>
            <li className="nav-link">
              <h1
                className="nav-title"
                onClick={() => navigate("/faq")}
                data-name="FAQ"
              >
                FAQ
              </h1>
            </li>
            <li className="nav-link">
              <h1
                className="nav-title"
                onClick={() => navigate("/contactus")}
                data-name="CONTACT US"
              >
                CONTACT US
              </h1>
            </li>
          </ul>
          <ul id="nav" className="nav-ul">
            <li className="nav-link">
              <h1
                className="nav-title"
                onClick={() =>
                  window.open(
                    "https://join.saarang.org/accommodation",
                    "_blank"
                  )
                }
                data-name="ACCOMMODATION"
              >
                ACCOMMODATION
              </h1>
            </li>
            <li className="nav-link">
              <h1
                className="nav-title"
                onClick={() =>
                  window.open("https://sales.saarang.org/#proshows", "_blank")
                }
                data-name="TICKETS"
              >
                TICKETS
              </h1>
            </li>
            <li className="nav-link">
              <h1
                className="nav-title"
                onClick={() =>
                  window.open("https://sales.saarang.org/merch", "_blank")
                }
                data-name="MERCH"
              >
                MERCH
              </h1>
            </li>
            <li className="nav-link">
              <h1
                className="nav-title"
                onClick={() =>
                  window.open("https://join.saarang.org/milans", "_blank")
                }
                data-name="MILANS"
              >
                MILANS
              </h1>
            </li>
            <li className="nav-link">
              <h1 className="nav-title" onClick={()=>navigate("/sponsors")}data-name="SPONSORS">SPONSORS</h1>
            </li>
            <li className="nav-link">
              <h1
                className="nav-title"
                onClick={() => navigate("/rules")}
                data-name="RULES"
              >
                RULES
              </h1>
            </li>
          </ul>
        </div>
      </div>

      {/* <div>
        <img src="horiz.png" className="w-[150px]" alt="Logo" />
      </div> */}

      <div className="flex">
        {/* <img src='horiz.png' className='w-[15vw]' /> */}
      </div>

      <div className="w-[100px] h-[40px]">
        <button
          className="w-[100px] h-[40px] bg-[#ea9901] text-xl"
          onClick={handleAuthAction}
        >
          {" "}
          {isAuthenticated ? "SIGN OUT" : "SIGN IN"}
        </button>
      </div>
    </div>
  );
};

export default Navbar;
