import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Events from "./pages/Events";
import EventsAdmin from "./pages/admin/EventsAdmin";
import Auth from "./pages/auth/Auth";
import EscapeRoomAdmin from "./pages/admin/EscapeRoomAdmin";
import ProfileSetup from "./pages/auth/ProfileSetup";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import CategoryClubs from "./components/events/CategoryClubs";
import { Toaster } from "react-hot-toast";
import Workshops from "./components/events/Workshops";
import Dashboard from "./pages/Dashboard";
import VerifyEmail from "./pages/auth/VerifyEmail";
import SendVerificationEmail from "./pages/auth/SendVerificationEmail";
import Contactus from "./pages/pages/Contactus";
import Faq from "./pages/pages/Faq";
import Home from "./pages/pages/Home";
import OatRules from "./pages/pages/OatRules";
import Sponsers from "./pages/pages/Sponsors";
import NotFound from "./pages/pages/NotFound"; // Import your NotFound component
import Schedule from './components/Home/Schedule'
const RestrictAdmin: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuth();
  if (user?.positions?.[4] !== "1") {
    return <Navigate to="/events" replace />;
  }

  return <>{children}</>;
};
const RestrictedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/events" replace />;
  }

  return <>{children}</>;
};

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route
              path="/auth"
              element={
                // <RestrictedRoute>
                <Auth />
                // </RestrictedRoute>
              }
            />
            <Route
              path="/admin/escapeRoom"
              element={
                <RestrictAdmin>
                <EscapeRoomAdmin />
                </RestrictAdmin>
              }
            />
            <Route
              path="/profile/setup"
              element={
                // <PrivateRoute>
                <ProfileSetup />
                // </PrivateRoute>
              }
            />
            <Route
              path="/events"
              element={
                // <PrivateRoute>
                <Events />
                // </PrivateRoute>
              }
            />
            {/* <Route path="/escapeRoom" element={<EscapeRoom />} /> */}
            <Route
              path="/clubs/:category"
              element={
                // <PrivateRoute> 
                <CategoryClubs />
                // </PrivateRoute>
              }
            />

            <Route
              path="/admin"
              element={
               <RestrictAdmin>
                <EventsAdmin />
             </RestrictAdmin>
              }
            />

            <Route path="/workshops" element={<Workshops />} />
               <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/verifyEmail/:id1/:id2" element={<VerifyEmail />} />
            <Route
              path="/sendVerificationEmail"
              element={<SendVerificationEmail />}
            />
            
            <Route path="/schedule" element={<Schedule />} />

            <Route path="/faq" element={<Faq />} />
            <Route path="/contactus" element={<Contactus />} />
            <Route path="/sponsors" element={<Sponsers />} />
            <Route path="/rules" element={<OatRules />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
      <Toaster position="top-center" />
    </AuthProvider>
  );
}

export default App;
